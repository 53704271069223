//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Typography } from "antd";
import Chat from "../components/chat/Chat/Chat";
import { Content } from "antd/lib/layout/layout";
import {
  clearFilterJob,
  clearMessageList,
  clearPositionSelect,
  clearUserChatSelected,
  getFilterJob,
  startGetMessageInboxList,
} from "../store/chat";
import MessagesPending from "../components/chat/MessagesPending";
import PositionListChat from "../components/chat/PositionListChat";
import MessagesList from "../components/chat/Message/MessagesList";
import { getTranslation } from "../hooks/hooks";
// Component
const { Text } = Typography;

const ChatScreen: React.FC = () => {
  // States
  const [size, setSize] = useState<number>(window.innerWidth);
  const dispatch: Dispatch<any> = useDispatch();
  const { user, token } = useSelector((state: any) => state.auth);
  const {
    userChatSelected,
    positionSelected,
    messageList: messagesListRedux,
    filterJob,
  } = useSelector((state: any) => state.chat);
  const { dictionary } = useSelector((state: any) => state.ui);
  const [isActiveChat, setIsActiveChat] = useState<boolean>(false);
  const [idCandidateData, setIdCandidateData] = useState<string>("");
  const [nameCandidate, setNameCandidate] = useState<string>("");
  const { messageList }: { messageList: Array<any> } = useSelector(
    (state: any) => state.chat
  );

  //Effects
  useEffect(() => {
    if (isActiveChat) {
      if (userChatSelected.id === idCandidateData) {
        setIsActiveChat(false);
        setIdCandidateData("");
        setNameCandidate("");
      } else {
        setIdCandidateData(userChatSelected.id);
        setNameCandidate(
          userChatSelected.name + " " + userChatSelected.lastName
        );
        setIsActiveChat(true);
      }
    } else {
      setIdCandidateData(userChatSelected.id);
      setNameCandidate(userChatSelected.name + " " + userChatSelected.lastName);
      setIsActiveChat(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChatSelected]);

  // Handlers
  const handleGoToInbox = () => {
    if (filterJob === "Inbox") {
      dispatch(clearFilterJob());
      dispatch(clearMessageList());
      dispatch(clearUserChatSelected());
    } else {
      dispatch(clearPositionSelect());
      dispatch(clearMessageList());
      dispatch(startGetMessageInboxList(user, token));
      // dispatch(startShowFirstCandidateToInbox(user, positionPerCandidate));
      dispatch(getFilterJob("Inbox"));
    }
  };
  const updateWidth = () => {
    setSize(window.innerWidth);
  };

  // Effects
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, [size]);

  return (
    <Content
      style={{ height: "100%", width: "100%", backgroundColor: '#00000004' }}
    >
      <Row style={{ height: "100%", width: "100%" }}>
        {/* Component One - Lista de posiciones */}
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xxl={10}
          style={{
            width: "100%",
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xxl={12}
              style={{ width: "100%", padding: "2%" }}
            >
              <Row style={{ width: "100%" }}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xxl={24}
                  style={{ width: "100%", minHeight: 100 }}
                >
                  <div
                    style={{
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e0e0e0",
                      boxShadow: "2px 2px 5px #00000020",
                      padding: 5,
                      paddingBottom: 10,
                      paddingRight: 10,
                      backgroundColor: 'white'
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: "#129BD4",
                          fontSize: 22,
                          fontWeight: "bold",
                          textAlign: "center",
                          fontFamily: "Muli",
                        }}
                      >
                        {dictionary["messages-title-my-messages"]}
                      </Text>
                    </Row>

                    <Row style={{ paddingLeft: 10 }}>
                      <Row
                        style={{
                          backgroundColor:
                            filterJob === "Inbox" ? "#24CECE" : "#00000000",
                          height: "40%",
                          paddingLeft: "5%",
                          width: "100%",
                          borderRadius: 8,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={handleGoToInbox}
                      >
                        <Col span={20}>
                          <Text style={{ fontSize: 18, fontFamily: "Muli" }}>
                            {dictionary["messages-title-inbox"]}
                          </Text>
                        </Col>
                        <Col>
                          <MessagesPending />
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xxl={24}
                  style={{ width: "100%", minHeight: 250 }}
                >
                  <div
                    className="contenedor"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 10,
                      border: "1px solid #e0e0e0",
                      boxShadow: "2px 2px 5px #00000020",
                      padding: size <= 991 ? "0%" : "4%",
                      overflow: "auto",
                      backgroundColor: 'white'
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 22,
                          color: "#129BD4",
                          textAlign: "center",
                          fontFamily: "Muli",
                        }}
                      >
                        {dictionary["messages-title-messages-by-job"]}
                      </Text>
                    </div>

                    <div
                      className="contenedor"
                      style={{ overflow: "scroll", width: "100%" }}
                    >
                      <PositionListChat />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xxl={12}
              style={{ width: "100%", marginTop: "2%" }}
            >
              <MessagesList />
            </Col>
          </Row>
        </Col>

        <Col
          xs={14}
          sm={14}
          md={14}
          lg={14}
          xxl={14}
          style={{ height: "100%", width: "100%", padding: "1%" }}
        >
          {messageList.length === 0 && filterJob !== "" && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
                {getTranslation("message-load-user-list-text", dictionary)}
              </Text>
            </div>
          )}
          <Row style={{ height: "100%", width: "100%" }}>
            {messagesListRedux.length > 0 && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xxl={24}
                style={{
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {filterJob === "Inbox" ? (
                  isActiveChat && idCandidateData !== "" ? (
                    <Chat
                      idCandidate={idCandidateData}
                      idHiring={user.idHiring}
                      nameCandidate={nameCandidate}
                      isChatScreen
                    />
                  ) : (
                    <Content
                      style={{
                        width: "100%",
                        height: "100%",
                        boxShadow: "7px 7px 15px #00000020",
                        borderRadius: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          textAlign: "center",
                          color: "#00000040",
                          fontFamily: "Muli",
                        }}
                      >
                        {dictionary["message-unselected-conversation"]}
                      </Text>
                    </Content>
                  )
                ) : (
                  filterJob === "MessageByJob" &&
                  positionSelected.idPosition !== "" &&
                  (isActiveChat && idCandidateData !== "" ? (
                    <Chat
                      idCandidate={idCandidateData}
                      idHiring={user.idHiring}
                      nameCandidate={nameCandidate}
                      isChatScreen
                    />
                  ) : (
                    <Content
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid",
                        boxShadow: "7px 7px 15px #00000020",
                        borderRadius: 16,
                        borderColor: "#00000016",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          textAlign: "center",
                          color: "#00000040",
                          fontFamily: "Muli",
                        }}
                      >
                        {dictionary["message-unselected-conversation"]}
                      </Text>
                    </Content>
                  ))
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

// Export
export default ChatScreen;
