import { Button, Divider, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CompanyInformationSection from "./companyInformationSection/CompanyInformationSection";
import ContactInformationSection from "./contactInformationSection/ContactInformationSection";
import PreviewInformationSection from "./PreviewInformationSection";
import { startUpdateUserInformation } from "../../../store/auth";
import { getTranslation } from "../../../hooks/hooks";
import { getHiring } from "../../../firebase/endpoints/hiringEndPoint";
import './index.css';
//Interface
interface Props {
  modalUser: boolean;
  setModalUser: (value: boolean) => void;
  setVisibleWelcomeModal: (value: boolean) => void;
}

// Component Ant Design
const { Text } = Typography;

// Component
const UpdateUserInfoModal: React.FC<Props> = ({
  modalUser,
  setModalUser,
  setVisibleWelcomeModal,
}) => {
  //States

  const { dictionary } = useSelector((state: any) => state.ui);

  const dispatch: Dispatch<any> = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const [avatarPersonal, setAvatarPersonal] = useState<string>("");
  const [avatarCompany, setAvatarCompany] = useState<string>("");
  const [nameForm, setNameForm] = useState<string>("");
  const [lastNameForm, setLastNameForm] = useState<string>("");
  const [emailForm, setEmailForm] = useState<string>("");
  const [phoneNumberForm, setPhoneNumberForm] = useState<string>("");
  const [companyInformationForm, setCompanyInformationForm] =
    useState<string>("");
  const [industryForm, setIndustryForm] = useState<string>("");
  const [titleForm, setTitleForm] = useState<string>("");
  const [websiteCompany, setWebsiteCompany] = useState<string>("");
  const [companyName, setCompanyNameForm] = useState<string>("");
  const [section, setSection] = useState<"contactInformation" | "companyInformation" | "previewInformation">("contactInformation");
  const [activeNextButton, setActiveNextButton] = useState<boolean>(true);

  // Handlers
  const handleOnSaveChanges = (idHiring: string, email: string) => {
    dispatch(
      startUpdateUserInformation({
        avatarCompany: avatarCompany,
        avatarPersonal: avatarPersonal,
        company: companyName,
        companyInformation: companyInformationForm,
        idHiring: idHiring,
        industry: industryForm,
        lastName: lastNameForm,
        name: nameForm,
        phoneNumber: phoneNumberForm,
        companyWebsite: websiteCompany,
        email: email,
        title: titleForm,
        communicationLanguaje: "English",
        idCompanyQR: user.idCompanyQR !== undefined ? user.idCompanyQR : "",
      })
    );
    setModalUser(false);
    setVisibleWelcomeModal(true);
  };

  const fetchHiring = useCallback(async (idHiring: string) => {
    const hiring: any = await getHiring(idHiring);

    if (hiring) {
      setNameForm(hiring.name);
      setLastNameForm(hiring.lastName);
      setEmailForm(hiring.email);
      setPhoneNumberForm(hiring.phoneNumber);
      setCompanyNameForm(hiring.company);
      setIndustryForm(hiring.industry);
      setWebsiteCompany(hiring.companyWebsite);
      setCompanyInformationForm(hiring?.companyInformation);
      setTitleForm(hiring.title);
    }
  }, []);

  useEffect(() => {
    fetchHiring(user?.idHiring);
  }, [user?.idHiring, fetchHiring]);

  useEffect(() => {
    if (section === "contactInformation") {
      if (
        nameForm?.length > 0 &&
        lastNameForm?.length > 0 &&
        phoneNumberForm?.length > 0 &&
        titleForm?.length > 0
      ) {
        setActiveNextButton(false);
      } else {
        setActiveNextButton(true);
      }
    } else if (section === "companyInformation") {
      if (
        companyInformationForm?.length > 0 &&
        industryForm?.length > 0 &&
        companyName?.length > 0 &&
        websiteCompany?.length > 0
      ) {
        setActiveNextButton(false);
      } else {
        setActiveNextButton(true);
      }
    } else if (section === "previewInformation") {
    }
  }, [section, nameForm?.length, lastNameForm?.length, phoneNumberForm?.length, avatarPersonal?.length, industryForm?.length, companyName?.length, websiteCompany?.length, companyInformationForm?.length, avatarCompany?.length, titleForm?.length,]);

  return (
    <Modal
      title={
        <Row style={{ height: 'auto', width: "100%" }}>
          {section === "contactInformation" ? (
            <Row className="container-header">
              {/* <img
                alt="icon Irgt"
                src={iconIRGTLogoWhite}
                style={{ height: "50px", width: "150px", position: 'absolute' }}
              /> */}
              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {getTranslation("first-login-modal-title-general1", dictionary)}
                </Text>
              </Row>

              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {dictionary["first-login-modal-section1-title1"]}
                </Text>
              </Row>
            </Row>
          ) : section === "companyInformation" ? (
            <Row className="container-header">
              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {dictionary["first-login-modal-title-general1"]}
                </Text>
              </Row>

              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {dictionary["first-login-modal-section2-title1"]}
                </Text>
              </Row>
            </Row>
          ) : section === "previewInformation" ? (
            <Row className="container-header">
              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {dictionary["first-login-modal-title-general1"]}
                </Text>
              </Row>

              <Row className="container-header-subtitle">
                <Text className="title-text">
                  {dictionary["first-login-modal-section3-title-1"]}
                </Text>
              </Row>
            </Row>
          ) : null}
        </Row>
      }
      width={"100vw"}
      centered
      open={modalUser}
      closable={false}
      onOk={() => setModalUser(false)}
      footer={
        <Row style={{ width: "100%", height: 'auto' }}>
          {section === "contactInformation" ? (
            <Row className="container-footer">
              <Button
                disabled={activeNextButton}
                className="button-next-section"
                onClick={() => setSection("companyInformation")}
              >
                {dictionary["creation-modal-button-next"]}
              </Button>
            </Row>
          ) : section === "companyInformation" ? (
            <Row className="container-footer">
              <Row style={{
                display: "flex",
                justifyContent: "space-around",
                width: "auto",
                flexWrap: 'wrap',
                gap: 10
              }}
              >
                <Button
                  className="button-next-section"
                  onClick={() => setSection("contactInformation")}
                  style={{ backgroundColor: '3f4093aa' }}
                >
                  {dictionary["creation-modal-button-previous"]}
                </Button>

                <Button
                  disabled={activeNextButton}
                  className="button-next-section"

                  onClick={() => setSection("previewInformation")}
                >
                  {dictionary["creation-modal-button-next"]}
                </Button>
              </Row>
            </Row>
          ) : (
            section === "previewInformation" && (
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "auto",
                    flexWrap: 'wrap',
                    gap: 10
                  }}
                >
                  <Button
                    className="button-next-section"
                    onClick={() => setSection("companyInformation")}
                  >
                    {dictionary["creation-modal-button-previous"]}
                  </Button>

                  <Button
                    className="button-next-section"
                    onClick={() =>
                      handleOnSaveChanges(user?.idHiring, user?.email)
                    }
                  >
                    {dictionary["editing-modal-confirm-change-button-save"]}
                  </Button>
                </Row>
              </Row>
            )
          )}
        </Row>
      }
    >
      <Content className="container-modal">
        <Divider />
        {section === "contactInformation" ? (
          <ContactInformationSection
            titleForm={titleForm}
            setTitleForm={setTitleForm}
            avatarPersonal={avatarPersonal}
            emailForm={emailForm}
            lastNameForm={lastNameForm}
            nameForm={nameForm}
            phoneNumberForm={phoneNumberForm}
            setAvatarPersonal={setAvatarPersonal}
            setLastNameForm={setLastNameForm}
            setNameForm={setNameForm}
            setPhoneNumberForm={setPhoneNumberForm}
          />
        ) : section === "companyInformation" ? (
          <CompanyInformationSection
            avatarCompany={avatarCompany}
            companyInformationForm={companyInformationForm}
            companyName={companyName}
            industryForm={industryForm}
            setAvatarCompany={setAvatarCompany}
            setCompanyInformationForm={setCompanyInformationForm}
            setCompanyNameForm={setCompanyNameForm}
            setIndustryForm={setIndustryForm}
            setWebsiteCompany={setWebsiteCompany}
            websiteCompany={websiteCompany}
          />
        ) : (
          section === "previewInformation" && (
            <PreviewInformationSection
              titleForm={titleForm}
              avatarPersonal={avatarPersonal}
              emailForm={emailForm}
              lastNameForm={lastNameForm}
              nameForm={nameForm}
              phoneNumberForm={phoneNumberForm}
              setAvatarPersonal={setAvatarPersonal}
              setLastNameForm={setLastNameForm}
              setNameForm={setNameForm}
              setPhoneNumberForm={setPhoneNumberForm}
              avatarCompany={avatarCompany}
              companyInformationForm={companyInformationForm}
              companyName={companyName}
              industryForm={industryForm}
              setAvatarCompany={setAvatarCompany}
              setCompanyInformationForm={setCompanyInformationForm}
              setCompanyNameForm={setCompanyNameForm}
              setIndustryForm={setIndustryForm}
              setWebsiteCompany={setWebsiteCompany}
              websiteCompany={websiteCompany}
            />
          )
        )}
      </Content>
    </Modal>
  );
};

export default UpdateUserInfoModal;
