//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
//Interface
interface Props {
    saveWorkplace: (arrayString: Array<string>) => void
    dataWorkplace: Array<string>
}

//Component
const WorkplaceCheckBox: React.FC<Props> = ({ saveWorkplace, dataWorkplace }) => {

    // States
    const [statusOnSite, setStatusOnsite] = useState<boolean>(false)
    const [statusRemote, setStatusRemote] = useState<boolean>(false)
    const [statusHybrid, setStatusHybrid] = useState<boolean>(false);

    const { dictionary } = useSelector((state: any) => state.ui);


    useEffect(() => {
        const arrayString = []
        if (statusOnSite === true) {
            arrayString.push("On-Site")
        }
        if (statusRemote === true) {
            arrayString.push("Remote")
        }
        if (statusHybrid === true) {
            arrayString.push("Hybrid")
        }
        saveWorkplace(arrayString);
    }, [statusOnSite, statusRemote, statusHybrid, saveWorkplace])

    useEffect(() => {
        if (dataWorkplace === undefined) {
            return
        }
        dataWorkplace.forEach((element: string) => {
            if (element === "On-Site") {
                setStatusOnsite(true)
            }
            if (element === "Remote") {
                setStatusRemote(true)
            }
            if (element === "Hybrid") {
                setStatusHybrid(true)
            }
        });
    }, [dataWorkplace])

    return (

        <Row style={{ width: '100%', alignItems: 'center' }}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} style={{ alignItems: 'center' }} >
                <Checkbox checked={statusOnSite} style={{ fontSize: 16 }} onChange={() => setStatusOnsite(!statusOnSite)}>{dictionary['creation-modal-page-one-checkbox-on-site']}</Checkbox>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                <Checkbox checked={statusRemote} style={{ fontSize: 16 }} onChange={() => setStatusRemote(!statusRemote)}>{dictionary['creation-modal-page-one-checkbox-remote']}</Checkbox>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                <Checkbox checked={statusHybrid} style={{ fontSize: 16 }} onChange={() => setStatusHybrid(!statusHybrid)}>{dictionary['creation-modal-page-one-checkbox-hybrid']}</Checkbox>
            </Col>
        </Row>


    )
}

//Export
export default WorkplaceCheckBox;