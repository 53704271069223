//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "../../../../types/position";
import { useNavigate } from "react-router-dom";
import { clearFilterJob, clearPositionSelect, clearUserChatSelected } from "../../../../store/chat";
import { getUserSelected } from "../../../../store/job";
import { getPositionSideBarSelected } from "../../../../store/ui";

// Interface
interface Props {
  position: Job;
  status: "waiting for reply"
  | "meeting"
  | "interview"
  | "interested"
  | "hired"
  | "declined"
  | "offer"
}

// Component
const PositionButton: React.FC<Props> = ({ position, status }) => {
  // States
  const navigate = useNavigate();
  const userChatSelected = useSelector(
    (state: any) => state.chat.userChatSelected
  );

  const positionSelected = useSelector(
    (state: any) => state.chat.positionSelected
  );
  const dispatch = useDispatch();



  const goToJobList = () => {

    navigate("/jobList");
    dispatch(getPositionSideBarSelected(position));
    dispatch(getUserSelected(userChatSelected));
    dispatch(clearFilterJob());
    dispatch(clearPositionSelect());
    dispatch(clearUserChatSelected());
  }

  const { Text } = Typography;
  return (
    <div
      onClick={status === 'declined' ? () => console.log('nothing') : goToJobList}
      style={{
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        flexDirection: "row",
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        marginRight: 10,
        backgroundColor: positionSelected.idPosition === position.idPosition ? '#24CECE' : '#00000050', cursor: 'pointer'
      }}
    >
      <Text
        style={{
          fontSize: 20,
          color: 'black',
          textAlign: "center",
          fontFamily: 'Muli'
        }}>
        {position.title}
      </Text>
    </div>
  );
};

//Export
export default PositionButton;

