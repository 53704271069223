import { Button, Result } from 'antd'
import React from 'react'
import { StateGeneralRedux } from '../../../../types/redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RegisterSuccess: React.FC = () => {


    const navigate = useNavigate();
    const openLoginPage = () => {
        navigate('/login');
    }
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    return (
        <div className='loading-register-form'>
            <Result
                status='success'
                title={dictionary['register-success-title']} register-success-sub-title
                subTitle={dictionary['register-success-sub-title']}
                extra={<Button style={{ borderRadius: '20px' }} type="primary" onClick={openLoginPage}>{dictionary['register-success-proceed-to-login-button']}</Button>}
            />
        </div>
    )
}

export default RegisterSuccess
