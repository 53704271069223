//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
import { Avatar, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
// import arrowRight from "../../../../../image/ArrozRigth.png";
import { HomeOutlined, PlusCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { formatNumber } from '../../../../../hooks/hooks';


//Interface
interface Props {
  type: Array<string>;
  salary: string;
  salaryMax: string;
  bonus: string;
  hourlyCheck: boolean;
  amountCheck: boolean;
  percentageCheck: boolean;
  annualCheck: boolean;
  otherDetailsCompensation: string,
  valueHourForWeek: number;
  checkMin: boolean;
  checkMiddle: boolean;
  checkMax: boolean;
  location: any;
  workplace: Array<string>;
}
const ThirdSectionCarousel: React.FC<Props> = ({ type = [], location, workplace, checkMin, checkMiddle, checkMax, amountCheck, annualCheck, bonus, hourlyCheck, percentageCheck, salary, otherDetailsCompensation, valueHourForWeek, salaryMax }) => {

  const user = useSelector((state: any) => state.auth.user);
  const { Text } = Typography;
  const { dictionary } = useSelector((state: any) => state.ui);
  const [salaryState, setSalaryState] = useState<string>('hr');
  const [bonusState, setBonusState] = useState('%');
  const [totalSalary, settotalSalary] = useState<any>("");
  const [typePreview, setTypePreview] = useState<Array<string>>([]);
  const [remoteCheck, setRemoteCheck] = useState<boolean>(false);
  const [onSiteCheck, setonSiteCheck] = useState<boolean>(false);
  const [hybridCheck, setHybridCheck] = useState<boolean>(false);


  useEffect(() => {
    workplace.forEach((each: any) => {
      if (each === 'Remote') {
        setRemoteCheck(true);
      } else if (each === 'Hybrid') {
        setHybridCheck(true);
      } else if (each === 'On-Site') {
        setonSiteCheck(true);
      }
    })
  }, [workplace]);


  useEffect(() => {
    if (hourlyCheck && !annualCheck) {
      setSalaryState('hr');
    } else if (!hourlyCheck && annualCheck) {
      setSalaryState('')
    }
  }, [hourlyCheck, annualCheck]);

  useEffect(() => {
    if (percentageCheck && !amountCheck) {
      setBonusState('%');
    } else if (!percentageCheck && amountCheck) {
      setBonusState('')
    }
  }, [percentageCheck, amountCheck]);

  useEffect(() => {
    const numMin = parseFloat(salary.replace(/,/g, ""))
    const numMax = parseFloat(salaryMax.replace(/,/g, ""))
    const numMid = (numMin + numMax) / 2

    let numero1: number = checkMin === true ? numMin : checkMax === true ? numMax : checkMiddle === true ? numMid : numMid
    let numero2: number = parseFloat(bonus.replace(/,/g, ""))



    var numeroResult: number = 0;

    if (hourlyCheck === true && amountCheck === false && percentageCheck === false) {
      numeroResult = (((numero1 * valueHourForWeek) * 50) + 0);
      settotalSalary(numeroResult);
    } else {
      if (hourlyCheck === true) {
        if (amountCheck === true) {
          numeroResult = (((numero1 * valueHourForWeek) * 50) + numero2);
          settotalSalary(numeroResult);
        } else {
          if (percentageCheck === true) {
            numeroResult = ((numero1 * valueHourForWeek) * 50);
            numeroResult = ((numeroResult + ((numero2 * numeroResult) / 100)))
            settotalSalary(numeroResult);
          }
        }
      }
    }



    if (annualCheck === true && amountCheck === false && percentageCheck === false) {
      numeroResult = (numero1);
      settotalSalary(numeroResult);
    } else {
      if (annualCheck === true) {
        if (amountCheck === true) {
          numeroResult = numero1 + numero2;
          settotalSalary(numeroResult);
        } else {
          if (percentageCheck === true) {
            numeroResult = numero1
            numeroResult = ((numero1 + ((numero2 * numeroResult) / 100)))

            settotalSalary(numeroResult);
          }
        }
      }
    }

  }, [hourlyCheck, annualCheck, percentageCheck, amountCheck, salary, bonus, valueHourForWeek, salaryMax, checkMin, checkMiddle, checkMax,])

  useEffect(() => {

    const newArrayType: Array<string> = [];
    type.forEach((typeName: string) => {
      if (typeName === 'Contract') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-contract']);
      } else if (typeName === 'Full time') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-full-time']);
      } else if (typeName === 'Part time') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-part-tim']);
      } else if (typeName === 'Permanent') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-permanent']);
      } else if (typeName === 'Training') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-training']);
      } else if (typeName === 'Seasonal') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-seasonal']);
      } else if (typeName === 'Freelance') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-freelance']);
      } else if (typeName === 'Volunteer') {
        newArrayType.push(dictionary['creation-modal-page-one-checkbox-volunteer']);
      }
    })

    setTypePreview(newArrayType);
  }, [type, dictionary])

  return (
    <div className="contenedor" style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>

      <Row style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
        <Text style={{ fontSize: 22, fontFamily: 'Muli' }}>{dictionary['creation-modal-preview-field-work-condition']}</Text>


        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "2vh" }}>
          <Text style={{ fontSize: 26, fontFamily: 'Muli', color: "#6153d3" }}>${formatNumber(totalSalary.toString())}<Text style={{ fontSize: 18, fontFamily: 'Muli', color: "#6153d3" }}>{"("}{checkMin === true ? dictionary["new-job-modal-check-title1"] : checkMax === true ? dictionary["new-job-modal-check-title2"] : checkMiddle === true ? dictionary["new-job-modal-check-title4"] : ""}{")"}</Text> </Text>
        </Row>

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: -3 }}>
          <Text style={{ fontSize: 18, fontFamily: 'Muli' }}>{dictionary['creation-modal-preview-field-total-compensation']}</Text>
        </Row>
        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Text style={{ fontSize: 15, fontFamily: 'Muli' }}>(Based on {valueHourForWeek}h/week)</Text>
        </Row>


        <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: "2vh" }}>
          <Text style={{ fontSize: 22, fontFamily: 'Muli', }}>{dictionary["new-job-modal-text-title1"]}: $ {formatNumber(salary)}{salaryState.length > 0 ? '/' + salaryState : salaryState}</Text>
          <Text style={{ fontSize: 22, fontFamily: 'Muli', }}>{dictionary["new-job-modal-text-title2"]}: $ {formatNumber(salaryMax)}{salaryState.length > 0 ? '/' + salaryState : salaryState}</Text>

        </Row>
        {
          bonus.length > 0 && (
            <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
              <Text style={{ fontSize: 22, fontFamily: 'Muli', }}>Bonus: {bonusState.length > 0 ? '' : '$'} {formatNumber(bonus)}{bonusState.length > 0 ? bonusState : bonusState}</Text>

            </Row>
          )
        }



        {otherDetailsCompensation?.length > 0 && (
          <div style={{ width: "100%", flexDirection: "row", display: "flex", marginTop: 10 }}>
            <div style={{ width: "10%" }}>
              <Text style={{ fontSize: 20 }}> <PlusCircleOutlined style={{ color: "#414193" }} /></Text>
            </div>
            <div style={{ width: "90%", height: "100%", display: "flex", alignItems: "center" }}>
              <Text style={{ fontSize: 15, fontFamily: 'Muli' }}>{otherDetailsCompensation}</Text>
            </div>

          </div>
        )}

        <div style={{ width: "100%", flexDirection: "row", display: "flex", marginTop: 10, marginBottom: 10 }}>
          <div style={{ width: "10%" }}>
            <Text style={{ fontSize: 20 }}> <EnvironmentOutlined style={{ color: "#414193" }} /></Text>
          </div>
          <div style={{ width: "90%", height: "100%", display: "flex", alignItems: "center" }}>
            <Text style={{ fontSize: 15, fontFamily: 'Muli' }}>{location}</Text>
          </div>

        </div>

        {onSiteCheck &&
          <div style={{ width: "100%", }}>


            <Row style={{ display: 'flex' }}>
              <Text style={{ fontSize: 18, fontFamily: 'Muli' }}>• {dictionary['creation-modal-preview-field-on-site']}</Text>
            </Row>
          </div>
        }

        {hybridCheck &&
          <div style={{ width: "100%", }} >
            <Row style={{ display: 'flex' }}>
              <Text style={{ fontSize: 18, fontFamily: 'Muli' }}>• {dictionary['creation-modal-preview-field-hybrid']}</Text>
            </Row>
          </div>
        }



        {remoteCheck &&
          <div style={{ width: "100%", }}  >

            <Row style={{ display: 'flex' }}>
              <Text style={{ fontSize: 18, fontFamily: 'Muli' }}>• {dictionary['creation-modal-preview-field-remote']}</Text>
            </Row>
          </div>
        }

        <Row style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: 5 }}>
          {
            typePreview.map((each, index) => (
              <Col key={each} span={20} style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Text key={index + 'typeText'} style={{ fontSize: 18, fontFamily: 'Muli' }}>• {each}</Text>
              </Col>
            ))
          }
        </Row>
      </Row>

      <Row style={{ marginTop: 30, marginLeft: 30, marginRight: 30 }}>
        <Row>
          <Text style={{ fontSize: 22, fontFamily: 'Muli' }}>{dictionary['creation-modal-preview-field-about-the-company']}</Text>
        </Row>

        <Row style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 20 }}>
          {
            user?.avatarCompany !== "" ? (
              <img
                alt='User company'
                src={user.avatarCompany}
                style={{ height: 80, width: 'auto' }}
              />

            ) : <Avatar size={64} icon={<HomeOutlined />} />
          }
        </Row>

        <Row style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 20 }}>
          <Text style={{ textAlign: 'center', fontSize: 18, fontFamily: 'Muli' }}>{user.company}</Text>
        </Row>

        <Row style={{ width: '100%', marginTop: 10 }}>
          <Text style={{ fontSize: 18, display: 'inline-block', fontFamily: 'Muli' }}>{user?.companyInformation}</Text>

        </Row>

        <Row style={{ width: '100%', marginTop: 10 }}>
          <Text style={{ fontSize: 18, display: 'inline-block', fontFamily: 'Muli' }}>{user?.companyWebsite}</Text>

        </Row>
      </Row>
    </div>
  )
}

export default ThirdSectionCarousel;