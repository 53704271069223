//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import { Content } from "antd/lib/layout/layout";
import React from "react";
import Recommendation from "../components/recommendation/Recommendation";

// Component
const RecommendationScreen: React.FC = () => {
  return (
    <Content style={{ height: '100%', width: '100%', backgroundColor: '#00000004' }}>
      <Recommendation />
    </Content>
  );
};

// Exports
export default RecommendationScreen;
