// Dependencies
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import Chat from "../components/support/chat/Chat";
import Questions from "../components/support/Questions";

// Component
const SupportScreen: React.FC = () => {
  return (
    <Content style={{ height: "100%", width: "100%", backgroundColor: '#00000004' }}>
      <Row style={{ height: "100%", width: "100%" }}>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 2 }}
          lg={{ span: 12, offset: 0 }}
          md={{ span: 12, offset: 0 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          style={{
            height: "100%",
            width: "100%",
            padding: 20,
            paddingLeft: 40,
          }}
        >
          <Questions />
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 22, offset: 2 }}
          lg={{ span: 12 }}
          md={{ span: 12, offset: 0 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          style={{ height: "100%", width: "100%", padding: 20 }}
        >
          <Chat />
        </Col>
      </Row>
    </Content>
  );
};

// Export
export default SupportScreen;
