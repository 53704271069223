import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../firebase/config";

export const startSaveLogFailedAttempts = async (count: number, lastFailedAttempt: any, email: string, errorCode: string, errorMessage: string) => {
  try {
    // Crea una referencia al documento del usuario en la colección 'failedLoginAttempts'
    const userDocRef = doc(collection(db, 'failedLoginAttempts'), email);
    // Añade un nuevo documento a la subcolección 'attempts' del usuario
    await addDoc(collection(userDocRef, 'attempts'), {
      count,
      lastFailedAttempt,
      email,
      errorCode,
      errorMessage,
    });
    return true;
  } catch (error) {
    console.error("Error saving log: ", error);
    return false;
  }
}