//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { RecommendationCandidate } from "../../../../types/candidate";
import { useDispatch, useSelector } from "react-redux";


import { Content } from "antd/lib/layout/layout";
import { Col, Row, Typography } from "antd";
import { clearUserSelected, getUserSelected } from "../../../../store/job";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase/config";
// Interfaces
interface Props {
  candidate: RecommendationCandidate;
}

// Component
const ResumeCandidateCard: React.FC<Props> = ({ candidate }) => {

  // States
  const userSelected = useSelector((state: any) => state.jobList.userSelected);
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const dispatch = useDispatch();
  const [backgroundColorStatus, setBackgroundColorStatus] = useState('#00000016');

  //Effects
  useEffect(() => {
    if (!candidate?.id || !candidate?.type) {
      return;
    }

    // Determine the correct collection based on candidate type
    const collectionName = candidate.type === 'candidate' ? 'candidates' : 'preCandidates';

    // Reference to the specific candidate document
    const candidateDocRef = doc(db, collectionName, candidate.id);

    const unsubscribe = onSnapshot(candidateDocRef, (candidateSnap) => {
      const candidateData = candidateSnap.data() as { name: string, lastName: string, id: string };

      if (candidateData) {
        candidateData.name !== "" && setName(candidateData.name);
        candidateData.lastName !== "" && setLastName(candidateData.lastName);
      }
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();

  }, [candidate?.id, candidate?.type]);

  const { Text } = Typography;

  useEffect(() => {
    if (userSelected?.id === candidate.id) {
      if (candidate.status === 'interested') {
        setBackgroundColorStatus('#FEC898');
      } else if (candidate.status === 'meeting') {
        setBackgroundColorStatus('#F5C033');
      } else if (candidate.status === 'interview') {
        setBackgroundColorStatus('#A1E299')
      } else if (candidate.status === 'hired') {
        setBackgroundColorStatus('#77AB59')
      } else if (candidate.status === 'declined') {
        setBackgroundColorStatus('#707077')
      } else if (candidate.status === 'offer') {
        setBackgroundColorStatus('#62A2F6')
      }
    } else {
      if (candidate.status === 'interested') {
        setBackgroundColorStatus('#FEC892');
      } else if (candidate.status === 'meeting') {
        setBackgroundColorStatus('#F5C011');
      } else if (candidate.status === 'interview') {
        setBackgroundColorStatus('#A1E291')
      } else if (candidate.status === 'hired') {
        setBackgroundColorStatus('#77AB59')
      } else if (candidate.status === 'declined') {
        setBackgroundColorStatus('#707070')
      } else if (candidate.status === 'offer') {
        setBackgroundColorStatus('#62A2F6')
      }

    }
  }, [userSelected?.id, candidate.id, candidate.status]);

  return (
    <Content
      style={{
        backgroundColor: backgroundColorStatus,
        border: '1px solid',
        borderRadius: 10,
        borderColor: '#00000016',
        height: 60,
        width: "100%",
        marginTop: '3%',
        cursor: 'pointer',
        opacity: userSelected?.id === candidate?.id ? 1 : 0.5,
        padding: '5px'
      }}
      onClick={() => {
        userSelected?.id === candidate?.id
          ? dispatch(clearUserSelected())
          : dispatch(getUserSelected(candidate));
      }}
    >
      <Row
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        <Col
          style={{
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',      // Ensures the text does not overflow the container
          }}
        >
          <Text
            style={{
              fontSize: 15,
              color: 'black',
              fontWeight: userSelected?.id === candidate?.id ? 'bold' : 'initial',
              whiteSpace: 'nowrap',    // Prevents text from wrapping to the next line
              overflow: 'hidden',      // Hides any overflow content
              textOverflow: 'ellipsis' // Adds ellipsis when the text overflows
            }}
          >
            {name} {lastName}
          </Text>
        </Col>
      </Row>
    </Content>
  );
};

// Export
export default ResumeCandidateCard;
