import { Button, Col, Row, Typography } from 'antd';
import React from 'react'
import { useSelector } from 'react-redux';

import { getTranslation } from '../../../../../hooks/hooks';
import { Timestamp } from 'firebase/firestore';
// Interface
interface Props {
  position: any;
  handleOnClickButtonPreview: () => void;
  handleOnClickButtonGo: () => void;
}
// Component
const { Text } = Typography;

const PositionInfoCard: React.FC<Props> = ({ position, handleOnClickButtonGo, handleOnClickButtonPreview }) => {

  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Row
      style={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fff",
        border: "solid, 0.1px, #f4f7fc",
        borderRadius: '20px'
      }}
    >
      <Row style={{ width: "100%", marginTop: 20 }}>
        <Col
          style={{
            display: "flex",
            alignItems: "center"
          }}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          <Text style={{ fontSize: 20, fontWeight: "bold", marginLeft: 10 }}>{dictionary["creation-modal-initial-page-title-job-selected"]}: <Text style={{ fontWeight: "normal", fontSize: 20 }}>{position?.title}</Text></Text>
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 20 }}>
        <Col
          style={{
            display: "flex",
            alignItems: "center"
          }}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
        >
          <Text style={{ fontSize: 20, fontWeight: "bold", marginLeft: 10 }}>
            {dictionary["creation-modal-initial-page-create-at-job-selected"]}: <Text style={{ fontSize: 20, fontWeight: "normal" }}>
              {position.createAt ? (
                new Timestamp(
                  position?.createAt?.seconds,
                  position?.createAt?.nanoseconds
                )
                  .toDate()
                  .getDate() +
                "-" +
                (new Timestamp(
                  position?.createAt?.seconds,
                  position?.createAt?.nanoseconds
                )
                  .toDate()
                  .getMonth() +
                  1) +
                "-" +
                new Timestamp(
                  position?.createAt?.seconds,
                  position?.createAt?.nanoseconds
                )
                  .toDate()
                  .getFullYear()
              ) : (
                <Text style={{ fontSize: 20, fontWeight: "normal" }}>Template</Text>
              )}
            </Text>
          </Text>
        </Col>

      </Row>

      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          border: "solid, 0.1px, #f4f7fc",
          marginTop: 20,
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
            marginBottom: 5
          }}
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={{ span: 12 }}
          xxl={12}
        >
          <Button
            size="middle"
            style={{
              fontSize: 19,
              borderBottomRightRadius: 20,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              width: 170,
              paddingTop: 16,
              paddingBottom: 16,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              background:
                "linear-gradient(90deg, rgba(226,184,66,1) 0%, rgba(253,134,71,1) 100%)",
              color: "white",
            }}
            onClick={handleOnClickButtonPreview}
          >
            {dictionary["creation-modal-initial-page-button-preview"]}
          </Button>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
          }}
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={{ span: 12 }}
          xxl={12}
        >
          <Button
            size="middle"
            style={{
              fontSize: 19,
              borderBottomRightRadius: 20,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              width: 170,
              paddingTop: 16,
              paddingBottom: 16,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              background:
                "linear-gradient(90deg, rgba(196,196,78,1) 0%, rgba(157,196,81,1) 100%)",
              color: "white",
            }}
            onClick={handleOnClickButtonGo}
          >
            {getTranslation('creation-modal-initial-page-button-use-template', dictionary)}
          </Button>
        </Col>
      </Row>
    </Row>
  );
}

export default PositionInfoCard