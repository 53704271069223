// Dependencies
import { Button, Col, Input, Row, Spin } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import {db, storage} from '../../../firebase/config';
import { uid } from "uid";
import UploadButtonDocument from '../../../common/UploadButtonDocument';
import UploadButtonImage from '../../../common/UploadButtonImage';
import iconPlane from '../../../image/ico_sent-plane.png';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Timestamp, collection, doc, runTransaction, updateDoc } from 'firebase/firestore';
// Component
const { TextArea } = Input;
const InputSendMessage:React.FC = () => {

    //State
    const  user  = useSelector((state:any)=>state.auth.user);

    const [textInput, setTextInput] = useState<string>("");
    const [document, setDocument] = useState<{
        name: string;
        url: string;
      } | null>(null);
      const [image, setImage] = useState<{ name: string; url: string } | null>(
        null
      );
    const [loading, setLoading] = useState(false);
    const updateLastMessageCandidate = async (idHiring: string, idAdmin: string) => {
        try {
            // Reference to the specific document in the "messages" subcollection under "admin"
            const messageDocRef = doc(db, `admin/${idAdmin}/messages`, idHiring);
            
            // Update the document with the new data
            await updateDoc(messageDocRef, {
                dateLastMessage: Timestamp.now(), // Use Firestore Timestamp for the current time
                messagesPending: true
            });
    
            console.log("Admin message document successfully updated");
        } catch (error) {
            console.error("Error updating admin message document: ", error);
            // Handle the error appropriately
        }
    }


    const getDocumentData = async(url:string,idHiring:string,idAdmin:string):Promise<{
        fromUrlDocument:string,toUrlDocument:string
    }>=>{
       
            const idImage=uid(20);
            const response = await fetch(url);

            const blob = await response.blob();
            
            const fromUserPathStorageRef = ref(storage, `imagesChat/${idHiring}/${idAdmin}/${idImage}`);
            
            const toUserPathStorageRef = ref(storage, `imagesChat/${idAdmin}/${idHiring}/${idImage}`);

            await uploadBytes(fromUserPathStorageRef, blob);
            await uploadBytes(toUserPathStorageRef, blob);
            const fromUrlDocument = await getDownloadURL(fromUserPathStorageRef);
            const toUrlDocument = await getDownloadURL(toUserPathStorageRef);

            return  {fromUrlDocument,toUrlDocument}
        
    }

    const getImageData = async(url:string,idHiring:string,idAdmin:string):Promise<{
        fromUrlImage:string,toUrlImage:string
    }>=>{
       
            const idImage=uid(20);
            const response = await fetch(url);

            const blob = await response.blob();
            
            const fromUserPathStorageRef = ref(storage, `imagesChat/${idHiring}/${idAdmin}/${idImage}`);
            
            const toUserPathStorageRef = ref(storage, `imagesChat/${idAdmin}/${idHiring}/${idImage}`);

            await uploadBytes(fromUserPathStorageRef, blob);
            await uploadBytes(toUserPathStorageRef, blob);
            const fromUrlImage = await getDownloadURL(fromUserPathStorageRef);
            const toUrlImage = await getDownloadURL(toUserPathStorageRef);

            return  {fromUrlImage,toUrlImage}
        
    }
    // Handlers
    const handleOnSendMessage = async(text:string,image:{ name: string; url: string;}|null,document:{name: string;url: string; }|null,idAdmin:string,idHiring:string)=>{
        try {
            if( text.length === 0 && image === null && document === null){
                return;
            }
            setLoading(true);
            const fromUserRef = collection(db, "hirings", idHiring, "messages", idAdmin, "chat");
            const toUserRef = collection(db, "admin", idAdmin, "messages", idHiring, "chat");
            
            const idMessage = uid(20);
            
            let fromUrlImage: string | null = null;
            let toUrlImage: string | null = null;

            let fromUrlDocument: string | null = null;
            let toUrlDocument: string | null = null;

            if (image !== null) {
                const imageData = await getImageData(image.url, idHiring,idAdmin);
                fromUrlImage = imageData.fromUrlImage;
                toUrlImage = imageData.toUrlImage;
            }
            if(document !== null){
                const documentData = await getDocumentData(document.url,idHiring,idAdmin);
                fromUrlDocument = documentData.fromUrlDocument;
                toUrlDocument = documentData.toUrlDocument;
            };

            const fromAdminMessageData = {
                id: idMessage,
                chatUser:"from",
                text: text.length > 0 ? text : null,
                date: Timestamp.now(),
                nameDocument: document !== null ? document.name : null,
                urlImage: image !== null ? fromUrlImage:null,
                urlDocument: document !== null ? fromUrlDocument: null,
                check:false
            };

            

            const toDestinationMessageData = {
                id: idMessage,
                chatUser:"to",
                text: text.length > 0 ? text : null,
                date: Timestamp.now(),
                nameDocument: document !== null ? document.name : null,
                urlImage: image !== null ? toUrlImage:null,
                urlDocument: document !== null ? toUrlDocument: null,
                check:false
            }

            await runTransaction(db, async (transaction) => {
                // Referencia al documento específico dentro de la subcolección 'chat'
                const specificDocRef = doc(fromUserRef, idMessage);
        
                // Establece el documento con los datos proporcionados
                transaction.set(specificDocRef, fromAdminMessageData);
            });
            

            await runTransaction(db, async (transaction) => {
                // Referencia al documento específico dentro de la subcolección 'chat'
                const specificDocRef = doc(toUserRef, idMessage);
        
                // Establece el documento con los datos proporcionados
                transaction.set(specificDocRef, toDestinationMessageData);
            });
            
            updateLastMessageCandidate(idHiring,idAdmin);
            setLoading(false);
            setDocument(null);
            setImage(null);
            setTextInput("");
            
            
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

  return (
    <>
        <Row style={{height:'35%',width:'100%',paddingLeft:10}}>
            <Col
                style={{
                    height: "100%",
                    width:'fit-content', 
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                }}
            >
                <UploadButtonDocument setDocument={setDocument} />
            </Col>

            <Col
                style={{
                    height: "100%",
                    width:'fit-content', 
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'      
                }}
            >
              <UploadButtonImage setImage={setImage} />
            </Col>
        </Row>

        <Row style={{ height:'65%',width:'100%',borderRadius:10,boxShadow: "2px 2px 10px #00000010",border:'1px solid #00000020'}}>
            <Col lg={{span:22}} xl={{span:23}} xxl={{span: 22}} style={{padding:5}}>
                <TextArea value={textInput} onChange={(e)=>setTextInput(e.target.value)} bordered={false} style={{height:'100%',fontFamily:'Muli'}}/>
            </Col>
            
            <Col lg={{span:2}} xxl={{span: 2}} style={{borderLeft:'1px solid #00000010',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',padding:5}}>
                {
                    loading ? (
                        <Spin/>
                    ):(
                        <Button style={{border:'transparent'}}  icon={<img src={iconPlane} alt='icon-sent-plane'/>} onClick={()=>handleOnSendMessage(textInput,image,document,'huiCo2D8l6ZY7NuyNruK6NH6CCG3',user?.idHiring)}/>
                    )
                }
            </Col>
        </Row>
    </>
  )
}

// Export
export default InputSendMessage