import React from 'react'
import { MessagesModified } from '../../../types/message';
import { Col, Image, Row, Typography } from 'antd';
import iconMessage  from '../../../image/ico-profile-color-3x.png'
//Interface 
interface Props {
    message: MessagesModified;
    justifyContent: string;
    backgroundColor: string;
    textAlign: any | undefined;
    color: string;
  }

const {Text } = Typography;
const ChatDetail:React.FC<Props> = ({ color,message,justifyContent,backgroundColor,textAlign }) => {
  const name = 'Administrator'
  const lastName= 'Support'
  //States
  return (
    <Row style={{width:'100%',display:'flex',justifyContent:justifyContent,marginBottom:20,height:'fit-content'}}>
      <Row style={{width:'100%',marginBottom:5}}>
        {
          message.chatUser === 'to' ? (
            <>
            {
                message.showName && (
                    <>
                        <Col>
                            <div style={{ display: "flex",position:'relative' }}>
                            <img
                                src={iconMessage}
                                style={{ height: 'auto', width: 45 }}
                                alt="icon profile color"
                            />
                            
                            <Text
                                style={{fontSize:17,display:'flex',fontFamily:'Muli',position:'absolute',top:'45%',left:'50%',transform:'translate(-50%, -50%',color:'white'
                                }}
                            >
                                {name.substring(0, 1).toUpperCase()}
                                {lastName.substring(0, 1).toUpperCase()}
                            </Text>
                            
                            </div>
                        </Col>
                        
                        <Col style={{display:'flex',alignItems:'center',paddingLeft:10}}>
                            <Text>{name} {lastName}</Text>
                        </Col>
                    </>

                )
            }
              
          </>
          ):null
        }
        
      </Row>

      <Row  className="contenedor" style={{borderRadius:10,padding:5,backgroundColor:backgroundColor,maxWidth:'90%',overflow:'hidden',height:'fit-content'}}>
        
        {
          message.urlDocument !== null && message.text !== null ? (
            <>
             <a
                style={{
                  fontSize: "bold",
                  color: textAlign === "end" ? "blue" : "white",
                  textDecoration: "underline",
                  fontFamily:'Muli',
                }}
                href={message.urlDocument}
                target="_blank"
                rel="noreferrer"
              >
                {message.nameDocument}
              </a>

              <span style={{ color: color, fontSize: 12,fontFamily:'Muli' }}>
                {" "}
                {message.text}
              </span>
            </>
          ): message.urlDocument !== null && message.text === null ? (
            <a
              style={{
                fontSize: "bold",
                textDecoration: "underline",fontFamily:'Muli'
              }}
              href={message.urlDocument}
              target="_blank"
              rel="noreferrer"
            >
              {message.nameDocument}
            </a>
          ) : (message.urlImage !== null && message.text !== null ) ? (
            <Row style={{ width:'100%'}}>
              <Row style={{width:'100%'}}>
                <Text style={{fontSize:16,fontFamily:'Muli'}}>{message.text}</Text>
              </Row>

              <Row>
                <Image width={150} height={150} src={message.urlImage} /> 
              </Row>  
            
            </Row>

          ): (message.urlImage !== null && message.text === null) ? (
            <Image width={150} height={150} src={message.urlImage} />
          ):(
            <Text style={{fontSize:16,fontFamily:'Muli'}}>{message.text}</Text>
          )
         }
      </Row>

      <Row style={{width:'100%',padding:5,display:'flex',justifyContent:justifyContent}}>
        <Text style={{fontFamily:'Muli',fontSize:12,color:'#00000065'}}>
        {new Date(message.date.seconds * 1000).toLocaleString().substr(0, 10)}{" "}{new Date(message.date.seconds * 1000).toLocaleString().substr(11, 5)}
        </Text>
      </Row>
    </Row>
  )
}

export default ChatDetail