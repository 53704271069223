import { Row, Typography } from 'antd'
import React, { useState } from 'react'
import EditFieldForm from './EditFieldForm';
import AddFieldForm from './AddFieldForm';
import './index.css'
import './FormHeaderInput.css'
interface IFormHeaderInput {
    title: string;
    isEditing: boolean;
    currentEditValue: string;
    idCurrentEditValue: number;
    setIdCurrentEditValue: (value: number) => void;
    list: Array<any>;
    setList: (value: Array<any>) => void;
    handleUpdateField: (value: string, list: Array<any>, index: number) => void;
}
const FormHeaderInput: React.FC<IFormHeaderInput> = ({ title, isEditing, currentEditValue, list, setList, handleUpdateField, idCurrentEditValue, setIdCurrentEditValue }) => {

    // States
    const [newFieldValue, setNewFieldValue] = useState<string>("");

    const addNewField = (value: string, list: Array<any>) => {
        const idNewField = list.length + 1;

        const newField = {
            index: idNewField,
            value: value
        }
        const newList = [...list, newField];
        setList(newList);
        setNewFieldValue("")
    }
    return (
        <Row className='row-form-header-input'>
            <Typography.Text style={{ fontSize: 18 }}>{title} *</Typography.Text>

            <div className='div-input-button-form'>
                {
                    isEditing ?
                        (
                            <EditFieldForm dataSource={list} editValue={currentEditValue} handleUpdateField={handleUpdateField} idEditValue={idCurrentEditValue} setEditValue={setIdCurrentEditValue} />
                        ) : (
                            list.length < 6 &&
                            <AddFieldForm addNewField={addNewField} dataSource={list} newFieldValue={newFieldValue} setNewFieldValue={setNewFieldValue} />
                        )
                }

            </div>
        </Row>
    )
}

export default FormHeaderInput
