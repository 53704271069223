//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React from "react";
import { Col, Row, Typography } from "antd";

import Chat from "../../chat/Chat/Chat";

import CandidateDetail from "./candidateDetailCard/CandidateDetail";
import { useSelector } from "react-redux";
import CandidateList from "./candidateList/CandidateList";
import { Content } from "antd/lib/layout/layout";

import StatusCandidateStep from "./StatusCandidateStep";

// Component Design
const { Text } = Typography;
// Component
const CandidateTab: React.FC = () => {
  // States

  const user = useSelector((state: any) => state.auth.user);
  const { dictionary } = useSelector((state: any) => state.ui);
  const userSelected = useSelector((state: any) => state.jobList.userSelected);

  return (
    <Row style={{ width: "100%" }}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 5 }} xl={{ span: 4 }} xxl={{ span: 3 }} style={{ width: "100%", overflow: "auto" }}>
        <CandidateList />
      </Col>

      <Col sm={{ span: 24 }} md={{ span: 17 }} lg={{ span: 19 }} xl={{ span: 20 }} xxl={{ span: 21 }} style={{ width: "100%", height: "100%" }}>
        {userSelected?.name !== "" ? (
          <Row style={{ width: "100%", height: "100%" }}>
            <Row
              style={{
                height: "10%",
                justifyContent: "center",
                display: "flex",
                minWidth: "100%",
                alignItems: "center",
                paddingLeft: "2%",
                paddingTop: 10,
                paddingBottom: 10,
                paddingRight: "2%",
              }}
            >
              <StatusCandidateStep idCandidate={userSelected.id} idHiring={user?.idHiring} />
            </Row>

            <Row
              style={{
                width: "100%",
                paddingBottom: 10,
                height: "90%",
              }}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
                style={{
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 2,
                  height: "80vh",
                  justifyContent: "center",
                  display: "flex",
                  overflowY: 'auto',
                }}
              >
                {userSelected.type === 'precandidate' ? null : (
                  <Chat
                    idCandidate={userSelected.id}
                    idHiring={user?.idHiring}
                    nameCandidate={
                      userSelected.name + " " + userSelected.lastName
                    }
                    isChatScreen={false}
                  />
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
                style={{
                  paddingBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                  paddingTop: 2,
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <CandidateDetail candidate={userSelected} />
              </Col>
            </Row>
          </Row>
        ) : (
          <Content
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 30, color: "#00000090" }}>
              {dictionary["job-candidate-unselected-message"]}
            </Text>
          </Content>
        )}
      </Col>
    </Row>
  );
};

// Export
export default CandidateTab;
