//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../firebase/config";
import { Job } from "../../types/position";
import { collection, getDocs, onSnapshot } from "firebase/firestore";

// Interface
interface Props {
  position: Job;
}

// Component
const AlertMessageBadge: React.FC<Props> = ({ position }) => {

  //States
  const user = useSelector((state: any) => state.auth.user);
  const [messagePending, setMessagePending] = useState<boolean>(false);

  //Handlers
  const getIdCandidateMessagesList = useCallback(async () => {
    if (!user?.idHiring) {
      console.error("No hiring ID provided");
      return [];
    }

    try {
      const idCandidateMessagesList: any = [];

      // Reference to the "messages" subcollection
      const messagesRef = collection(db, `hirings/${user.idHiring}/messages`);

      const messagesSnap = await getDocs(messagesRef);
      messagesSnap.forEach((messageFirebase) => {
        const messageData = messageFirebase.data();
        if (messageData && messageData.id) {
          idCandidateMessagesList.push(messageData.id);
        }
      });

      return idCandidateMessagesList;

    } catch (error) {
      console.error("Error fetching candidate messages list:", error);
      return [];
    }

  }, [user?.idHiring]);

  const getIdCandidateList = useCallback(async () => {
    try {
      const idCandidateMessagesList = await getIdCandidateMessagesList();
      let idListMessage: any = [];

      const recommendationCandidatesRef = collection(db, `hirings/${user?.idHiring}/positions/${position.idPosition}/recommendationCandidates`);
      const recommendationCandidatesSnap = await getDocs(recommendationCandidatesRef);

      recommendationCandidatesSnap.forEach((recomCandidate) => {
        const recomCandidateData = recomCandidate.data();
        console.log(recomCandidateData)
        if (recomCandidateData.status !== "waiting for reply" && recomCandidateData?.candidateLike && recomCandidateData?.like && recomCandidateData.status !== 'declined') {
          const result = idCandidateMessagesList.filter((candidate: any) => candidate === recomCandidateData.id);
          if (result.length > 0) {
            idListMessage.push(result[0]);
          }
        }
      });

      return idListMessage;

    } catch (error) {
      console.error("Error in getIdCandidateList:", error);
      return [];
    }

  }, [position.idPosition, user?.idHiring, getIdCandidateMessagesList]);

  const getMessagePending = useCallback(async () => {
    try {
      const candidateList = await getIdCandidateList(); // Ensure this function is defined and returns a list of IDs
      // Reference to the "messages" subcollection
      const messagesRef = collection(db, `hirings/${user?.idHiring}/messages`);

      const unsubscribe = onSnapshot(messagesRef, (messageSnap) => {
        let messagePendingList = [];

        messageSnap.forEach((message) => {
          const messageData = message.data();
          if (candidateList && candidateList.includes(messageData.id) && messageData.messagesPending) {
            messagePendingList.push(messageData.id);
          }
        });

        setMessagePending(messagePendingList.length > 0);
      });

      // Return unsubscribe function for cleanup
      return unsubscribe;
    } catch (error) {
      console.error("Error in getMessagePending:", error);
      // Handle any errors that occurred during the process
    }
  }, [user?.idHiring, getIdCandidateList]);

  useEffect(() => {
    getMessagePending();
  }, [getMessagePending]);

  return (
    <>
      {
        messagePending && (
          <div style={{ height: 15, width: 15, backgroundColor: '#D12525', borderRadius: 10 }} />
        )
      }
    </>
  );
};
// Export
export default AlertMessageBadge;
