import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, Col, Slider, Table, Typography, Row } from 'antd';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { collectionGroup, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { CSS } from '@dnd-kit/utilities';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import IconRatingSkill from '../iconRating';
import MensajeIngles from '../../lang/diccionary-en.json';
import MensajeFrance from '../../lang/diccionary-fr.json';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../hooks/hooks';
import './index.css'

const { Text } = Typography
// INTERFACES
interface DataType {
  rating: number;
  nameSkill: string;
  index: number,
  predeterminated: string,
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowTable = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined

                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface Props {
  dataSource: Array<any>;
  setDataSource: (value: any) => void;
  title: string
}
const TableDrawSkill: React.FC<Props> = ({ dataSource, setDataSource, title }) => {
  const [editingValue, setEditingValue] = useState<boolean>(false);
  const [idEditValue, setIdEditValue] = useState<number>(0);
  const [editValue, setEditValue] = useState<string>("");
  const [newFieldValue, setNewFieldValue] = useState<string>("");
  const [skillRating, setSkillRating] = useState<string>("1");
  const marks = { 1: '1', 2: '2', 3: '3', 4: '4', 5: '5' };
  const [sliderValue, setSliderValue] = useState<number>(1);
  const [sliderValueEdit, setSliderValueEdit] = useState<number>(1)
  const [skillRatingEdit, setSkillRatingEdit] = useState<string>("1");
  const [skillOptionList, setSkillOptionList] = useState<Array<{ value: string, label: string, predeterminated: string }>>([]);
  const [messageTranslate, setMessageTranslate] = useState<any>(MensajeIngles);
  const { language, dictionary } = useSelector((state: any) => state.ui);
  const columns: ColumnsType<DataType> = [
    {
      key: 'sort',
      width: '1%'
    },
    {
      title: 'value',
      dataIndex: 'nameSkill',
      className: 'column-name-skill',
      render: (_, render) => (
        <Text style={{ color: render.index === idEditValue ? '#00000050' : 'black', justifyItems: 'flex-start' }}>{render.nameSkill}</Text>
      )
    },
    {
      title: 'value',
      dataIndex: 'rating',
      className: 'column-rating-skill',
      render: (_, render) => (
        <IconRatingSkill rating={render.rating.toString()} />
      )
    }, {
      dataIndex: 'action',
      className: 'column-buttons-skills',
      render: (_, render) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button disabled={render.index === idEditValue ? editingValue : false} onClick={() => {
            setIdEditValue(render.index);
            setEditingValue(true);
            setEditValue(render.nameSkill);
            setSliderValueEdit(render.rating)
          }} style={{ backgroundColor: render.index === idEditValue ? '#00000050' : 'gray', borderRadius: 40 }} icon={<EditOutlined style={{ color: 'white' }} />} />
          <Button disabled={render.index === idEditValue ? editingValue : false} onClick={() => deleteField(dataSource, render.index)} style={{ backgroundColor: render.index === idEditValue ? '#00000050' : 'red', borderRadius: 40 }} icon={<DeleteOutlined style={{ color: 'white' }} />} />
        </div>
      )
    }
  ];

  // States

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.index === active.id);
        const overIndex = previous.findIndex((i: any) => i.index === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    const newList = [];
    dataSource?.forEach((data, index) => {
      newList.push({
        index: index,
        value: data.value
      })
    })
  }, [dataSource]);

  const addNewField = (value: string, list: Array<any>) => {
    const idNewField = list.length + 1;

    const newField = {
      index: idNewField,
      nameSkill: value,
      rating: skillRating,
      predeterminated: ""
    }
    const newList = [...list, newField];
    setDataSource(newList);
    setNewFieldValue("");
  }

  const deleteField = (list: Array<any>, id: number) => {
    const deleteValue = list.filter((list) => list.index !== id);
    setDataSource(deleteValue)
  }

  const editField = (value: string, list: Array<any>, index: number) => {

    const deleteValue = list.filter((list) => list.index !== index);

    const idField = index;
    const newField = {
      index: idField,
      nameSkill: value,
      rating: skillRatingEdit,
      predeterminated: ""
    };
    const newList = [...deleteValue, newField];

    const listSorted = newList.sort((a, b) => a.index - b.index);
    setEditingValue(false)
    setIdEditValue(0)
    setEditValue("");
    setSliderValueEdit(1);
    setSkillRatingEdit("");
    setDataSource(listSorted)
  }

  useEffect(() => {
    const getSkillOptions = async () => {
      try {
        const skillTitlesQuery = query(collectionGroup(db, "skillTitles"));
        const response = await getDocs(skillTitlesQuery);
        const skillList: any = [];

        response.docs.forEach((doc) => {
          const data = doc.data();
          if (data.nameSkill.length > 0 && data.verified) {
            skillList.push(data.nameSkill);
          }
        });

        const uniqueSkills = skillList.filter((item: any, index: any) => skillList.indexOf(item) === index);

        const skillListSelector = uniqueSkills.map((skillName: any) => {
          const translation = messageTranslate[skillName];
          return {
            value: translation !== undefined ? getTranslation(skillName, dictionary) : skillName,
            label: translation !== undefined ? getTranslation(skillName, dictionary) : skillName,
            predeterminated: skillName
          };
        });

        setSkillOptionList(language === 'English' ? skillListSelector.sort((a: any, b: any) => a.value.localeCompare(b.value)) : skillListSelector);
      } catch (error) {
        console.error("Error al obtener las habilidades:", error);
      }
    };

    getSkillOptions();
  }, [dictionary, messageTranslate, language]);

  useEffect(() => {
    if (language === 'English') {
      setMessageTranslate(MensajeIngles);
    } else {
      //@ts-ignore
      setMessageTranslate(MensajeFrance)
    }
  }, [language]);
  const selectRef = useRef<any>(null);
  return (
    <>
      <Row className='container-table-draw'>
        <Col className='container-title' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }} xl={{ span: 2 }} xxl={{ span: 2 }} >
          <Text style={{ fontSize: 18 }}>{title} *</Text>
        </Col>

        {

          editingValue ? (
            <>
              <Col className='container-input' xs={{ span: 23 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 8 }} xl={{ span: 9 }} xxl={{ span: 9 }}>
                <div style={{ width: '100%', padding: '10px 10px', backgroundColor: 'white', borderRadius: '20px', border: '1px solid #00000026', boxShadow: "4px 1px 6px #00000020", }}>
                  <AutoComplete
                    ref={selectRef}
                    className='autocomplete-skill'
                    placeholder={messageTranslate["creation-modal-page-three-placeholder-skill"]}
                    options={skillOptionList}
                    value={editValue}
                    bordered={false}
                    filterOption={true}
                    onChange={(value, options) => setEditValue(value)}
                    onSelect={(value: string) => setEditValue(value)}
                  />
                </div>
              </Col>

              <Col className='slider-container' xs={{ span: 23 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 8 }} xl={{ span: 9 }} xxl={{ span: 9 }} >
                <Slider style={{ width: "100%" }}
                  defaultValue={1}
                  marks={marks}
                  value={sliderValueEdit}
                  min={1}
                  max={5}
                  onChange={(value: any) => {
                    setSkillRatingEdit(value);
                    setSliderValueEdit(value);
                  }} />
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }} className='container-buttons'>
                <Button onClick={() => {
                  if (editValue.length > 0) {
                    editField(editValue, dataSource, idEditValue);
                  }
                }} style={{
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  width: 110,
                  backgroundColor: "#22c0c1",
                  color: "white",
                  boxShadow: "inset 7px 7px 15px #00000030"
                }}>{messageTranslate["creation-modal-update-button"]}</Button>
              </Col>
            </>
          ) : (
            dataSource.length < 5 && (
              <>
                <Col className='container-input' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 8 }} xl={{ span: 9 }} xxl={{ span: 9 }}>

                  <div style={{ width: '100%', padding: '0px 5px', height: '38px', backgroundColor: 'white', borderRadius: '20px', border: '1px solid #00000026', boxShadow: "4px 1px 6px #00000020", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <AutoComplete
                      ref={selectRef}
                      className='autocomplete-skill'
                      placeholder={messageTranslate["creation-modal-page-three-placeholder-skill"]}
                      options={skillOptionList}
                      value={newFieldValue}
                      bordered={false}
                      filterOption={true}
                      onChange={(value, options) => setNewFieldValue(value)}
                      onSelect={(value: string) => setNewFieldValue(value)}
                    />
                  </div>
                </Col>

                <Col className='slider-container' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 19 }} lg={{ span: 8 }} xl={{ span: 9 }} xxl={{ span: 9 }} >
                  <Slider style={{ width: "100%" }} defaultValue={1} marks={marks} value={sliderValue} min={1} max={5} onChange={(value: any) => { setSkillRating(value); setSliderValue(value); }} />
                </Col>

                <Col xs={{ span: 23 }} lg={{ span: 4 }} xl={{ span: 3 }} xxl={{ span: 3 }} className='container-buttons'>
                  <Button onClick={() => {
                    if (newFieldValue.length > 0) {
                      addNewField(newFieldValue, dataSource);
                    }
                  }} style={{
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    width: 110,
                    backgroundColor: "#22c0c1",
                    color: "white",
                    boxShadow: "inset 7px 7px 15px #00000030"
                  }}>Add</Button>
                </Col>
              </>
            )
          )
        }
      </Row>
      <div style={{ border: '1px solid #00000040', padding: 5, borderRadius: 16, width: '100%' }}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.index)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              pagination={false}
              components={{
                body: {
                  row: RowTable,
                },
              }}
              showHeader={false}
              rowKey="index"
              columns={columns}
              scroll={{ x: 'max-content' }}
              dataSource={dataSource}

            />
          </SortableContext>
        </DndContext>
      </div>
    </>
  )
}

export default TableDrawSkill