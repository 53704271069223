//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Button, Col, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import StatusCandidateCard from "./StatusCandidateCard";
import { QuestionCircleOutlined } from "@ant-design/icons";
import HelpInformationModal from "./HelpInformationModal";
import { useSelector } from "react-redux";
import TitlePositionCol from "./TitlePositionCol";

//Interface
interface Props {
  loadingStatusCandidate: boolean;
  dataDashboard: Array<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
    daysOld: number;
    declined: number;
    hired: number;
    interested: number;
    interview: number;
    meeting: number;
    waitingForReply: number;
    offer: number;
  }>;
}

const { Text } = Typography;

//Component
const StatusPositionCard: React.FC<Props> = ({
  loadingStatusCandidate,
  dataDashboard,
}) => {
  //States

  const { dictionary } = useSelector((state: any) => state.ui);
  const [positionSelected, setPositionSelected] = useState<string>("");
  const [infoPositionSelected, setInfoPositionSelected] = useState<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
    daysOld: number;
    declined: number;
    hired: number;
    interested: number;
    interview: number;
    meeting: number;
    waitingForReply: number;
    offer: number;
  }>();
  const [visibleHelpInformationModal, setvisibleHelpInformationModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (dataDashboard.length > 0) {
      setPositionSelected(dataDashboard[0].idPosition);
      setInfoPositionSelected(dataDashboard[0]);
    }
  }, [dataDashboard]);

  return (
    <Col
      className="contenedor"
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 24 }}
      lg={{ span: 24 }}
      xl={{ span: 24 }}
      xxl={{ span: 24 }}
      style={{
        height: "100%",
        paddingLeft: "0.5%",
        paddingRight: "0.5%",
        borderRadius: 16,
        border: "1px solid",
        borderColor: "#00000020", backgroundColor: 'white'
      }}
    >
      <Row
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 13,
          marginBottom: 13,
        }}
      >
        <Col
          xs={{ span: 11, offset: 1 }}
          sm={11}
          md={11}
          lg={11}
          xl={11}
          xxl={11}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {dictionary["dashboard-title-job"]}
          </Text>
          <Button
            onClick={() => setvisibleHelpInformationModal(true)}
            shape="circle"
            style={{ border: "none" }}
            icon={<QuestionCircleOutlined />}
          />
        </Col>

        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {dictionary["dashboard-title-status-candidates"]}
          </Text>
        </Col>

        <HelpInformationModal
          visible={visibleHelpInformationModal}
          handleOnCloseModal={() => setvisibleHelpInformationModal(false)}
        />
      </Row>

      {loadingStatusCandidate ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Skeleton />
        </div>
      ) : (
        <Row style={{ height: "85%", width: "100%", marginBottom: 10 }}>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            style={{ paddingTop: "2%" }}
          >
            {dataDashboard.length > 0 &&
              dataDashboard.map((each, index) => (
                <TitlePositionCol
                  key={each.idPosition}
                  each={each}
                  index={index}
                  positionSelected={positionSelected}
                  handleOnClick={() => {
                    setInfoPositionSelected(undefined);
                    setPositionSelected("");
                    setTimeout(() => {
                      positionSelected === each.idPosition
                        ? setPositionSelected("")
                        : setPositionSelected(each.idPosition);
                      setInfoPositionSelected(each);
                    }, 120);
                  }}
                />
              ))}
          </Col>

          {positionSelected !== "" && (
            <StatusCandidateCard dataPosition={infoPositionSelected} />
          )}
        </Row>
      )}
    </Col>
  );
};

export default StatusPositionCard;
