//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
import React from "react";

import JobPosition from "../components/jobsList/JobPosition";
import { Content } from "antd/lib/layout/layout";


const JobsListScreen: React.FC = () => {
  return (
    <Content style={{ height: '100%', width: '100%', backgroundColor: '#00000004' }}>
      <JobPosition />
    </Content>
  );
};

export default JobsListScreen;
