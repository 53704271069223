//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Row } from "antd";

//Interfaces
interface Props {
  iconImage: string;
  height: string | number;
  width: string | number;
}

//Component
const ImageButton: React.FC<Props> = ({ iconImage, height, width }) => {
  return (
    <Row
      style={{
        padding: 6,
        cursor: "pointer",
        alignContent: "center",
        justifyContent: "center",
        display: "flex",
        height: height,
        width: width,
      }}
    >
      <img
        src={iconImage}
        style={{ height: "100%", width: "100%", margin: 0 }}
        alt={iconImage}
      />
    </Row>
  );
};

//Export
export default ImageButton;
