//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useCallback, useEffect, useRef, useState } from "react";
import { Message, MessagesModified } from "../../../../types/message";
import ChatMessage from "./ChatMessage";
import { useSelector } from "react-redux";

// Interfaces
interface Props {
  chat: Array<Message>;
  isChatScreen: boolean;
  showHeaderLarge: boolean;
}

// Component
const { Text } = Typography;

const ChatMessageList: React.FC<Props> = ({
  chat,
  isChatScreen,
  showHeaderLarge,
}) => {
  // States
  const messageEndRef = useRef<HTMLDivElement>(null);
  const [chatModified, setChatModified] = useState<Array<MessagesModified>>([]);
  const { dictionary } = useSelector((state: any) => state.ui);

  const getChatModified = useCallback(() => {
    const chatModified: Array<MessagesModified> = [];

    chat.forEach((message: Message, index: number) => {
      if (index > 0) {
        if (chat[index - 1].chatUser === "to") {
          chatModified.push({
            ...message,
            showName: false,
          });
        } else {
          chatModified.push({
            ...message,
            showName: true,
          });
        }
      } else {
        chatModified.push({
          ...message,
          showName: true,
        });
      }
    });
    setChatModified(chatModified);
  }, [chat]);

  useEffect(() => {
    getChatModified();
    scrollBottom();
  }, [getChatModified]);

  const scrollBottom = () => {
    const node: HTMLDivElement | null = messageEndRef.current;

    if (node) {
      const scroll = node.scrollHeight - node.clientHeight;
      node.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    scrollBottom();
  });

  return (
    <Content
      className="contenedor"
      ref={messageEndRef}
      style={{
        overflowY: "scroll",
        border: "1px solid",
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        boxShadow: "2px 2px 5px #00000020",
        borderColor: "#e0e0e0",
        width: "100%",
        // height: isChatScreen ?'77%' :'90%',
        height: isChatScreen ? "100%" : "100%",
        backgroundColor: "white",
        position: "relative",
        padding: 10,
      }}
    >
      {chatModified.length > 0 ? (
        chatModified.map((message: MessagesModified, index) => (
          <>
            {message.chatUser === "from" ? (
              <ChatMessage
                key={message.chatUser + index}
                message={message}
                backgroundColor="#F5F1FB"
                color="black"
                justifyContent="flex-end"
                textAlign="end"
                isChatScreen={isChatScreen}
              />
            ) : (
              <ChatMessage
                key={message.chatUser + index}
                message={message}
                backgroundColor="#ECFBFD"
                color="black"
                justifyContent="flex-start"
                textAlign="start"
                isChatScreen={isChatScreen}
              />
            )}
          </>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Text
            style={{
              fontSize: 30,
              color: "#00000040",
              fontFamily: "Muli",
              textAlign: "center",
            }}
          >
            {dictionary["message-list-no-messages-text"]}
          </Text>
        </div>
      )}
    </Content>
  );
};

// Export
export default ChatMessageList;
