import { Col, Row, Typography, Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import './PreviewInformationSection.css';

const { Text } = Typography;
interface Props {
  avatarCompany: string;
  setAvatarCompany: (value: string) => void;
  companyName: string,
  setCompanyNameForm: (value: string) => void;
  industryForm: string;
  setIndustryForm: (value: string) => void;
  websiteCompany: string;
  setWebsiteCompany: (value: string) => void;
  companyInformationForm: string;
  setCompanyInformationForm: (value: string) => void;
  avatarPersonal: string;
  setAvatarPersonal: (value: any) => void;
  nameForm: string;
  setNameForm: (value: string) => void;
  setLastNameForm: (value: string) => void;
  lastNameForm: string;
  phoneNumberForm: string;
  setPhoneNumberForm: (value: string) => void;
  emailForm: string;
  titleForm: string;
}
const PreviewInformationSection: React.FC<Props> = ({
  avatarCompany,
  avatarPersonal,
  companyInformationForm,
  companyName,
  emailForm,
  industryForm,
  lastNameForm,
  nameForm,
  phoneNumberForm,
  setAvatarCompany,
  setAvatarPersonal,
  setCompanyInformationForm,
  setCompanyNameForm,
  setIndustryForm,
  setLastNameForm,
  setNameForm,
  setPhoneNumberForm,
  setWebsiteCompany,
  websiteCompany,
  titleForm
}) => {

  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <div className='div-container-last-section-preview-information'>
      <Row className='row-body-informations'>
        <Col className='col-content-information' xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} xxl={{ span: 10, offset: 1 }}>
              <Card bordered={false} style={{ padding: '20px', marginBottom: '20px' }}>
                <Text className='title-section'>
                  {dictionary['first-login-modal-title-general2']}
                </Text>

                <div style={{ paddingBottom: '10px' }}>
                  <Text className='text-information-preview'>
                    <strong>Name:</strong> {nameForm} {lastNameForm}
                  </Text>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                  <Text className='text-information-preview'>
                    <strong>Title:</strong> {titleForm}
                  </Text>
                </div>
              </Card>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} xxl={{ span: 10, offset: 1 }}>
              <Card bordered={false} style={{ padding: '20px', marginBottom: '20px' }}>
                <Text className='title-section'>
                  {dictionary['first-login-modal-title-general3']}
                </Text>

                <div style={{ paddingBottom: '10px' }}>
                  <div style={{ height: '120px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <img
                      src={avatarCompany}
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      alt='img-company-user'
                    />
                  </div>

                </div>

                <div style={{ paddingBottom: '10px' }}>
                  <Text className='text-information-preview'>
                    <strong>Company:</strong> {companyName}
                  </Text>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                  <Text className='text-information-preview'>
                    <strong>Website:</strong> {websiteCompany}
                  </Text>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                  <Text className='text-information-preview'>
                    {companyInformationForm}
                  </Text>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <Text className='text-information-preview'>
            {dictionary['first-login-modal-view3-text-informative']}
          </Text>
          <Text style={{ color: 'blue', marginLeft: '3px' }}>
            admin@gocareerswipe.com.
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewInformationSection;
