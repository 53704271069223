import { Steps, Tooltip, message } from "antd";
import {
  Timestamp,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { useDispatch, useSelector } from "react-redux";
import iconInterview from "../../../image/meeting.png";
import iconIntested from "../../../image/interested.png";
import iconMeet from "../../../image/interview.png";
import iconOffer from "../../../image/offer.png";
import iconHired from "../../../image/hired.png";
import iconInterestedWithTicket from "../../../image/interestedWithTicket.png";
import iconMeetWithTicket from "../../../image/interviewWithTicket.png";
import iconOfferWithTicket from "../../../image/offerWithTicket.png";
import iconHiredWithTicket from "../../../image/hiredWithTicket.png";
import iconInterviewWithTicket from "../../../image/meetWithTicket.png";
import iconDeclined from "../../../image/declined.png";
import { uid } from "uid";
import { getTranslation } from "../../../hooks/hooks";
import DeclinedCandidateConfirmModal from "./DeclinedCandidateConfirmModal";
import { clearUserSelected } from "../../../store/job";
import { getCandidate } from "../../../firebase/endpoints/candidateEndPoint";
import { updateRecommendationCandidateDoc, updateRecommendationPreCandidateDoc } from "../../../firebase/controllers/hiring.services.firebase";
const { Step } = Steps;
// Interface
interface Props {
  idHiring: string;
  idCandidate: string;
}
const StatusCandidateStep: React.FC<Props> = ({ idCandidate, idHiring, }) => {
  // States
  const { dictionary, position } = useSelector((state: any) => state.ui);
  const { type, id } = useSelector((state: any) => state.jobList.userSelected);
  const [current, setCurrent] = useState<number>(5);
  const [widthWindow, setWidthWindow] = useState<number>(1300);
  const dispatch: Dispatch<any> = useDispatch();
  const [visibleDeclinedConfirmModal, setVisibleDeclinedConfirmModal] = useState(false);

  // Handlers
  const updateStatusPreCandidate = useCallback(async (status: "interview" | "waiting for reply" | "meeting" | "hired" | "interested" | "declined" | "offer", id: string) => {
    try {
      if (!idHiring || !id) {
        return
      }
      await updateRecommendationPreCandidateDoc(idHiring, position.idPosition, id, { candidateStatus: status });
    } catch (error) {
      console.error("Error updating status or creating notification:", error);
    }
  }, [idHiring, position.idPosition],)

  const updateStatus = useCallback(async (status: "interview" | "waiting for reply" | "meeting" | "hired" | "interested" | "declined" | "offer") => {
    try {
      const candidateRes = await getCandidate(idCandidate)
      if (!candidateRes) {
        message.error('Candidate res not found ')
        return
      }
      await updateRecommendationCandidateDoc(idHiring, position.idPosition, idCandidate, { status })

      if (status === "declined") {

        let idNotification = uid(30);
        let idCard = uid(24);

        const notificationCardDocRef = doc(db, "notificationCards", idCandidate);
        await setDoc(notificationCardDocRef, { idCandidate: idCandidate, });

        const cardDocRef = doc(db, `notificationCards/${idCandidate}/cards`, idCard);
        await setDoc(cardDocRef, {
          title: position && position.language && position.language === "English" ?
            `${getTranslation("joblist-decline-candidate-text-title", dictionary)} ${position.title}` :
            `${getTranslation("joblist-decline-candidate-text-title", dictionary)} ${position.title}`,
          description:
            position && position.language === "English" ?
              `${getTranslation("joblist-decline-candidate-text-description-1", dictionary)} ${candidateRes.name} ${candidateRes.lastName}, ${getTranslation("joblist-decline-candidate-text-description-2", dictionary)} ${position.title} ${getTranslation("joblist-decline-candidate-text-description-3", dictionary)}`
              : `${getTranslation("joblist-decline-candidate-text-description-1", dictionary)} ${candidateRes.name} ${candidateRes.lastName}, ${getTranslation("joblist-decline-candidate-text-description-2", dictionary
              )} ${position.title} ${getTranslation("joblist-decline-candidate-text-description-3", dictionary)}`,
          idNotification,
          idPosition: position.idPosition,
          idCard,
          statusView: false,
          dateCreatedNotification: Timestamp.now(),
          dateViewNotification: null,
          cardType: "declinedCandidate",
        });

        // Agregar funcion para cambiar estado del puesto en el candidate.
        const candidateDocRef = doc(db, "candidates", idCandidate, "recommendation", position.idPosition,);
        await updateDoc(candidateDocRef, { statusMatch: "declined" });
      }
    } catch (error) {
      console.error("Error updating status or creating notification:", error);
    }
  },
    [idHiring, position, dictionary, idCandidate]
  );

  const onChange = useCallback(
    (value) => {
      if (value === 0) {
        setCurrent(value);
        updateStatus("interested");
      } else if (value === 2) {
        setCurrent(value);
        updateStatus("meeting");
        setCurrent(value);
      } else if (value === 1) {
        updateStatus("interview");
        setCurrent(value);
      } else if (value === 3) {
        updateStatus("offer");
        setCurrent(value);
      } else if (value === 4) {
        updateStatus("hired");
        setCurrent(value);
      } else if (value === 5) {
        /* */
        setVisibleDeclinedConfirmModal(true);
        /* dispatch(clearUserJobSelected()) */
      }
    },
    [updateStatus]
  );

  const onChangePreCandidate = useCallback(
    (value) => {
      if (value === 0) {
        setCurrent(value);
        updateStatusPreCandidate("interested", id);
      } else if (value === 2) {
        setCurrent(value);
        updateStatusPreCandidate("meeting", id);
        setCurrent(value);
      } else if (value === 1) {
        updateStatusPreCandidate("interview", id);
        setCurrent(value);
      } else if (value === 3) {
        updateStatusPreCandidate("offer", id);
        setCurrent(value);
      } else if (value === 4) {
        updateStatusPreCandidate("hired", id);
        setCurrent(value);
      } else if (value === 5) {
        /* */
        setVisibleDeclinedConfirmModal(true);
        /* dispatch(clearUserJobSelected()) */
      }
    },
    [updateStatusPreCandidate, id]
  );

  const fetchCandidateStatus = useCallback(async (db, idHiring, positionId, idCandidate, type, setCurrent) => {
    // Ensure all necessary data is available
    if (!idHiring || !positionId || !idCandidate) {
      return;
    }

    // Reference to the specific document in the "recommendationCandidates" or "recommendationPreCandidates" subcollection
    const candidateDocRef = doc(db, `hirings/${idHiring}/positions/${positionId}/${type === 'precandidate' ? 'recommendationPreCandidates' : 'recommendationCandidates'}`, idCandidate);

    // Get the document data
    const querySnapshot = await getDoc(candidateDocRef);
    const status = type === 'precandidate' ? querySnapshot.data()?.candidateStatus : querySnapshot.data()?.status;

    // Update the current step based on the status
    switch (status) {
      case "interested":
        setCurrent(0);
        break;
      case "interview":
        setCurrent(1);
        break;
      case "meeting":
        setCurrent(2);
        break;
      case "offer":
        setCurrent(3);
        break;
      case "hired":
        setCurrent(4);
        break;
      case "declined":
        setCurrent(5);
        break;
      default:
      // Handle default case or do nothing
    }
  }, []);

  useEffect(() => {
    fetchCandidateStatus(db, idHiring, position.idPosition, idCandidate, type, setCurrent);
  }, [idCandidate, idHiring, position.idPosition, type, fetchCandidateStatus]);

  const getWindowSize = () => {
    const { innerHeight, innerWidth } = window;
    return { innerWidth, innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setWidthWindow(windowSize.innerWidth);
  }, [windowSize]);

  return (
    <>
      <DeclinedCandidateConfirmModal
        handleOnFunction={() => {
          updateStatus("declined");
          dispatch(clearUserSelected());
        }}
        setVisible={setVisibleDeclinedConfirmModal}
        visible={visibleDeclinedConfirmModal}
      />

      <Steps
        direction="horizontal"
        size="default"
        current={current}
        className="site-navigation-steps"
        onChange={type === 'precandidate' ? onChangePreCandidate : onChange}
        style={{ minWidth: "100%" }}
      >
        <Step
          title={
            widthWindow < 1200 && widthWindow > 576
              ? ""
              : dictionary["job-status-title-interested"]
          }
          disabled={true}
          icon={
            current === 0 ? (
              <Tooltip title={dictionary["job-status-title-interested"]}>
                {" "}
                <img
                  src={iconInterestedWithTicket}
                  alt="interested"
                  style={{ height: "30%" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={dictionary["job-status-title-interested"]}>
                {" "}
                <img src={iconIntested} alt="interested" />
              </Tooltip>
            )
          }
        />

        <Step
          title={
            widthWindow < 1200 && widthWindow > 576
              ? ""
              : dictionary["job-status-title-interview"]
          }
          icon={
            current === 1 ? (
              <Tooltip title={dictionary["job-status-title-interview"]}>
                {" "}
                <img
                  src={iconInterviewWithTicket}
                  alt="interview"
                  style={{ height: "30%" }}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={dictionary["job-status-title-interview"]}>
                <img src={iconInterview} alt="interview" />
              </Tooltip>
            )
          }
        />
        <Step
          title={
            widthWindow < 1200 && widthWindow > 576
              ? ""
              : dictionary["job-status-title-meeting"]
          }
          icon={
            current === 2 ? (
              <Tooltip title={dictionary["job-status-title-meeting"] + "WEQ"}>
                {" "}
                <img
                  src={iconMeetWithTicket}
                  alt="meeting"
                  style={{ height: "30%" }}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={dictionary["job-status-title-meeting"]}>
                <img src={iconMeet} alt="meeting" />
              </Tooltip>
            )
          }
        />

        <Step
          title={
            widthWindow < 1200 && widthWindow > 576
              ? ""
              : dictionary["job-status-title-offer"]
          }
          icon={
            current === 3 ? (
              <Tooltip title={dictionary["job-status-title-offer"]}>
                {" "}
                <img
                  src={iconOfferWithTicket}
                  alt="offer"
                  style={{ height: "30%" }}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={dictionary["job-status-title-offer"]}>
                <img src={iconOffer} alt="offer" />
              </Tooltip>
            )
          }
        />
        <Step
          title={
            widthWindow < 1200 && widthWindow > 576
              ? ""
              : dictionary["job-status-title-hired"]
          }
          icon={
            current === 4 ? (
              <Tooltip title={dictionary["job-status-title-hired"]}>
                <img
                  src={iconHiredWithTicket}
                  alt="hired"
                  style={{ height: "30%" }}
                />{" "}
              </Tooltip>
            ) : (
              <Tooltip title={dictionary["job-status-title-hired"]}>
                <img src={iconHired} alt="hired" />
              </Tooltip>
            )
          }
        />
        <Step
          title={widthWindow < 1200 && widthWindow > 576 ? "" : dictionary["job-status-title-declined"]}
          icon={
            <Tooltip title={dictionary["job-status-title-declined"]}>
              <img src={iconDeclined} alt="declined" />{" "}
            </Tooltip>
          }
        />
      </Steps>
    </>
  );
};

export default StatusCandidateStep;
