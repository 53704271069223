import { Col, Typography } from "antd";
import * as d3 from "d3";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../hooks/hooks";

// Interface
interface Props {
  data: Array<any>;
}

// Component
const GraphicPieChart: React.FC<Props> = ({ data }) => {
  // States
  const piechartRef = useRef<SVGSVGElement>(null);
  const { dictionary } = useSelector((state: any) => state.ui);
  const drawPieChart = useCallback((data: Array<any>) => {
    const pieData = d3.pie().value((d: any) => d.count)(data);
    const arc: any = d3.arc().innerRadius(0).outerRadius(105);
    const colors: any = d3.scaleOrdinal([
      "#77AB59",
      "#D12525",
      "yellow",
      "#C182E6",
    ]);

    const svg = d3
      .select(piechartRef.current)
      .attr("width", 200)
      .attr("height", 200)
      .style("background", "white")
      .style("overflow", "visible")
      .append("g")
      .attr("transform", `translate(100, 100)`);

    // Draw Pie
    svg
      .selectAll("path")
      .data(pieData)
      .join("path")
      .attr("d", arc)
      .attr("fill", (d: any, i: number) => colors(i))
      .attr("stroke", "white")
      .attr("opacity", 0.9);

    svg
      .selectAll("text")
      .data(pieData)
      .join("text")
      .text((d: any) => (d.data.count > 0 ? d.data.count : null))
      .attr("transform", (d: any) => `translate(${arc.centroid(d)})`)
      .style("text-anchor", "middle");
  }, []);

  useEffect(() => {
    drawPieChart(data);
  }, [data, drawPieChart]);

  const { Text } = Typography;
  return (
    <Col
      style={{
        height: 200,
        width: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data.every((item) => item.count === 0) ? (
        <div
          style={{
            height: 200,
            width: 200,
            backgroundColor: "#8D8D8D",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Text
            style={{ fontSize: 17, fontFamily: "Muli", textAlign: "center" }}
          >
            {getTranslation("dashboard-graphic-title-no-data", dictionary)}
          </Text>
        </div>
      ) : (
        <svg ref={piechartRef} />
      )}
    </Col>
  );
};

export default GraphicPieChart;
