// Dependencies
import { Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import alertIcon from "../../../image/iconNotificationSuccess.png";
import alertIconYellow from "../../../image/iconNotificationSuccessYellow.png";

// Interface
interface Props {
  visible: boolean;
  closeModal: () => void;
  type: "like" | "dislike";
}
// Component
const { Text } = Typography;

const NotificationInteractionSourcing: React.FC<Props> = ({
  visible,
  closeModal,
  type,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      open={visible}
      centered
      onCancel={closeModal}
      closable={false}
      width="35%"
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        ></Row>
      }
    >
      <Content style={{ width: "100%", height: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Col xxl={{ span: 18, offset: 3 }} style={{ height: "100%" }}>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="Icon exclamation"
                style={{ height: 50, width: 50 }}
                src={type === "like" ? alertIcon : alertIconYellow}
              />
            </Row>
            {type === "like" ? (
              <>
                <Row
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    marginTop: 20,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 16, fontFamily: "Muli" }}>
                    {dictionary["sourcing-modal-action-like-parrafo1"]}
                  </Text>
                </Row>
                <Row
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    marginTop: 20,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 16, fontFamily: "Muli" }}>
                    {dictionary["sourcing-modal-action-like-parrafo2"]}
                  </Text>
                </Row>

                <Row
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    marginTop: 20,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 16, fontFamily: "Muli" }}>
                    {dictionary["sourcing-modal-action-like-parrafo3"]}
                  </Text>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    marginTop: 20,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 16 }}>
                    {dictionary["sourcing-modal-action-dislike-parrafo1"]}
                  </Text>
                </Row>
                <Row
                  style={{
                    width: "100%",
                    paddingLeft: 10,
                    marginTop: 20,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 16 }}>
                    {dictionary["sourcing-modal-action-dislike-parrafo2"]}
                  </Text>
                </Row>
              </>
            )}
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            ></Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};

// Export
export default NotificationInteractionSourcing;
