//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React, { useCallback, useState } from "react";
import { Button, Col, Input, Row, Typography, notification, Form } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import iconIRGT from "../../image/inrgt-logo@3x.png";
import { logout } from "../../store/auth";
import { logoutFirebase } from "../../firebase/providers";
import { auth, db } from "../../firebase/config";
import { updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { StateGeneralRedux } from "../../types/redux";
import CounterDown from "../../components/home/CounterDown";
import './index.css'
const { Text } = Typography;
interface Props {
    setChangePassword: any;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
};

// Component
const ChangePasswordPage: React.FC<Props> = ({ setChangePassword }) => {
    // States
    const { user } = useSelector((state: StateGeneralRedux) => state.auth);
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    const dispatch = useDispatch();
    const [passwordForm, setPasswordForm] = useState<string>("");
    const [passwordConfirmForm, setPasswordConfirmfForm] = useState<string>("");
    const [seconds] = useState(
        Number(localStorage.getItem("seconds")) !== 0
            ? localStorage.getItem("seconds")
            : 120
    );

    const [form] = Form.useForm();

    const LogOut = useCallback(async () => {
        setChangePassword(false);
        logoutFirebase();
        dispatch(logout());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setChangePassword]);

    const onFinish = async () => {
        try {
            const currentUser = auth.currentUser;
            if (currentUser) {
                await updatePassword(currentUser, passwordForm);
            } else {
                throw new Error("No authenticated user found");
            }

            if (user?.idHiring) {
                const hiringDocRef = doc(db, "hirings", user.idHiring);
                await updateDoc(hiringDocRef, { forceChangePassword: false });
            } else {
                throw new Error("No hiring ID found");
            }

            LogOut();
            notificationModal(
                "success",
                dictionary["alertMessage-text-title2"],
                dictionary["alertMessage-changePassword-text-title3"]
            );
        } catch (error) {
            alert(`Error in function onFinish: ${error}`);
            // Handle the error, maybe show a notification to the user
        }
    };

    type NotificationType = "success" | "info" | "warning" | "error";

    const notificationModal = (
        type: NotificationType,
        message: string,
        description: string
    ) => {
        notification[type]({
            message: message,
            description: description,
        });
    };

    return (
        <Content
            style={{
                height: "100vh",
                width: "100vw",
                justifyItems: "center",
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Row style={{ height: "93%", alignItems: "center", marginTop: "2%" }}>
                <Row style={{ height: "35%", width: "100%" }}>
                    <Col
                        xs={24}
                        sm={5}
                        md={5}
                        lg={3}
                        xxl={3}
                        style={{
                            height: "100%",
                            padding: 20,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <img
                            src={iconIRGT}
                            style={{ height: "100%", width: "100%", objectFit: "contain" }}
                            alt={iconIRGT}
                        />
                    </Col>

                    <Col
                        xs={24}
                        sm={19}
                        md={19}
                        lg={20}
                        xxl={21}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <Row
                            style={{
                                width: "100%",
                                height: "30%",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Text style={{ fontSize: 24, fontFamily: "Muli" }}>
                                {dictionary["change-password-title"]}
                            </Text>
                        </Row>

                        <Row
                            style={{ width: "100%", height: "70%", justifyContent: "center" }}
                        >
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={15}
                                xxl={14}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 24,
                                        fontFamily: "Muli",
                                        textAlign: "center",
                                    }}
                                >
                                    {dictionary["change-password-subtitle"]}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ height: "40%", width: "100%" }}>
                    <Col
                        xs={24}
                        sm={{ span: 19, offset: 5 }}
                        md={{ span: 19, offset: 5 }}
                        lg={{ span: 21, offset: 3 }}
                        xxl={{ span: 21, offset: 3 }}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <Row style={{ height: "100%", width: "100%" }}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xxl={24}
                                style={{ justifyContent: "center", alignItems: "center" }}
                            >
                                <Row
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        display: "flex",
                                    }}
                                >
                                    <Form
                                        {...formItemLayout}
                                        form={form}
                                        name="password"
                                        onFinish={onFinish}
                                        style={{ width: "100%" }}
                                    >
                                        <Form.Item
                                            name="password"
                                            label={dictionary["change-password-field-password"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: dictionary["changePassword-text-title1"],
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password
                                                value={passwordForm}
                                                onChange={(e) => setPasswordForm(e.target.value)}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            label={
                                                dictionary["change-password-field-confirm-password"]
                                            }
                                            dependencies={["password"]}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: dictionary["changePassword-text-title2"],
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue("password") === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                dictionary["changePassword-text-title3"]
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                value={passwordConfirmForm}
                                                onChange={(e) =>
                                                    setPasswordConfirmfForm(e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item className="form-item-button">
                                            <Button className="button-change-password" htmlType="submit">
                                                {dictionary["changePassword-button-title1"]}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Row>
                                <Row
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                ></Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ height: "25%", width: "100%" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Row
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 30,
                            }}
                        >
                            <CounterDown seconds={seconds} handleOnLogout={LogOut} />
                        </Row>
                    </Col>
                </Row>
            </Row>

            <Row
                style={{
                    height: "5%",
                    backgroundColor: "#404193",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Text style={{ color: "white", fontSize: 16 }}>
                    {dictionary["login-privacy-info-text"]}
                </Text>
            </Row>
        </Content>
    );
};

export default ChangePasswordPage;
