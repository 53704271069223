//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Typography } from "antd";
import { MessageCandidate } from "../../../types/candidate";
import { clearUserChatSelected, getUserChatSelected } from "../../../store/chat";
import { getBackgroundColor, startGetJobPerCandidateByIdCandidateWithIdHiring } from "../../../hooks/hooks";
import { startGetLatestStatusCandidateAllJobs } from "../../../firebase/chat/hooks";
import { Timestamp, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../firebase/config";

// Interface
interface Props {
  candidate: MessageCandidate;
}

// Component
const MessageDetail: React.FC<Props> = ({ candidate }) => {
  // States
  const dispatch = useDispatch();
  const { Text } = Typography;

  const [lastMessage, setLastMessage] = useState<string>("");
  const [checkMsg, setCheckMsg] = useState<boolean>(true);
  const [cont, setCont] = useState<number>(1);
  const [status, setStatus] = useState<string>('interested');
  const [ backgroundColorStatus, setBackgroundColorStatus ] = useState('#00000016');

  const userChatSelected = useSelector(( state: any ) => state.chat.userChatSelected );
  const { user, token } = useSelector((state: any) => state.auth);
  const position = useSelector((state:any)=> state.chat.positionSelected);
  const filterJob = useSelector((state: any) => state.chat.filterJob);

  // Handlers
/*   const getStatus = useCallback( async (idHiring:string,idCandidate:string,idPosition) => {
    await firebase.firestore().collection("hirings").doc(idHiring).collection("positions").doc(idPosition).collection("recommendationCandidates").doc(idCandidate)
      .get().then((data) => setStatus(data?.data()?.status));
  },[]); */

  const getStatus = useCallback(async (idHiring, idCandidate, idPosition) => {
    try {
        // Reference to the specific document in the "recommendationCandidates" subcollection
        const candidateDocRef = doc(db, `hirings/${idHiring}/positions/${idPosition}/recommendationCandidates`, idCandidate);

        // Fetching the document
        const data = await getDoc(candidateDocRef);
        if (data.exists()) {
            setStatus(data.data()?.status); // Assuming setStatus is a state setter function
        } else {
            console.log("No such document!");
        }
    } catch (error) {
        console.error("Error fetching status:", error);
        // Handle the error appropriately
    }
}, []); // Dependencies for useCallback


  const getBackgroundColorStatus = useCallback((idUserSelected:string,idCandidate:string,status) => {
    const backgroundColor = getBackgroundColor(idUserSelected,idCandidate,status);
    setBackgroundColorStatus(backgroundColor);
  },[],);

  /* const handleOnSelectCard = ()=>{
    
    if (userChatSelected.id === candidate.id) {
      dispatch(clearUserChatSelected());
    } else {
      dispatch(
        getUserChatSelected({
          id: candidate.id,
          name: candidate.name,
          lastName: candidate.lastName,
        })
      );

      firebase.firestore().collection("hirings").doc(user?.idHiring).collection("messages").doc(candidate.id).collection("chat")
        .where("chatUser", "==", "to").where("check", "==", false)
        .get().then((queryData) => {
          queryData.forEach((docQuery) => {
            firebase.firestore().collection("hirings").doc(user?.idHiring).collection("messages").doc(candidate.id).collection("chat").doc(docQuery.data().id)
              .update({ check: true });
          });
        });

      firebase.firestore().collection("hirings").doc(user?.idHiring).collection("messages").doc(candidate.id)
        .update({
          messagesPending: false,
          notReaded: 0,
        });
    }
  }; */
  const handleOnSelectCard = async () => {
    if (userChatSelected.id === candidate.id) {
        dispatch(clearUserChatSelected());
    } else {
        dispatch(
            getUserChatSelected({
                id: candidate.id,
                name: candidate.name,
                lastName: candidate.lastName,
            })
        );

        // Reference to the chat subcollection
        const chatRef = collection(db, `hirings/${user?.idHiring}/messages/${candidate.id}/chat`);
        const q = query(chatRef, where("chatUser", "==", "to"), where("check", "==", false));

        const queryData = await getDocs(q);
        queryData.forEach(async (docQuery) => {
            const chatDocRef = doc(db, `hirings/${user?.idHiring}/messages/${candidate.id}/chat`, docQuery.id);
            await updateDoc(chatDocRef, { check: true });
        });

        const messageDocRef = doc(db, `hirings/${user?.idHiring}/messages`, candidate.id);
        await updateDoc(messageDocRef, {
            messagesPending: false,
            notReaded: 0,
        });
    }
};

  const getJobCandidate = useCallback( async( candidateId:string,hiringId:string,token:string ) => {
    try {  
      const responseJobPerCandidate = await startGetJobPerCandidateByIdCandidateWithIdHiring(candidateId,hiringId,token);
      
      if(responseJobPerCandidate !== null){
       
        const responseLatestStatus = await startGetLatestStatusCandidateAllJobs(responseJobPerCandidate,candidateId,hiringId);

        if(responseLatestStatus !== null){
          setStatus(responseLatestStatus)
        }
      }else {

      }
    } catch ( error ) {
      console.log(error);
    };
  },[ ]);
  
  // Effects
 useEffect(() => {
   if(filterJob === 'MessageByJob'){
    if(position?.idPosition !== ""){
      getStatus(user?.idHiring,candidate?.id,position?.idPosition);
      getBackgroundColorStatus(userChatSelected?.id,candidate.id,candidate.status);
    }
   }
 }, [filterJob,user?.idHiring,candidate?.id,position?.idPosition,getStatus,userChatSelected?.id,status,getBackgroundColorStatus,candidate.status])
 
 useEffect(() => {
   if(filterJob === 'Inbox'){
    getJobCandidate(candidate?.id,user?.idHiring,token);
    getBackgroundColorStatus(userChatSelected?.id,candidate?.id,candidate?.status);
   }
 }, [candidate?.status,candidate?.id,user?.idHiring,userChatSelected?.id,filterJob,getJobCandidate,getBackgroundColorStatus,token]);
 
/*   useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("hirings")
      .doc(user?.idHiring)
      .collection("messages")
      .doc(candidate.id)
      .collection("chat")
      .orderBy("date", "desc")
      .limit(1)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc) {
            if (doc.data().chatUser === "to") {
              if (!doc.data().check) {
                firebase
                  .firestore()
                  .collection("hirings")
                  .doc(user?.idHiring)
                  .collection("messages")
                  .doc(candidate.id)
                  .update({
                    messagesPending: true,
                    dateLastMessage: firebase.firestore.Timestamp.now()
                  });
              }
            }
          }
        
          !!doc.data().urlImage
            ? setLastMessage("Image")
            : !!doc.data().urlDocument
            ? setLastMessage("File")
            : setLastMessage(doc.data().text);
        });
      });

    return ()=>unsubscribe();
  }, [user?.idHiring, candidate.id]); */
  useEffect(() => {
    if (!user?.idHiring || !candidate.id) {
        return;
    }

    // Reference to the chat subcollection
    const chatRef = collection(db, `hirings/${user.idHiring}/messages/${candidate.id}/chat`);
    const q = query(chatRef, orderBy("date", "desc"), limit(1));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach(async (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                if (data.chatUser === "to" && !data.check) {
                    const messageDocRef = doc(db, `hirings/${user.idHiring}/messages`, candidate.id);
                    await updateDoc(messageDocRef, {
                        messagesPending: true,
                        dateLastMessage: Timestamp.now()
                    });
                }
                setLastMessage(data.urlImage ? "Image" : data.urlDocument ? "File" : data.text);
            }
        });
    });

    return () => unsubscribe();
}, [user?.idHiring, candidate.id]);

  /* useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("hirings")
      .doc(user?.idHiring)
      .collection("messages")
      .doc(candidate.id)
      .collection("chat")
      .where("chatUser", "==", "to")
      .where("check", "==", false)
      .onSnapshot((snapshot) => {
        var a = 0;
        snapshot.forEach(() => {
          setCheckMsg(false);
          a = a + 1;
        });
        setCont(a);
      });
    return () => unsubscribe();
  }, [user?.idHiring, candidate.id]); */
  useEffect(() => {
    if (!user?.idHiring || !candidate.id) {
        return;
    }

    // Reference to the "chat" subcollection with filters
    const chatRef = collection(db, `hirings/${user.idHiring}/messages/${candidate.id}/chat`);
    const q = query(chatRef, where("chatUser", "==", "to"), where("check", "==", false));

    const unsubscribe = onSnapshot(q, (snapshot) => {
        let count = 0;
        snapshot.forEach(() => {
            count += 1;
        });

        setCheckMsg(count === 0); // Set to false if there are unread messages
        setCont(count); // Update the count of unread messages
    });

    return () => unsubscribe();
}, [user?.idHiring, candidate.id]);

  return (
    <div
      style={{
        backgroundColor: backgroundColorStatus,
        border:'1px solid',
        padding:10,
        borderRadius:10,     
        borderColor:'#00000016',
        height: 75,
        width:"100%",
        marginBottom:'2%',
        cursor:'pointer',
        position:'relative',
        opacity:userChatSelected?.id === candidate.id ? 1 :0.5,
        fontWeight: userChatSelected?.id === candidate.id ? 'bold':'initial',
      }}
      onClick={handleOnSelectCard}
    >
      <Row
        style={{
          height: "60%",
          width: "100%",
        }}
      >
          {/* {checkMsg === true || cont === 0 ? null : (
            <div style={{ position: "absolute", bottom: '60%', left: 2 }}>
              <Badge style={{height:'20px',width:'20px'}} count={cont} />
            </div>
          )} */}
        <Col 
          style={{ 
            height: "100%",
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center'
          }} 
          
          xs={19} sm={20} md={19} lg={21} xxl={22}
        >
            <Text style={{ fontSize: 16,fontFamily:'Muli' }}>
              {candidate.name} {candidate.lastName}
            </Text>
          
        </Col>
        <Col xs={5} sm={4} md={5} lg={3} xxl={2} style={{display:'flex',alignItems:'center'}}>

          {
            checkMsg === true || cont === 0 ?null : (
              
                cont > 0 && (
                  <div style={{ height:20,width:20,backgroundColor:'#D12525',display:'flex',justifyContent:'center',alignItems:'center',borderRadius:50 }}>
                    <Text style={{fontFamily:'Muli',fontSize:11,color:'white'}}>{cont}</Text>
                  </div>
                )
              
            )
          }
        </Col>
      </Row>

      <Row style={{ height: "40%",justifyContent:'flex-end',paddingRight:'10%',alignItems:'center',display:'flex', width:"70%", marginLeft:"30%"}}>
        <Text style={{ color: "#00000070", fontSize: 16, fontFamily:'Muli', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"  }}>
          {lastMessage}
        </Text>
      </Row>
    </div>
  );
};

// Exports
export default MessageDetail;
