import { Button, Input } from 'antd';
import React from 'react'


interface IEditFieldForm {
    editValue: any;
    handleUpdateField: (value: string, list: Array<any>, index: number) => void;
    dataSource: any;
    idEditValue: any;
    setEditValue: (value: any) => void;
}

const EditFieldForm: React.FC<IEditFieldForm> = ({ editValue, handleUpdateField, dataSource, idEditValue, setEditValue }) => {
    return (
        <>
            <div style={{ flexGrow: 1 }}>
                <Input count={{
                    show: true,
                    max: 350,
                }} size='middle' style={{ borderRadius: '20px' }} allowClear value={editValue} onChange={(e) => { e.preventDefault(); setEditValue(e.target.value) }} />
            </div>

            <div className='counter-button-container'>
                <Button
                    onClick={() => {
                        if (editValue.length > 0) {
                            handleUpdateField(editValue, dataSource, idEditValue);
                        }
                    }}
                    className='button-table-add'
                >
                    Update
                </Button>
            </div>
        </>
    )
}

export default EditFieldForm
