//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Col, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import iconCalendar from "../../../image/iconCalendar.png";
import { db } from "../../../firebase/config";
import { TruncatedText } from "../../../common/TruncatedText";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

//Interface
interface Props {
  loadingTableCountDaysPosition: boolean;
  dataDashboard: Array<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
    daysOld: number;
    declined: number;
    hired: number;
    interested: number;
    interview: number;
    meeting: number;
    waitingForReply: number;
  }>;
}

//Component
const ActivityCard: React.FC<Props> = ({
  loadingTableCountDaysPosition,
  dataDashboard,
}) => {
  //States
  const { Text } = Typography;
  const { dictionary } = useSelector((state: any) => state.ui);
  const user = useSelector((state: any) => state.auth.user);
  const [ArrayDays, setArrayDays] = useState<Array<any>>([]);
  //Effects
  useEffect(() => {
    if (!user?.idHiring) {
      return;
    }

    // Reference to the "positions" subcollection
    const positionsRef = collection(db, `hirings/${user.idHiring}/positions`);
    const q = query(positionsRef, orderBy("title", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs: any = [];
      const fechaActual = new Date();
      const fechaActualSegundos = Math.floor(fechaActual.getTime() / 1000);

      querySnapshot.forEach((docSnapshot) => {
        const positionData = docSnapshot.data();
        if (positionData.status === "open") {
          const fechaCreacionSegundos = positionData.createAt.seconds;
          const diferenciaSegundos =
            fechaActualSegundos - fechaCreacionSegundos;
          const diasTranscurridos = Math.floor(
            diferenciaSegundos / (60 * 60 * 24)
          );

          docs.push({
            title: positionData.title,
            days: diasTranscurridos,
          });
        }
      });

      setArrayDays(docs); // Assuming setArrayDays is a state setter function
    });

    return () => unsubscribe();
  }, [user?.idHiring]);

  return (
    <Col
      className="contenedor"
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 24 }}
      lg={{ span: 24 }}
      xl={{ span: 24 }}
      xxl={{ span: 24 }}
      style={{
        height: "100%",
        paddingLeft: "0.5%",
        paddingRight: "0.5%",
        borderRadius: 16,
        border: "1px solid",
        borderColor: "#00000020",
        backgroundColor: 'white'
      }}
    >
      <Row
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: 16,
          marginBottom: 13,
        }}
      >
        <Col
          xs={{ span: 13, offset: 1 }}
          sm={{ span: 11, offset: 1 }}
          md={{ span: 11, offset: 1 }}
          lg={{ span: 11, offset: 1 }}
          xl={{ span: 11, offset: 1 }}
          xxl={{ span: 11, offset: 1 }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {dictionary["dashboard-title-job"]}
          </Text>
        </Col>

        <Col
          xs={{ span: 8 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {" "}
            {dictionary["dashboard-title-timeActivity"]}{" "}
          </Text>
        </Col>
      </Row>

      <Row
        style={{
          height: "85%",
          width: "100%",
          marginBottom: 10,
          marginTop: 80,
        }}
      >
        <Col
          className="contenedor"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 24 }}
          style={{ height: "75%", width: "100%", overflow: "auto" }}
        >
          {loadingTableCountDaysPosition ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Skeleton />
            </div>
          ) : (
            ArrayDays.map((each, index) => (
              <Row
                key={index + "dataDashboard"}
                style={{ marginTop: index > 0 ? 3 : 0 }}
              >
                <Col
                  key={index + "dataDashboardCol"}
                  xs={{ span: 16, offset: 1 }}
                  sm={{ span: 12, offset: 1 }}
                  md={{ span: 12, offset: 1 }}
                  lg={{ span: 12, offset: 1 }}
                  xl={{ span: 12, offset: 1 }}
                  xxl={{ span: 12, offset: 1 }}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <TruncatedText text={each.title} maxLength={40} />
                </Col>

                <Col
                  key={index + "dataDashboardTextTwo"}
                  xs={{ span: 7 }}
                  sm={{ span: 11 }}
                  md={{ span: 11 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xxl={{ span: 11 }}
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: 45,
                      width: 45,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={iconCalendar}
                      style={{ height: "100%", width: "100%" }}
                      alt="iconCalendar.png"
                    />
                    <Text
                      key={index + "dataDashboardTextTree"}
                      style={{
                        fontSize: 17,
                        display: "flex",
                        fontFamily: "Muli",
                        position: "absolute",
                        marginTop: 10,
                      }}
                    >
                      {each.days}
                    </Text>
                  </div>
                </Col>
              </Row>
            ))
          )}
        </Col>
      </Row>
    </Col>
  );
};

//Export
export default ActivityCard;
