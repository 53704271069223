import { Modal, Typography, Row, Col, Space } from 'antd';
import React from 'react';
import './InformativeModal.css';

// Destructuring components from Typography
const { Title, Paragraph } = Typography;

// Interface
interface Props {
    visible: boolean;
    setVisible: (value: boolean) => void;
    title?: string;
    description: string;
    videoUrl?: string; // URL del video de YouTube
}

// Components
const InformativeModal: React.FC<Props> = ({
    visible,
    setVisible,
    title,
    description,
    videoUrl, // URL del video
}) => {
    return (
        <Modal
            open={visible}
            centered
            width="700px"
            destroyOnClose
            onCancel={() => setVisible(false)}
            footer={null}
            className="informative-modal"
        >
            <Row
                style={{
                    padding: '20px',
                }}
            >
                <Col span={24}>
                    {
                        title && (
                            <Space align="center" style={{ width: '100%' }}>
                                <Title level={3} style={{ margin: 0 }}>
                                    {title}
                                </Title>
                            </Space>
                        )
                    }
                    <div style={{ marginTop: '20px' }}>
                        <Paragraph
                            style={{
                                fontSize: '16px',
                                lineHeight: '1.6',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {description}
                        </Paragraph>
                    </div>
                    {videoUrl && (
                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            <iframe
                                width="100%"
                                height="315"
                                src={videoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default InformativeModal;
