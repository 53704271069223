//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Button, Col, Row, Select, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from "../../../../../firebase/config";
import { Job } from '../../../../../types/position';
import JobPreviewModal from '../../JobPeviewModal';
import PositionInfoCard from './PositionInfoCard';
import { collection, getDocs } from 'firebase/firestore';
import './index.css'
//Interface
interface Props {
    handleOnNextStep: (actualStep: 'Initial' | 'First Step' | 'Second Step' | 'Third Step') => void;
    setTitle: (title: string) => void;
    setType: (typeList: Array<string>) => void;
    setLocation: (location: string) => void;
    setSalary: (salary: string) => void;
    setSalaryMax: (salaryMax: string) => void;
    setTotalSalary: (salary: string) => void;
    setWorkplace: (workplace: Array<string>) => void;
    setDescription: (description: string) => void;
    setResponsibilities: (value: Array<any>) => void;
    setRequirements: (value: Array<any>) => void;
    setSkills: (value: Array<any>) => void;
    checkHourly: boolean;
    setCheckHourly: (value: boolean) => void;
    checkAnnual: boolean;
    setCheckAnnual: (value: boolean) => void;
    checkAmount: boolean;
    setCheckAmount: (value: boolean) => void;
    checkPercentage: boolean;
    setCheckPercentage: (value: boolean) => void;
    setOtherDetailsCompensation: (value: string) => void;
    otherDetailsCompensation: string;
    setValueHourForWeek: (value: number) => void;
    valueHourForWeek: number;
}

// Component
const { Text } = Typography;
const InitialStepCreationJob: React.FC<Props> = (
    {
        handleOnNextStep, setTitle,
        setType,
        setLocation,
        setSalary,
        setSalaryMax,
        setWorkplace,
        setDescription,
        setResponsibilities,
        setRequirements,
        setSkills,
        setCheckHourly,
        setCheckAmount,
        setCheckAnnual,
        setCheckPercentage,
        setTotalSalary,
        otherDetailsCompensation,
        setOtherDetailsCompensation,
        valueHourForWeek,
        setValueHourForWeek
    }) => {

    //States
    const user = useSelector((state: any) => state.auth.user);
    const { dictionary } = useSelector((state: any) => state.ui);
    const [positionList, setPositionList] = useState<Array<Job>>([]);
    const [positionSelectList, setPositionSelectList] = useState<Array<any>>([]);
    const [visibleJobPreviewModal, setVisibleJobPreviewModal] = useState<boolean>(false);
    const [positionSelected, setPositionSelected] = useState<any>({});
    const [positionSelectedCheck, setPositionSelectedCheck] = useState<boolean>(false);
    const [defaultValueSelect, setDefaultValueSelect] = useState<string>(dictionary['creation-modal-initial-page-button-select-job'])
    /* const [defaultValueSelectTemplate, setDefaultValueSelectTemplate] = useState<string>(dictionary['creation-modal-initial-page-button-select-job']) */
    const [positionSelectedTemplate, setPositionSelectedTemplate] = useState<any>({});
    const [positionSelectedCheckTemplate, setPositionSelectedCheckTemplate] = useState<boolean>(false);

    /*  const [positionTemplateList, setPositionTemplateList] = useState<Array<any>>([]);
     const [positionSelectTemplateList, setPositionSelectTemplateList] = useState<Array<any>>([]); */

    /*  const getPositionTemplateList = useCallback(async (idHiring) => {
         try {
             const positionListTemplate: any = [];
             const positionListTemplateSelected: any = [];
 
             const jobsCollectionRef = collection(db, 'templates', 'jobs', 'jobs');
             const jobsQuery = query(jobsCollectionRef, where("language", "==", language));
             const positionsCollect = await getDocs(jobsQuery);
 
             positionsCollect.forEach((each) => {
                 positionListTemplate.push({
                     value: each.data().idPosition,
                     label: each.data().title
                 });
                 positionListTemplateSelected.push(each.data());
             });
 
             setPositionTemplateList(positionListTemplate);
                setPositionSelectTemplateList(positionListTemplateSelected);
         } catch (error) {
             console.log("Error in getPositionTemplateList()", error);
         }
     }, [language]); */

    const getPositionList = useCallback(async (idHiring) => {
        try {
            const positionList: any = [];
            const positionListSelected: any = [];

            const positionsCollectionRef = collection(db, 'hirings', idHiring, 'positions');
            const positionsCollect = await getDocs(positionsCollectionRef);

            positionsCollect.forEach((each) => {
                positionList.push({
                    value: each.data().idPosition,
                    label: each.data().title
                });
                positionListSelected.push(each.data());
            });

            setPositionList(positionList);
            setPositionSelectList(positionListSelected);

        } catch (error) {
            console.log("Error in getPositionList:", error);
        }
    }, []);

    const handleOnSelectJob = (idHiring: string, positionList: Array<any>) => {

        const searchPosition = positionList.find((position: any) => position.idPosition === idHiring);
        setDefaultValueSelect(searchPosition.title)
        searchPosition !== undefined && setPositionSelected(searchPosition);
        searchPosition !== undefined && setPositionSelectedCheck(true);
        positionSelectedTemplate && setPositionSelectedTemplate({});
        positionSelectedTemplate && setPositionSelectedCheckTemplate(false);
        /* setDefaultValueSelectTemplate(dictionary['creation-modal-initial-page-button-select-job']) */
    };

    /*  const handleOnSelectTemplateJob = (idHiring: string, positionList: Array<any>) => {
 
         const searchPosition = positionList.find((position: any) => position.idPosition === idHiring);
         setDefaultValueSelectTemplate(searchPosition.title)
 
         searchPosition !== undefined && setPositionSelectedTemplate(searchPosition);
         searchPosition !== undefined && setPositionSelectedCheckTemplate(true);
         positionSelected && setPositionSelected({});
         positionSelected && setPositionSelectedCheck(false);
         setDefaultValueSelect(dictionary['creation-modal-initial-page-button-select-job'])
     }; */

    const handleOnCloneJob = (position: Job) => {
        setVisibleJobPreviewModal(false);

        setTitle(position?.title ? position?.title : "");
        setOtherDetailsCompensation(position?.otherDetailsCompensation ? position?.otherDetailsCompensation : "");
        setLocation(position.location);
        setType(position.jobType);
        setWorkplace(position.workplace);
        setDescription(position.description);
        setResponsibilities(position.responsibilities);
        setRequirements(position.technicalRequirements);
        setSkills(position.skills);
        setValueHourForWeek(position.hourForWeek ? position.hourForWeek : 40);
        if (position.salary !== undefined && position.salary !== "") {
            setSalary(position.salary);
            if (position.hourly !== undefined) {
                setCheckHourly(position.hourly);
            }
            if (position.annual !== undefined) {
                setCheckAnnual(position.annual);
            }
        }
        if (position.salaryMax !== undefined && position.salaryMax.length > 0 && position.salary !== '0') {
            setSalaryMax(position.salaryMax)
        } else {
            setSalaryMax("")
        }
        if (position.totalSalary !== undefined && position.totalSalary.length > 0 && position.totalSalary !== '0') {
            setTotalSalary(position?.totalSalary);
            if (position.percentage !== undefined) {
                setCheckPercentage(position.percentage);
            }
            if (position.amount !== undefined) {

                setCheckAmount(position.amount);
            }
        } else {
            setTotalSalary("");
            setCheckAmount(false);
            setCheckPercentage(false);
        }

        handleOnNextStep('Initial');
    }

    useEffect(() => {
        getPositionList(user?.idHiring);
        /* getPositionTemplateList(user?.idHiring) */
    }, [getPositionList, user?.idHiring]);

    useEffect(() => {
        setPositionSelectedCheck(false);
        setPositionSelected({});

    }, []);
    return (
        <Content style={{ minHeight: '70vh', width: '100%', padding: "3%" }}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }}>



                <Row style={{ width: '100%', minHeight: '60vh' }}>
                    <Row style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24} style={{ display: 'flex', alignItems: 'center' }}>
                            <Row style={{ width: '100%' }}>
                                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={24} sm={24} md={12} lg={12} xl={{ span: 12 }} xxl={12}>
                                    <Text style={{ fontSize: 32, color: '#414193', textAlign: "center" }}>{dictionary['creation-modal-title']}</Text>
                                </Col>
                                <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={{ span: 18, offset: 3, }} sm={{ span: 18, offset: 3, }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} xl={{ span: 8, offset: 4 }} xxl={{ span: 8, offset: 4 }}>
                                    <Button size='middle' className='button-go-initial-step' onClick={() => handleOnNextStep('Initial')} >{dictionary['creation-modal-initial-page-button-go']}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ width: '100%', paddingTop: 10, height: 300, backgroundColor: "#f4f7fc" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24}>
                            <Row style={{ height: '100%', width: '100%', borderRadius: 10, padding: 6 }}>
                                <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={24} sm={24} md={12} lg={12} xl={{ span: 12 }} xxl={12}>
                                        <Text style={{ fontSize: 18 }}>{dictionary['creation-modal-initial-page-title-2']}</Text>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12} xl={{ span: 12 }} xxl={12} style={{ border: '1px solid', borderRadius: '20px', borderColor: '#00000016', paddingLeft: '9px', paddingRight: '9px', paddingTop: '1px', paddingBottom: '1px', backgroundColor: 'white' }}>
                                        <Select
                                            placeholder={dictionary['creation-modal-initial-page-button-select-job']}
                                            optionFilterProp="children"
                                            onChange={(value) => handleOnSelectJob(value, positionSelectList)}
                                            style={{ width: '100%', backgroundColor: "white", borderRadius: 5, border: "transparent" }}
                                            options={positionList}
                                            bordered={false}
                                            value={defaultValueSelect}
                                            defaultValue={defaultValueSelect}
                                        />
                                    </Col>
                                </Row>


                                {/* <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={24} sm={24} md={12} lg={12} xl={{ span: 12 }} xxl={12}>
                                        <Text style={{ fontSize: 18 }}>{dictionary['creation-modal-initial-page-title-3']}</Text>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={12} xl={{ span: 12 }} xxl={12} style={{ border: '1px solid', borderRadius: '20px', borderColor: '#00000016', paddingLeft: '9px', paddingRight: '9px', paddingTop: '1px', paddingBottom: '1px', backgroundColor: 'white' }}>
                                        <Select
                                            placeholder={dictionary['creation-modal-initial-page-button-select-job']}
                                            optionFilterProp="children"
                                            onChange={(value) => handleOnSelectTemplateJob(value, positionSelectTemplateList)}
                                            style={{ width: '100%', backgroundColor: "white", borderRadius: 5, border: "transparent" }}
                                            options={positionTemplateList}
                                            bordered={false}
                                            value={defaultValueSelectTemplate}
                                            defaultValue={defaultValueSelectTemplate}
                                        />
                                    </Col>
                                </Row> */}

                            </Row>
                        </Col>
                    </Row>
                    <Col style={{ bottom: 40, marginBottom: 22 }} xs={24} sm={24} md={24} lg={{ span: 12, offset: 12 }} xl={{ span: 12, offset: 12 }} xxl={{ span: 12, offset: 12 }}>
                        {positionSelectedCheck && (
                            <PositionInfoCard position={positionSelected} handleOnClickButtonPreview={() => setVisibleJobPreviewModal(true)} handleOnClickButtonGo={() => handleOnCloneJob(positionSelected)} />
                        )}
                        {positionSelectedCheckTemplate && (
                            <PositionInfoCard position={positionSelectedTemplate} handleOnClickButtonPreview={() => setVisibleJobPreviewModal(true)} handleOnClickButtonGo={() => handleOnCloneJob(positionSelectedTemplate)} />
                        )}
                    </Col>
                </Row>
            </Col>

            <JobPreviewModal
                visibleModal={visibleJobPreviewModal}
                closeModal={() => setVisibleJobPreviewModal(false)}
                position={positionSelectedCheck ? positionSelected : positionSelectedTemplate}
                handleOnCloneJob={() => handleOnCloneJob(positionSelectedCheck ? positionSelected : positionSelectedTemplate)}
                valueHourForWeek={valueHourForWeek}
            />
        </Content>
    )
}

// Exports
export default InitialStepCreationJob;