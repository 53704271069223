
import axios from "axios";
import routes from "../api";
import { API_RECAPTCHA_SECRET_KEY } from "../api/secrets";
import { getOpenPositions, getRecommendationCandidate } from "../firebase/endpoints/hiringEndPoint";

const url = routes.mode === "dev" ? routes.dev_route : routes.qa_route;

export const verificationTokenGoogle = async (tokenGoogle: any) => {
  try {
    const response = await fetch(`${url}/check/verificationTokenGoogleRecaptcha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api_recaptcha_secret_key': API_RECAPTCHA_SECRET_KEY,
        'token_google': tokenGoogle,
      }
    });


    const result = await response.json();

    return result.success === true ? true : false
  } catch (error) {
    return false
  }
};

export const formatNumber = (number: any) => {
  if (number !== null && number !== undefined) {
    const isString = typeof number === 'string';
    const numberWithoutCommas = isString ? number.replace(/,/g, '') : number.toString().replace(/,/g, '');

    // Elimina todos los caracteres no deseados excepto números, puntos y comas
    const sanitizedInput = numberWithoutCommas.replace(/[^0-9.,]/g, '');

    // Divide la cadena en parte entera y parte decimal
    const parts = sanitizedInput.split(/[.,]/);
    let integerPart = parts[0];
    let decimalPart = parts[1];

    // Formatea la parte entera con comas para separar miles
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Concatena la parte decimal si existe
    const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

    return formattedNumber;
  } else {
    return ''; // Devuelve una cadena vacía si la entrada no es válida
  }
};

export const getBackgroundColor = (idUserSelected: string, idCandidate: string, status: string) => {
  let backgroundColorStatus = "";
  if (idUserSelected === idCandidate) {
    if (status === '') {

      backgroundColorStatus = '#00000016';
    } else if (status === 'meeting') {
      backgroundColorStatus = '#F5C011';
    } else if (status === 'interview') {
      backgroundColorStatus = '#A1E291';
    } else if (status === 'hired') {
      backgroundColorStatus = '#77AB59';
    } else if (status === 'declined') {
      backgroundColorStatus = '#707070';
    } else if (status === 'offer') {
      backgroundColorStatus = '#62A2F6';
    } else if (status === 'interested') {
      backgroundColorStatus = "#FEC892";
    }
  } else {

    if (status === 'interested') {
      backgroundColorStatus = '#FEC892';
    } else if (status === 'meeting') {
      backgroundColorStatus = '#F5C011';
    } else if (status === 'interview') {
      backgroundColorStatus = '#A1E291';
    } else if (status === 'hired') {
      backgroundColorStatus = '#77AB59';
    } else if (status === 'declined') {
      backgroundColorStatus = '#707070';
    } else if (status === 'offer') {
      backgroundColorStatus = '#62A2F6';
    } else {
      backgroundColorStatus = '#00000016';
    }
  };

  return backgroundColorStatus;
};

export const startGetJobPerCandidateByIdCandidateWithIdHiring = async (candidateId: string, hiringId: string, token: string) => {
  try {
    const config = {
      headers: {
        candidateId: candidateId,
        Authorization: token
      }
    };

    const responseGetJobCandidate = await axios.get(`${url}/user/getJobCandidate`, config);

    if (responseGetJobCandidate.status === 200) {
      if (responseGetJobCandidate.data.status === 200) {
        return responseGetJobCandidate.data.data;

      } else if (responseGetJobCandidate.data.status === 400) {
        return null;
      }
    } else {
      return null
    }
  } catch {
    return null;
  }
}

export const startGetCandidateListByJob = async (idPosition: string, idHiring: string) => {
  try {

    const config = {
      headers: {
        positionId: idPosition,
        hiringId: idHiring
      }
    };

    const responseGetCandidateList = await axios.get(`${url}/user/getChatListCandidate`, config)

    if (responseGetCandidateList.status === 200) {
      if (responseGetCandidateList.data.status === 200) {
        return responseGetCandidateList.data.data;

      } else if (responseGetCandidateList.data.status === 400) {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  };
}

type Dictionary = {
  [key: string]: string;
};

export const getTranslation = (key: string, dictionary: Dictionary | null): string => {
  if (dictionary && dictionary.hasOwnProperty(key)) {
    return dictionary[key];
  } else {
    return `Translation not found for key: ${key}`;
  }
};

export const startFetchDataDashboard = async (token: string) => {
  try {
    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };
    const responseGetDataDashboard = await axios.get(`${url}/user/dashboardData`, config);

    if (responseGetDataDashboard.status >= 200 && responseGetDataDashboard.status <= 299) {
      return responseGetDataDashboard.data.data
    } else {
      return null;
    }
  } catch (error) {
    return null
  }
}

export const getDataDashboardValidated = (dataDashboard: Array<any>, jobList: Array<any>) => {
  const positionList: Array<any> = jobList.map((jobData: any) => {
    const validationId = dataDashboard.find((d: any) => d.position_id === jobData.idPosition);

    return {
      idPosition: validationId ? validationId.position_id : jobData.idPosition,
      name: validationId ? validationId.position_title : jobData.title,
      like: validationId ? validationId.candidate_like_true : 0,
      dislike: validationId ? validationId.candidate_like_false : 0,
      feedback: validationId ? validationId.remote + validationId.other_location + validationId.better_salary_range + validationId.level : 0,
      remote: validationId ? validationId.remote : 0,
      location: validationId ? validationId.other_location : 0,
      betterSalaryRange: validationId ? validationId.better_salary_range : 0,
      level: validationId ? validationId.level : 0,
      daysOld: validationId ? validationId.time_activity_days : 0,
      declined: validationId ? validationId.declined : 0,
      hired: validationId ? validationId.hired : 0,
      interested: validationId ? validationId.interested : 0,
      interview: validationId ? validationId.interview : 0,
      meeting: validationId ? validationId.meeting : 0,
      waitingForReply: validationId && validationId.waiting_for_reply ? validationId.waiting_for_reply : 0,
      offer: validationId && validationId.offer ? validationId.offer : 0,
    };
  });

  return positionList;
};

export const getNewDimensionsByScreenSize = (size: number) => {
  if (size >= 600 && size <= 800) {
    return 10
  } else if (size >= 801 && size <= 1001) {
    return 0
  } else if (size >= 1002 && size <= 1200) {
    return 0
  } else if (size >= 1201 && size <= 1400) {
    return 30
  } else if (size >= 1401 && size <= 1600) {
    return 60
  } else if (size >= 1601 && size <= 1800) {
    return 50
  } else if (size >= 1801 && size <= 2000) {
    return 0
  } else if (size >= 2001 && size <= 2200) {
    return 0
  } else {
    return 0
  }
};


export const getNewDimensionsByScreenSizeFeedBack = (size: number) => {
  if (size >= 600 && size <= 800) {
    return 10
  } else if (size >= 801 && size <= 1001) {
    return 10
  } else if (size >= 1002 && size <= 1200) {
    return 10
  } else if (size >= 1201 && size <= 1400) {
    return 20
  } else if (size >= 1401 && size <= 1600) {
    return 30
  } else if (size >= 1601 && size <= 1800) {
    return 30
  } else if (size >= 1801 && size <= 2000) {
    return 20
  } else if (size >= 2001 && size <= 2200) {
    return 20
  } else {
    return 20
  }
};


export const startToGetJobListByCandidate = async (candidateId: string, hiringId: string) => {
  try {

    const jobList = await getOpenPositions(hiringId);
    const positionByCandidate: Array<any> = [];
    if (jobList.length > 0) {
      for (const job of jobList) {
        const recomCandidate = await getRecommendationCandidate(hiringId, job.id, candidateId);
        if (recomCandidate) {

          // Verifica que los campos "like", "candidateLike" y "status" existan y sean true
          if (recomCandidate?.like && recomCandidate?.candidateLike && recomCandidate?.status !== 'declined') {
            // Agrega el objeto completo con los datos de la recomendación y el ID de la posición
            positionByCandidate.push({
              positionId: job.id,
              recommendationData: recomCandidate,
            });
          }
        }
      }
    }
    return positionByCandidate;
  } catch (error) {
    console.error('Error al obtener la lista de posiciones para el candidato:', error);
    return [];
  }
};


