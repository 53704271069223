//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React from "react";
import iconRatingTrue from "../image/ico-rating-0-green.png";
import iconRatingFalse from "../image/ico-rating-1-green.png";

// Interface
interface Props {
  rating: string;
}

// Component
const IconRatingSkill: React.FC<Props> = ({ rating }) => {
  //   States
  const ratingValue = [1, 2, 3, 4, 5];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {ratingValue.map((item: number) =>
        item <= parseInt(rating) ? (
          <div key={item + item + "rating"}>
            <img
              src={iconRatingTrue}
              style={{ height: 26, width: "auto", margin: 0 }}
              alt={iconRatingTrue}
            />
          </div>
        ) : (
          <div key={item + item + "rating"}>
            <img
              key={item + item + "ratingImg"}
              src={iconRatingFalse}
              style={{ height: 26, width: 22, margin: 0 }}
              alt={iconRatingFalse}
            />
          </div>
        )
      )}
    </div>
    // <List
    //   bordered={false}
    //   style={{flexDirection:"row",display:"flex", justifyContent:"center", background:"red"}}
    //   dataSource={ratingValue}
    //   renderItem={(item: any) =>
    //     item <= rating ? (
    //       <img
    //         src={iconRatingTrue}
    //         style={{ height: 24, width: 20, margin: 0 }}
    //         alt={iconRatingTrue}
    //       />
    //     ) : (
    //       <img
    //         src={iconRatingFalse}
    //         style={{ height: 24, width: 20, margin: 0 }}
    //         alt={iconRatingFalse}
    //       />
    //     )
    //   }
    // />
  );
};

// Export
export default IconRatingSkill;
