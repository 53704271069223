import { Button, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

//Interface
interface Props {
  visible: boolean;
  handleOnCloseModal: () => void;
}

const { Text } = Typography;
const HelpInformationModal: React.FC<Props> = ({
  visible,
  handleOnCloseModal,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      onCancel={handleOnCloseModal}
      title={
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 22, fontFamily: "Muli" }}>
            {dictionary["dashboard-title-status-candidates"]}
          </Text>
          <Button
            shape="circle"
            style={{
              color: "white",
              backgroundColor: "#D12525",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={<CloseOutlined />}
            onClick={handleOnCloseModal}
          />
        </Row>
      }
      width="45%"
      footer={<div></div>}
    >
      <Content style={{ width: "100%", height: "auto", paddingLeft: "4%" }}>
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <Text style={{ fontSize: 16, fontFamily: "Muli" }}>
            {dictionary["dashboard-modal-status-candidate-parrafo1"]}
          </Text>
        </Row>

        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            textAlign: "center",
            paddingLeft: "17%",
            paddingRight: "17%",
          }}
        >
          <Text style={{ fontSize: 16, fontFamily: "Muli" }}>
            {dictionary["dashboard-modal-status-candidates-parrafo2"]}
          </Text>
        </Row>
      </Content>
    </Modal>
  );
};

export default HelpInformationModal;
