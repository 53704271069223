import React, { useState } from 'react';
import './index.css';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'antd';
import { StateGeneralRedux } from '../../../../types/redux';
import { useSelector } from 'react-redux';

// Interface
interface Props {
    user: {
        name: string,
        lastName: string;
        phoneNumber: string;
        idCompany: string;
        companyName: string;
        email: string;
        idHiring: string;
    };
    setStep: (value: 'Initial' | 'VerificationEmail' | 'completeRegistration' | 'Error' | 'Loading') => void;
}

// Component
const VerificationStep: React.FC<Props> = ({ user: currentData, setStep }) => {
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    // States
    const [loadingResendEmail, setLoadingResendEmail] = useState<boolean>(false);
    const [checkingVerification, setCheckingVerification] = useState<boolean>(false);

    // Handlers
    const resendVerificationEmail = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        setLoadingResendEmail(true);

        if (user) {
            sendEmailVerification(user)
                .then(() => {
                    toast.success(dictionary['verification-step-success-resend']);
                })
                .catch((error) => {
                    toast.error(dictionary['verification-step-error-resend']);
                    console.error('Error resending the verification email:', error);
                });
        } else {
            toast.info(dictionary['verification-step-info-no-user']);
            console.error('No authenticated user available to resend the verification email.');
        }
        setLoadingResendEmail(false);
    };

    const checkEmailVerification = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        setCheckingVerification(true);

        if (user) {
            await user.reload();
            if (user.emailVerified) {
                setStep('completeRegistration')
            } else {
                toast.warning(dictionary['verification-step-warning-not-verified']);
            }
        } else {
            toast.info(dictionary['verification-step-info-no-user']);
            console.error('No authenticated user available.');
        }
        setCheckingVerification(false);
    };

    return (
        <div className='verification-step'>
            <h1>{dictionary['verification-step-title']}</h1>
            <span style={{ fontSize: 23 }}>
                {dictionary['verification-step-instruction-1']}
                <b>{currentData.email}</b>. {dictionary['verification-step-instruction-2']}

            </span>
            <span style={{ fontSize: 20, marginTop: '1em' }}>
                {dictionary['verification-step-instruction-3']}

            </span>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>
                <div className='buttons-section'>
                    <Button loading={loadingResendEmail} className='button-verification' onClick={resendVerificationEmail}>
                        {dictionary['verification-step-resend-email-button']}

                    </Button>
                    <Button loading={checkingVerification} className='button-verification' onClick={checkEmailVerification} style={{ marginLeft: '1em' }}>
                        {dictionary['verification-step-check-verification-button']}

                    </Button>
                </div>
            </div>
        </div>
    );
}

// Export
export default VerificationStep;
