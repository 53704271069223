import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { TruncatedText } from "../../../common/TruncatedText";

// interface
interface Props {
  index: number;
  handleOnClick: () => void;
  positionSelected: any;
  each: any;
}
const TitlePositionCol: React.FC<Props> = ({
  index,
  each,
  handleOnClick,
  positionSelected,
}) => {
  const [truncateTextMaxLengh, setTruncateTextMaxLengh] = useState<number>(30);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && window.innerWidth < 1300) {
        setTruncateTextMaxLengh(20);
      } else if (window.innerWidth > 1300 && window.innerWidth < 1700) {
        setTruncateTextMaxLengh(29);
      } else if (window.innerWidth > 1700 && window.innerWidth < 1800) {
        setTruncateTextMaxLengh(40);
      } else if (window.innerWidth < 1200) {
        setTruncateTextMaxLengh(40);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Row
      key={"DashStatusJobCard" + index}
      style={{
        marginBottom: "1%",
        border: positionSelected === each.idPosition ? "1px solid" : "",
        cursor: "pointer",
        borderTopLeftRadius: 10,
        paddingLeft: 10,
        borderBottomLeftRadius: 10,
        borderColor:
          positionSelected === each.idPosition ? "#24CECE" : "#00000000",
        backgroundColor: positionSelected === each.idPosition ? "#24CECE" : "",
        fontWeight: positionSelected === each.idPosition ? "bold" : "initial",
        marginTop: index > 0 ? 3 : 0,
        height: 45,
      }}
      onClick={() => {
        handleOnClick();
      }}
    >
      <Col
        xs={{ span: 23, offset: 1 }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <TruncatedText text={each.name} maxLength={truncateTextMaxLengh} />
      </Col>
    </Row>
  );
};

export default TitlePositionCol;
