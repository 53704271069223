//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Carousel, Col, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import FirstSectionCarousel from "./FirstSectionCarousel";
import SecondSectionCarousel from "./SecondSectionCarousel";
import ThirdSectionCarousel from "./ThirdSectionCarousel";
import  { RightOutlined,LeftOutlined} from '@ant-design/icons'

// Interface
interface Props{
    titleInput:string;
    jobType:Array<string>;
    location:string;
    workplace:Array<string>;
    description:string;
    salary:string;
    salaryMax:string;
    responsibilities:Array<any>;
    requirements:Array<any>;
    skills:Array<any>;
    totalSalary:string;
    annualOrHourly:boolean;
    annualCheck:boolean;
    hourlyCheck:boolean;
    percentageCheck:boolean;
    amountCheck:boolean;
    otherDetailsCompensation:string;
    valueHourForWeek:number;
    checkMin:boolean;
    checkMiddle:boolean;
    checkMax:boolean;
}

const SampleNextArrow = (props:any) => {
    const { className, style, onClick } = props

     const [isHover, setIsHover] = useState(false);

     const handleMouseEnter = () => {
       setIsHover(true);
     };
     const handleMouseLeave = () => {
       setIsHover(false);
     };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          borderRadius: "50px",
          height: "40px",
          width: "40px",
          lineHeight: "1.5715",
          justifyContent: "center",
          backgroundColor: isHover ? "rgb(0, 191, 255)" : "lightblue",
          marginTop: "230px",
          marginLeft: 30,
          display: "flex",
        }}
        onClick={onClick}
      >
        <RightOutlined  />
      </div>
    );
  }
  
  const SamplePrevArrow = (props:any) => {
    const { className, style, onClick } = props

     const [isHover, setIsHover] = useState(false);

     const handleMouseEnter = () => {
       setIsHover(true);
     };
     const handleMouseLeave = () => {
       setIsHover(false);
     };

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          backgroundColor: isHover ? "rgb(0, 191, 255)" : "lightblue",
          borderRadius: "50px",
          lineHeight: "1.5715",
          justifyContent: "center",
          height: "40px",
          width: "40px",
          marginTop: "230px",
          marginRight: 30,
          display: "flex",
        }}
        onClick={onClick}
      >
        <LeftOutlined  />
      </div>
    );
  }


// Dependencies
const JobPreviewCarousel:React.FC<Props> = ({checkMin,checkMiddle,checkMax, valueHourForWeek,otherDetailsCompensation,description,jobType,location,requirements,responsibilities,salary,skills,titleInput,workplace, annualOrHourly,amountCheck,annualCheck,hourlyCheck,percentageCheck,totalSalary, salaryMax })=>{
  const { Text } = Typography;
  
return (
    <Col style={{height:'100%',width:'100%',border:'1px solid',borderColor:'#00000016'}}>
        <Row style={{width:'100%',marginTop:20,display:'flex',justifyContent:'center',textAlign:'center',paddingLeft:25,paddingRight:25}}>
            <Text style={{fontSize:30,color:"#6153d3",fontFamily:'Muli'}}>{titleInput}</Text>
        </Row>
        <Carousel style={{width:'100%',height:'58vh',padding:20}} dotPosition="top" arrows={true}  nextArrow={<SampleNextArrow/>} prevArrow={<SamplePrevArrow />}>
            <Content>
                <div style={{ height:'58vh',marginTop:26 }}>
                    <FirstSectionCarousel bonus={totalSalary} valueHourForWeek={valueHourForWeek} percentageCheck={percentageCheck} amountCheck={amountCheck} salaryMax={salaryMax} checkMin={checkMin} checkMax={checkMax} checkMiddle={checkMiddle} annualCheck={annualCheck} hourlyCheck={hourlyCheck} location={location} salary={salary} workplace={workplace} description={description} responsibilities={responsibilities} otherDetailsCompensation={otherDetailsCompensation}  />
                </div>
                
            </Content>
            <Content>
                <div style={{ height:'58vh',marginTop:26 }}>
                    <SecondSectionCarousel requirements={requirements} skills={skills}/>
                </div>
            </Content>
            <Content>
                <div style={{ height:'58vh',marginTop:26 }}>
                    <ThirdSectionCarousel checkMin={checkMin} checkMax={checkMax} checkMiddle={checkMiddle} valueHourForWeek={valueHourForWeek} otherDetailsCompensation={otherDetailsCompensation} annualCheck={annualCheck} hourlyCheck={hourlyCheck} percentageCheck={percentageCheck} salary={salary} bonus={totalSalary} amountCheck={amountCheck} type={jobType} salaryMax={salaryMax} workplace={workplace} location={location} />
                </div>
            </Content>
        </Carousel>
    
    </Col>
)
}

// Exports
export default JobPreviewCarousel;