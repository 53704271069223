import { Tooltip, Typography } from "antd";
import React from "react";

const { Text } = Typography;
export const TruncatedText = ({
  text,
  maxLength,
}: {
  text: string;
  maxLength: number;
}) => {
  if (text.length <= maxLength) {
    return <Text style={{ fontSize: 17, fontFamily: "Muli" }}>{text}</Text>;
  } else {
    return (
      <Tooltip title={text}>
        <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
          {text.slice(0, maxLength)}...
        </Text>
      </Tooltip>
    );
  }
};
