import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, } from "firebase/auth";
import { db } from "../config";
import { setDoc, doc } from "firebase/firestore";

// Functions
export const setHiringRequest = async (name: string, lastName: string, email: string, phoneNumber: string = '', company: string, companyIDQR: string, uidHiring: string) => {
    try {
        const hiringDocRef = doc(db, "hirings", uidHiring);
        await setDoc(hiringDocRef, {
            name,
            lastName,
            email,
            phoneNumber,
            company,
            idCompanyQR: companyIDQR,
            avatarCompany: '',
            avatarPersonal: '',
            companyInformation: '',
            companyWebsite: '',
            firstLogin: true,
            forceChangePassword: false,
            idHiring: uidHiring,
            industry: '',
            title: '',
        }).catch((error) => console.log(error));
        return {
            sucess: true
        }
    } catch (error) {
        return {
            sucess: false
        }  // Propaga el error para manejo externo
    }
}

export const registerWithEmailAndPassword = async (email: string, password: string) => {
    const auth = getAuth();
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        sendEmailVerification(userCredential.user)
        // Actualizar perfil del usuario con número de teléfono
        // Additional user profile updates or data logging can go here
        return {
            success: true,
            user: userCredential.user,
            error: null
        };
    } catch (error: any) {
        return {
            success: false,
            user: null,
            error: error
        };
    }
}