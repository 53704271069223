import { Col, List, Row, Typography } from "antd";
import React from "react";
import icoEducation from "../../../../image/ico-education.png";
import { useSelector } from "react-redux";
import { Candidate } from "../../../../types/candidate";
const { Text } = Typography;

// interface
interface Props {
  candidate: Candidate;
  educationList: Array<any>;
}
const CandidateEducationSection: React.FC<Props> = ({
  candidate,
  educationList,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "6%",
      }}
    >
      <Row style={{ width: "100%", marginBottom: "10%" }}>
        <Col span={23} offset={1}>
          <Row style={{ width: "100%", marginBottom: "3%" }}>
            <Col
              xs={{ span: 3 }}
              sm={{ span: 2 }}
              md={{ span: 2 }}
              xl={{ span: 3 }}
              xxl={{ span: 2 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={icoEducation}
                style={{ height: 20, width: 20, margin: 0 }}
                alt={icoEducation}
              />
            </Col>
            <Col
              xs={{ span: 21 }}
              sm={{ span: 22 }}
              md={{ span: 22 }}
              xl={{ span: 21 }}
              xxl={{ span: 22 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Text style={{ color: "#129BD4", fontSize: 20 }}>
                {dictionary["job-candidate-detail-title-education"]}
              </Text>
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            <Col span={24}>
              {educationList.length > 0 && (
                <List
                  dataSource={educationList}
                  renderItem={(education, index) => (
                    <Row
                      style={{ width: "100%", marginTop: index > 0 ? 10 : 0 }}
                    >
                      <Col
                        xs={{ span: 11, offset: 1 }}
                        sm={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11 }}
                        xl={{ span: 5 }}
                        xxl={{ span: 6 }}
                      >
                        <Row style={{ width: "100%" }}>
                          <Col span={22}>
                            <Text>{education.degree}</Text>
                          </Col>

                          <Col span={1} offset={1}>
                            <Text>|</Text>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={{ span: 11, offset: 1 }}
                        sm={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11, offset: 1 }}
                        xl={{ span: 5, offset: 1 }}
                        xxl={{ span: 8 }}
                      >
                        <Row style={{ width: "100%" }}>
                          <Col span={22}>
                            <Text style={{ fontWeight: "bold" }}>
                              {education.fieldStudy}
                            </Text>
                          </Col>
                          <Col span={1} offset={1}>
                            <Text>|</Text>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={{ span: 11, offset: 1 }}
                        sm={{ span: 11, offset: 1 }}
                        md={{ span: 11, offset: 1 }}
                        lg={{ span: 11 }}
                        xl={{ span: 7 }}
                        xxl={{ span: 7 }}
                      >
                        <Row style={{ width: "100%" }}>
                          <Col span={22}>
                            <Text>{education.institution}</Text>
                          </Col>
                          <Col span={1} offset={1}>
                            <Text>|</Text>
                          </Col>
                        </Row>
                      </Col>

                      <Col
                        xs={{ span: 4, offset: 1 }}
                        sm={{ span: 4, offset: 1 }}
                        md={{ span: 4, offset: 1 }}
                        lg={{ span: 3 }}
                        xl={{ span: 3, offset: 1 }}
                        xxl={{ span: 3 }}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Row style={{ width: "100%" }}>
                          <Col span={24}>
                            <Text>{education.dateGraduationDateOk}</Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CandidateEducationSection;
