//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  notification,
  Col,
  Layout,
} from "antd";
import "../styles.css";
import User from "../../../types/user";
import FirstStepCreationJob from "./steps/firstStep/FirstStepCreationJob";
import SecondStepCreationJob from "./steps/SecondStepCreationModal";
import ThirdStepCreationJob from "./steps/thridStep/ThirdStepCreationJob";
import { uid } from "uid";
import InitialStepCreationJob from "./steps/initialStep/InitialStepCreationModal";
import FinalStepCreationJob from "./steps/finalStep/FinalStepCreationJob";
import { useDispatch, useSelector } from "react-redux";
import { Content } from "antd/lib/layout/layout";
import { CloseOutlined } from "@ant-design/icons";
import NotificationCloseModal from "../notificationModal/NotificationCloseModal";
import NotificationUpdatePositionModal from "../notificationModal/NotificationUpdatePositionModal";
import NotificationCloseUpdatePositionModal from "../notificationModal/NotificationCloseUpdateModal";
import "./createJobModal.css";
import iconLogo from "../../../image/Logo-white.png";
import { useNavigate } from "react-router-dom";
import { getPositionSideBarSelected } from "../../../store/ui";
import {
  Timestamp,
  collection,
  collectionGroup,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/config";
import HeaderRow from "./HeaderRow";

// Interface
interface Props {
  visibleModal: boolean;
  closeModal: () => void;
  user: User;
  data?: any;
}

type NotificationType = "success" | "info" | "warning" | "error";

// Component
const notificationModal = (
  type: NotificationType,
  message: string,
  description: string
) => {
  notification[type]({
    message: message,
    description: description,
  });
};

const CreateJobModal: React.FC<Props> = ({
  user,
  visibleModal,
  closeModal,
  data,
}) => {
  // States
  const [steps, setSteps] = useState<
    "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step"
  >("Initial");
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [visibleModalConfirm, setvisibleModalConfirm] =
    useState<boolean>(false);
  const [visibleUpdatePositionModal, setVisibleUpdatePositionModal] =
    useState(false);
  const [visibleCloseUpdatePositionModal, setVisibleCloseUpdatePositionModal] =
    useState(false);
  const navigate = useNavigate();

  // States First Step
  const [titleInput, setTitleInput] = useState<string>("");
  const [jobType, setJobType] = useState<Array<string>>([]);
  const [location, setLocation] = useState<string>("");
  const [workplace, setWorkplace] = useState<Array<string>>([]);
  const [description, setDescription] = useState<string>("");
  const [checkHourly, setCheckHourly] = useState<boolean>(true);
  const [checkAnnual, setCheckAnnual] = useState<boolean>(false);
  const [checkAmount, setCheckAmount] = useState<boolean>(false);
  const [checkMin, setCheckMin] = useState<boolean>(true);
  const [checkMax, setCheckMax] = useState<boolean>(false);
  const [checkMiddle, setCheckMiddle] = useState<boolean>(false);
  const [checkBonnus, setCheckBonnus] = useState<boolean>(false);
  const [checkPercentage, setCheckPercentage] = useState<boolean>(false);
  const [salary, setSalary] = useState<string>("");
  const [salaryMax, setSalaryMax] = useState<string>("");
  const [totalSalary, setTotalSalary] = useState<string>("");
  const [places, setPlaces] = useState<any>({});
  const [otherDetailsCompensation, setOtherDetailsCompensation] =
    useState<string>("");
  const [valueHourForWeek, setValueHourForWeek] = useState<number>(40);

  const { dictionary, language } = useSelector((state: any) => state.ui);

  // States Second Step
  const [responsibilities, setResponsibilities] = useState<
    Array<{ index: number; value: string }>
  >([]);

  // States Thrid Step
  const [requirements, setRequirements] = useState<Array<any>>([]);
  const [skills, setSkills] = useState<Array<any>>([]);

  const dispatch: Dispatch<any> = useDispatch();
  //Handlers
  const resetFields = () => {
    setTitleInput("");
    setJobType([]);
    setLocation("");
    setWorkplace([]);
    setDescription("");
    setRequirements([]);
    setSkills([]);
    setSalary("");
    setSalaryMax("");
    setTotalSalary("");
    setCheckAmount(false);
    setCheckMin(true);
    setCheckMiddle(false);
    setCheckMax(false);
    setCheckAnnual(false);
    setCheckPercentage(false);
    setCheckHourly(true);
    setResponsibilities([]);
    setOtherDetailsCompensation("");
    setValueHourForWeek(40);
    setSteps("Initial");
  };

  const formatSalary = (salaryString: string): string => {
    return salaryString.replace(/,/g, '');
  };

  const handleOnSaveJob = async (status: string) => {
    const idPosition = uid(20);
    const formattedSalary = formatSalary(salary);
    const formattedSalaryMax = formatSalary(salaryMax);
    const formattedTotalSalary = formatSalary(totalSalary);
    const newJob = {
      idHiring: user.idHiring,
      idPosition: idPosition,
      name: user.name,
      titleHiring: user?.title ? user?.title : "",
      lastName: user.lastName,
      location: location,
      company: user.company,
      companyInformation: user.companyInformation,
      createAt: Timestamp.now(),
      status: status,
      email: user.email,
      title: titleInput,
      salary: formattedSalary,
      totalSalary: formattedTotalSalary,
      salaryMax: formattedSalaryMax,
      checkBonnus: checkBonnus,
      checkMax: checkMax,
      checkMiddle: checkMiddle,
      checkMin: checkMin,
      workplace,
      jobType,
      description,
      responsibilities,
      technicalRequirements: requirements,
      skills,
      places: places,
      annual: checkAnnual !== undefined ? checkAnnual : false,
      hourly: checkHourly !== undefined ? checkHourly : false,
      percentage: checkPercentage !== undefined ? checkPercentage : false,
      amount: checkAmount !== undefined ? checkAmount : false,
      language: language,
      otherDetailsCompensation: otherDetailsCompensation,
      hourForWeek: valueHourForWeek,
    };

    try {
      validateExistFieldInDatabase(titleInput);
      const positionRef = doc(
        db,
        "hirings",
        user.idHiring,
        "positions",
        idPosition
      );

      await setDoc(positionRef, newJob);
      closeModal();
      resetFields();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  const validateExistFieldInDatabase = async (value: string) => {
    try {
      const titleList: Array<string> = [];
      const recommendationsRef = collectionGroup(db, "jobTitle");
      const querySnapshot = await getDocs(recommendationsRef);

      querySnapshot.docs.forEach((each) => {
        if (each.data().title.length > 0) {
          titleList.push(each.data().title);
        }
      });
      /* await firebase.firestore().collectionGroup("jobTitle").get().then(( response )=>{
            response.docs.forEach(( each )=>{
                if(each.data().title.length > 0) { 
                    titleList.push(each.data().title)
                }
            });
        }); */

      const exist = titleList.find((a) => a === value);

      if (!exist) {
        const idTitle = uid(30);
        const jobTitleRef = doc(
          db,
          "autofill",
          "jobTitle",
          "jobTitle",
          idTitle
        );
        await setDoc(jobTitleRef, {
          function: "",
          id: idTitle,
          level: "",
          sector: "",
          title: titleInput,
          verified: false,
          language: language,
        });
        /* await firebase.firestore().collection('autofill').doc('jobTitle').collection('jobTitle').doc(idTitle).set({
              "function":"",
              "id":idTitle,
              "level":"",
              "sector":"",
              "title":titleInput,
              "verified":false,
              "language": language,
            }) */
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Controller Button Modal
  const handleOnNextStep = (
    actualStep:
      | "Initial"
      | "First Step"
      | "Second Step"
      | "Third Step"
      | "Final Step"
  ) => {
    if (actualStep === "Initial") {
      setSteps("First Step");
      setNextStep(false);
    } else if (actualStep === "First Step") {
      setSteps("Second Step");
      setNextStep(false);
    } else if (actualStep === "Second Step") {
      setSteps("Third Step");
    } else if (actualStep === "Third Step") {
      setSteps("Final Step");
    }
  };

  const handleOnBackStep = (
    actualStep:
      | "Initial"
      | "First Step"
      | "Second Step"
      | "Third Step"
      | "Final Step"
  ) => {
    if (actualStep === "First Step") {
      setSteps("Initial");
      resetFields();
    } else if (actualStep === "Second Step") {
      setSteps("First Step");
    } else if (actualStep === "Third Step") {
      setSteps("Second Step");
    } else if (actualStep === "Final Step") {
      setSteps("Third Step");
    }
  };

  // Handlers on Update status position
  const updatePositionStatus = (status: "open" | "close") => {
    if (status === "close") {
      setVisibleUpdatePositionModal(true);
    }
  };

  const handleOnChangeStatusPosition = async () => {
    try {
      const positionRef = doc(
        db,
        "hirings",
        user.idHiring,
        "positions",
        data?.idPosition
      );

      await updateDoc(positionRef, { status: "close" });
      resetFields();
      setVisibleUpdatePositionModal(false);
      closeModal();

      const positionsRef = collection(
        db,
        "hirings",
        user.idHiring,
        "positions"
      );

      // Create a query against the collection with the condition
      const q = query(positionsRef, where("status", "==", "open"), limit(1));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.docs.length > 0) {
        querySnapshot.docs.forEach((doc) => {
          // Add the document data to an array
          /* positions.push({ id: doc.id, ...doc.data() }); */
          dispatch(getPositionSideBarSelected(doc.data()));
          notificationModal(
            "success",
            "Job close successfully",
            "The Position " +
            doc.data().title +
            " has been successfully closed."
          );
        });
      } else {
        navigate("/dash");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnCloseModal = () => {
    if (data !== undefined) {
      setVisibleCloseUpdatePositionModal(true);
    } else {
      setvisibleModalConfirm(true);
    }
  };

  // Effects
  useEffect(() => {
    if (steps === "First Step") {
      if (
        titleInput?.length > 0 &&
        jobType?.length > 0 &&
        location?.length > 0 &&
        workplace?.length > 0 &&
        salary?.length > 0 &&
        salaryMax?.length > 0 &&
        parseFloat(salaryMax.replace(/,/g, "")) >
        parseFloat(salary.replace(/,/g, ""))
      ) {
        if (totalSalary?.length > 0) {
          if (checkAmount === false && checkPercentage === false) {
            setNextStep(true);
          } else if (checkAmount === true || checkPercentage === true) {
            setNextStep(false);
          }
        } else setNextStep(false);

        /* setNextStep(false); */
      } else setNextStep(true);
    } else if (steps === "Second Step") {
      description.length > 0 && responsibilities.length > 0
        ? setNextStep(false)
        : setNextStep(true);
    } else if (steps === "Third Step") {
      requirements.length > 0 && skills.length === 5
        ? setNextStep(false)
        : setNextStep(true);
    }
  }, [
    steps,
    titleInput?.length,
    jobType?.length,
    location,
    workplace,
    description?.length,
    responsibilities?.length,
    requirements?.length,
    skills?.length,
    salary?.length,
    totalSalary?.length,
    checkAmount,
    checkPercentage,
    salaryMax?.length,
    salary,
    salaryMax,
  ]);

  const handleOnUpdatePositionAfterClose = async (idPosition: string) => {
    try {
      const updateJob = {
        location: location,
        title: titleInput,
        salary: salary,
        totalSalary: totalSalary,
        workplace,
        jobType,
        description,
        responsibilities,
        technicalRequirements: requirements,
        skills,
        annual: checkAnnual,
        hourly: checkHourly,
        percentage: checkPercentage,
        amount: checkAmount,
        salaryMax: salaryMax,
        checkBonnus: checkBonnus,
        checkMax: checkMax,
        checkMiddle: checkMiddle,
        checkMin: checkMin,
        otherDetailsCompensation: otherDetailsCompensation,
        hourForWeek: valueHourForWeek,
      };

      const positionRef = doc(
        db,
        "hirings",
        user?.idHiring,
        "positions",
        idPosition
      );

      await updateDoc(positionRef, updateJob);

      resetFields();
      setVisibleCloseUpdatePositionModal(false);
      closeModal();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnUpdatePositionData = async (idPosition: string) => {
    try {
      const updateJob = {
        title: titleInput,
        salary: salary,
        totalSalary: totalSalary,
        location,
        workplace,
        jobType,
        description,
        responsibilities,
        technicalRequirements: requirements,
        skills,
        annual: checkAnnual,
        hourly: checkHourly,
        salaryMax: salaryMax,
        checkBonnus: checkBonnus,
        checkMax: checkMax,
        checkMiddle: checkMiddle,
        checkMin: checkMin,
        percentage: checkPercentage,
        amount: checkAmount,
        otherDetailsCompensation: otherDetailsCompensation,
        hourForWeek: valueHourForWeek,
      };

      const positionRef = doc(
        db,
        "hirings",
        user?.idHiring,
        "positions",
        idPosition
      );

      await updateDoc(positionRef, updateJob);

      closeModal();
      resetFields();
      notificationModal("success", dictionary["alertMessage-text-title2"], "");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Actualizar informacion de un puesto de trabajo
    if (data !== undefined) {
      setSteps("First Step");
      setSalary(data?.salary ? data?.salary : "");
      setSalaryMax(data?.salaryMax ? data?.salaryMax : "");
      setCheckMin(data?.checkMin !== undefined ? data?.checkMin : false);
      setCheckMiddle(
        data?.checkMiddle !== undefined ? data?.checkMiddle : false
      );
      setCheckMax(data?.checkMax !== undefined ? data?.checkMax : false);
      setTitleInput(data?.title ? data?.title : "");
      setCheckAmount(data?.amount !== undefined ? data?.amount : false);
      setCheckAnnual(data?.annual !== undefined ? data?.annual : false);
      setCheckPercentage(
        data?.percentage !== undefined ? data?.percentage : false
      );
      setCheckHourly(data?.hourly !== undefined ? data?.hourly : false);
      setLocation(data?.location ? data?.location : "");
      setTotalSalary(data?.totalSalary ? data?.totalSalary : "");
      setJobType(data?.jobType ? data?.jobType : []);
      setWorkplace(data?.workplace ? data?.workplace : []);
      setDescription(data?.description ? data?.description : "");
      setResponsibilities(data?.responsibilities ? data?.responsibilities : []);
      setRequirements(
        data?.technicalRequirements ? data?.technicalRequirements : []
      );
      setSkills(data?.skills ? data?.skills : []);
      setUpdate(true);
      setOtherDetailsCompensation(
        data?.otherDetailsCompensation ? data?.otherDetailsCompensation : ""
      );
      setValueHourForWeek(data?.hourForWeek ? data?.hourForWeek : 40);
    }
  }, [data]);

  return (
    <Modal
      open={visibleModal}
      afterClose={() => {
        setSteps("First Step");
        resetFields();
      }}
      centered
      width="80%"
      closable={false}
      destroyOnClose={true}
      onCancel={handleOnCloseModal}
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row style={{ width: "100%" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              {steps === "Initial" ? null : steps === "First Step" &&
                data !== undefined ? (
                <div style={{ width: 100 }} />
              ) : (
                <Button
                  style={{
                    marginTop: 5,
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 0,
                    minWidth: 150,
                    background:
                      "linear-gradient(90deg, rgba(226,184,66,1) 0%, rgba(253,134,71,1) 100%)",
                    color: "white",
                  }}
                  onClick={() => handleOnBackStep(steps)}
                >
                  {dictionary["creation-modal-button-previous"]}
                </Button>
              )}
            </Col>

            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              xxl={8}
            >
              {steps !== "Initial" && data !== undefined ? (
                <Button
                  style={{
                    marginTop: 5,
                    borderBottomRightRadius: 20,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 0,
                    minWidth: 150,
                    background:
                      "linear-gradient(90deg, rgba(205,108,243,1) 0%, rgba(161,107,245,1) 100%)",
                    color: "white",
                  }}
                  onClick={() => handleOnUpdatePositionData(data?.idPosition)}
                >
                  {dictionary["editing-modal-button-save-changes"]}
                </Button>
              ) : null}
            </Col>

            <Col style={{ display: "flex", justifyContent: "center", alignItems: "center", }} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              {steps !== "Initial" ? (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    disabled={nextStep}
                    onClick={() =>
                      steps === "Final Step"
                        ? handleOnSaveJob('open')
                        : handleOnNextStep(steps)
                    }
                    style={{
                      marginTop: 5,
                      borderBottomRightRadius: 20,
                      borderTopLeftRadius: 20,
                      borderBottomLeftRadius: 0,
                      borderTopRightRadius: 0,
                      minWidth: 150,
                      background: nextStep
                        ? "#C5C5C5"
                        : "linear-gradient(90deg, rgba(196,196,78,1) 0%, rgba(157,196,81,1) 100%)",
                      color: nextStep ? "#707070" : "white",
                    }}
                  >
                    {steps === "First Step" || steps === "Second Step"
                      ? dictionary["creation-modal-button-next"]
                      : steps === "Third Step"
                        ? dictionary["creation-modal-button-next"]
                        : steps === "Final Step" && data === undefined
                          ? dictionary["creation-modal-preview-button-publish"]
                          : null}
                  </Button>
                  {/* {
                    steps === 'Final Step' && (
                      <Button
                        onClick={() =>
                          handleOnSaveJob('market')
                        }
                        style={{
                          marginTop: 5,
                          borderBottomRightRadius: 20,
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 0,
                          borderTopRightRadius: 0,
                          minWidth: 150,
                          background: nextStep
                            ? "#C5C5C5"
                            : "linear-gradient(90deg, rgba(196,196,78,1) 0%, rgba(157,196,81,1) 100%)",
                          color: nextStep ? "#707070" : "white",
                        }}
                      >
                        {dictionary["creation-modal-preview-button-publish"]} as 'Market'

                      </Button>

                    )
                  } */}
                </div>
              ) : null}
            </Col>
          </Row>
        </Row>
      }
    >
      <Layout style={{ height: "80vh", backgroundColor: "white" }}>
        <Row
          style={{
            width: "100%",
            height: "8%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            padding: "1%",
            borderBottom: "solid 1px #414193",
          }}
        >
          <Col
            xs={20}
            sm={22}
            md={22}
            lg={23}
            xl={23}
            xxl={23}
            style={{ height: "100%" }}
          >
            <HeaderRow data={data} dictionary={dictionary} iconLogo={iconLogo} steps={steps} />
          </Col>

          <Col
            xs={4}
            sm={2}
            md={2}
            lg={1}
            xl={1}
            xxl={1}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <Button
              shape="circle"
              size="small"
              style={{
                color: "white",
                backgroundColor: "red",
                border: "transparent",
              }}
              icon={<CloseOutlined />}
              onClick={() => {
                if (steps === "Initial") {
                  closeModal();
                  resetFields();
                } else {
                  handleOnCloseModal();
                }
              }}
            />
          </Col>
        </Row>
        <Content style={{ height: "80%", width: "100%", overflow: "auto" }}>
          {steps === "Initial" ? (
            <InitialStepCreationJob
              setTitle={(title: string) => setTitleInput(title)}
              setType={(typeList: Array<string>) => setJobType(typeList)}
              setLocation={(value: string) => {
                setLocation(value);
              }}
              setWorkplace={(workplaceData: Array<string>) =>
                setWorkplace(workplaceData)
              }
              setSalary={(salary: string) => setSalary(salary)}
              setSalaryMax={(salaryMax: string) => setSalaryMax(salaryMax)}
              setTotalSalary={setTotalSalary}
              setDescription={(value: string) => setDescription(value)}
              setResponsibilities={(value: Array<any>) =>
                setResponsibilities(value)
              }
              setRequirements={(value: Array<any>) => setRequirements(value)}
              setSkills={(value: Array<any>) => setSkills(value)}
              handleOnNextStep={handleOnNextStep}
              checkHourly={checkHourly}
              setCheckHourly={setCheckHourly}
              checkAmount={checkAmount}
              setCheckAmount={setCheckAmount}
              checkAnnual={checkAnnual}
              setCheckAnnual={setCheckAnnual}
              checkPercentage={checkPercentage}
              setCheckPercentage={setCheckPercentage}
              setOtherDetailsCompensation={setOtherDetailsCompensation}
              otherDetailsCompensation={otherDetailsCompensation}
              valueHourForWeek={valueHourForWeek}
              setValueHourForWeek={setValueHourForWeek}
            />
          ) : steps === "First Step" ? (
            <FirstStepCreationJob
              update={update}
              setPlaces={setPlaces}
              titleInput={titleInput}
              setTitle={(title: string) => setTitleInput(title)}
              type={jobType}
              setType={(typeList: Array<string>) => setJobType(typeList)}
              location={location}
              setLocation={(value: string) => {
                setLocation(value);
              }}
              workplace={workplace}
              setWorkplace={(workplaceData: Array<string>) =>
                setWorkplace(workplaceData)
              }
              setSalary={(salary: any) => setSalary(salary)}
              salary={salary}
              setSalaryMax={(salaryMax: any) => setSalaryMax(salaryMax)}
              salaryMax={salaryMax}
              checkHourly={checkHourly}
              setCheckHourly={setCheckHourly}
              checkMin={checkMin}
              setCheckMin={setCheckMin}
              checkMax={checkMax}
              setCheckMax={setCheckMax}
              checkMiddle={checkMiddle}
              setCheckMiddle={setCheckMiddle}
              checkBonnus={checkBonnus}
              setCheckBonnus={setCheckBonnus}
              checkAmount={checkAmount}
              setCheckAmount={setCheckAmount}
              checkAnnual={checkAnnual}
              setCheckAnnual={setCheckAnnual}
              checkPercentage={checkPercentage}
              setCheckPercentage={setCheckPercentage}
              totalSalary={totalSalary}
              setTotalSalary={setTotalSalary}
              updatePositionStatus={updatePositionStatus}
              otherDetailsCompensation={otherDetailsCompensation}
              setOtherDetailsCompensation={setOtherDetailsCompensation}
              setValueHourForWeek={setValueHourForWeek}
              valueHourForWeek={valueHourForWeek}
            />
          ) : steps === "Second Step" ? (
            <SecondStepCreationJob
              description={description}
              setDescription={(value: string) => setDescription(value)}
              isEditing={false}
              responsibilities={responsibilities}
              setResponsibilities={(value: Array<any>) =>
                setResponsibilities(value)
              }
            />
          ) : steps === "Third Step" ? (
            <ThirdStepCreationJob
              requirements={requirements}
              setRequirements={(value: Array<any>) => setRequirements(value)}
              isEditing={false}
              skills={skills}
              setSkills={(value: Array<any>) => setSkills(value)}
            />
          ) : (
            steps === "Final Step" && (
              <FinalStepCreationJob
                titleInput={titleInput}
                description={description}
                jobType={jobType}
                location={location}
                requirements={requirements}
                responsibilities={responsibilities}
                salary={salary}
                skills={skills}
                workplace={workplace}
                totalSalary={totalSalary}
                annualOrHourly={checkAnnual}
                amountCheck={checkAmount}
                annualCheck={checkAnnual}
                hourlyCheck={checkHourly}
                checkMin={checkMin}
                checkMax={checkMax}
                checkMiddle={checkMiddle}
                salaryMax={salaryMax}
                percentageCheck={checkPercentage}
                otherDetailsCompensation={otherDetailsCompensation}
                valueHourForWeek={valueHourForWeek}
              />
            )
          )}

          <NotificationCloseModal
            visible={visibleModalConfirm}
            closeModal={() => setvisibleModalConfirm(false)}
            closeCreateJobModal={() => closeModal()}
          />

          <NotificationUpdatePositionModal
            visible={visibleUpdatePositionModal}
            updateJobPositionStatus={() => handleOnChangeStatusPosition()}
            closeModal={async () => {
              await setVisibleUpdatePositionModal(false);
              closeModal();
              resetFields();
            }}
          />

          <NotificationCloseUpdatePositionModal
            onCancel={async () => {
              setVisibleCloseUpdatePositionModal(false);
            }}
            onClickDontSaveButton={async () => {
              await setVisibleCloseUpdatePositionModal(false);
              closeModal();
              resetFields();
            }}
            visible={visibleCloseUpdatePositionModal}
            onClickSaveButton={() =>
              handleOnUpdatePositionAfterClose(data?.idPosition)
            }
          />
        </Content>
      </Layout>
    </Modal>
  );
};

// Exports
export default CreateJobModal;
