//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Typography } from "antd";
import { useSelector } from "react-redux";

// Component
const MessagesPending: React.FC = () => {
  // States
  const counter = useSelector((state: any) => state.chat.counterMessages);

  const { Text } = Typography;
  return (
    <Text style={{ fontSize: 18, fontFamily: 'Muli' }}>{counter}</Text>
  );
};

// Export
export default MessagesPending;
