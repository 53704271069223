import React, { Dispatch, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../firebase/config";
import { Col, Layout, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import NavBar from "../common/NavBar";
import RecommendationScreen from "../pages/RecommendationScreen";
import SupportScreen from "../pages/SupportScreen";
import PeerRecommendationScreen from "../pages/PeerRecommendationScreen";
import JobsListScreen from "../pages/JobsListScreen";
import ProfileScreen from "../pages/ProfileScreen";
import ChatScreen from "../pages/ChatScreen";
import Menu from "../common/SideBar/Menu";
import WelcomeModal from "../common/modals/welcomeModal/WelcomeModal";
import CreateJobModal from "../common/modals/createJobModal/CreateJobModal";
import HomeScreen from "../pages/HomeScreen";
import UpdateUserInfoModal from "../common/modals/updateProfileModal/UpdateUserInfoModal";
import "./scroll.css"; // Ruta relativa al archivo CSS
import LogoutInactiveModal from "../common/modals/LogoutInactiveModal";
import { logoutFirebase } from "../firebase/providers";
import { logout } from "../store/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { StateGeneralRedux } from "../types/redux";
import User from "../types/user";
import ChangePasswordPage from "../pages/changePasswordPage/ChangePasswordPage";
// Component
const JobManagerRoutes = () => {
  // States
  const dispatch: Dispatch<any> = useDispatch();
  const { user } = useSelector((state: StateGeneralRedux) => state.auth);

  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [visibleWelcomeModal, setVisibleWelcomeModal] = useState<boolean>(false);
  const [visibleCreateJobModal, setVisibleCreateJobModal] = useState<boolean>(false);
  const [visibleUpdateProfileModal, setVisibleUpdateProfileModal] = useState<boolean>(false);
  const [visibleLogoutInactiveModal, setVisibleLogoutInactiveModal] = useState<boolean>(false);
  const [timeInSeconds, setTimeInSeconds] = useState<number>(0);

  const saveTimeInSeconds = () => {
    const currentTime = new Date();
    const seconds =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds();
    localStorage.setItem("timeInSeconds", seconds.toString());
    setTimeInSeconds(seconds);
  };

  const saveTimeInSecondsActually = () => {
    const currentTime = new Date();
    const seconds =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds();
    return seconds;
  };

  // Effects
  useEffect(() => {
    // Valida si user es null y detiene la ejecución del efecto si lo es
    if (!user) return;

    const unsubscribe = onSnapshot(doc(db, "hirings", user.idHiring), (hiringDocSnap) => {
      if (hiringDocSnap.exists()) {
        const hiringData = hiringDocSnap.data() as User;
        console.log("ID Company", hiringData.idCompanyQR)
        if (!hiringData.firstLogin) {
          setIsFirstLogin(false);
          setVisibleUpdateProfileModal(false);
        } else {
          setIsFirstLogin(true);
          setVisibleUpdateProfileModal(true);
        }
      }
    }
    );

    // Función de limpieza que se llama cuando el componente se desmonta o cuando `user` cambia
    return () => unsubscribe();
  }, [user]);

  // Effects
  useEffect(() => {
    let inactivityTimer: any;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleInactive, 20 * 60 * 1000); // 15 minutes
    };

    const handleInactive = () => {
      !visibleLogoutInactiveModal && saveTimeInSeconds();

      setVisibleLogoutInactiveModal(true);
      clearTimeout(inactivityTimer);
    };

    const handleUserActivity = () => {
      if (!visibleLogoutInactiveModal) {
        localStorage.setItem("timeInSeconds", "0");
        resetTimer();
      }
    };

    // Agregar eventos relevantes
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    // Establecer el temporizador inicial
    resetTimer();

    // Limpiar los eventos al desmontar el componente
    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("scroll", handleUserActivity);
    };
  }, [dispatch, visibleLogoutInactiveModal]);

  useEffect(() => {
    // Get the value from localStorage when the component mounts
    const storedTimeInSeconds: any = localStorage.getItem("timeInSeconds");

    // Convert the stored value to a number and update the state
    const valueStorageUserInactive = parseInt(storedTimeInSeconds, 10) || 0;
    if (valueStorageUserInactive > 0) {
      const valueActuallyUserTime = saveTimeInSecondsActually();
      console.log("analizar para ver si se cierra la sesion");
      const result = valueActuallyUserTime - valueStorageUserInactive;
      if (result > 180) {
        localStorage.setItem("timeInSeconds", "0");
        dispatch(logout());
        logoutFirebase();
      }
    }
  }, [dispatch]);

  return (
    <>
      {user?.forceChangePassword ? (
        <Routes>
          <Route
            path="/"
            element={<ChangePasswordPage setChangePassword={setChangePassword} />}
          />
        </Routes>
      ) : (
        <Content style={{ height: "100%", width: "100%", overflow: "auto" }}>
          {visibleLogoutInactiveModal && (
            <LogoutInactiveModal
              visible={visibleLogoutInactiveModal}
              setVisible={(value: boolean) =>
                setVisibleLogoutInactiveModal(value)
              }
              timeInactiveUser={timeInSeconds}
            />
          )}
          <Row style={{ height: "100%", width: "100%" }}>
            <Row style={{ height: "7%", width: "100%" }}>
              <NavBar />
            </Row>

            {isFirstLogin ? (
              <UpdateUserInfoModal
                modalUser={visibleUpdateProfileModal}
                setModalUser={setVisibleUpdateProfileModal}
                setVisibleWelcomeModal={setVisibleWelcomeModal}
              />
            ) : (
              <Row
                style={{
                  height: "93%",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <WelcomeModal
                  visibleModal={visibleWelcomeModal}
                  setVisibleModal={setVisibleWelcomeModal}
                  setVisibleCreateJobModal={setVisibleCreateJobModal}
                />
                {
                  user && (
                    <CreateJobModal
                      closeModal={() => setVisibleCreateJobModal(false)}
                      user={user}
                      visibleModal={visibleCreateJobModal}
                    />
                  )
                }
                <Col
                  style={{ height: "100%", paddingLeft: 1 }}
                  xs={{ span: 7 }}
                  sm={{ span: 6 }}
                  md={{ span: 6 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                  xxl={{ span: 3 }}
                >
                  <Menu />
                </Col>

                <Col
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                  xs={17}
                  sm={18}
                  md={18}
                  lg={21}
                  xl={21}
                  xxl={21}
                >
                  <Content style={{ height: "100%", width: "100%" }}>
                    <Layout
                      style={{ height: "100%", backgroundColor: "white" }}
                    >
                      <Routes>
                        <Route
                          path="/dash"
                          element={
                            <HomeScreen changePassword={changePassword} />
                          }
                        />
                        <Route
                          path="/recommendation"
                          element={<RecommendationScreen />}
                        />
                        <Route path="/support" element={<SupportScreen />} />
                        <Route path="/profile" element={<ProfileScreen />} />
                        <Route path="/jobList" element={<JobsListScreen />} />
                        <Route
                          path="/peerRecommendation"
                          element={<PeerRecommendationScreen />}
                        />
                        <Route path="/messages" element={<ChatScreen />} />
                        <Route path="/" element={<Navigate to="/dash" />} />
                      </Routes>
                    </Layout>
                  </Content>
                </Col>
              </Row>
            )}
          </Row>
        </Content>
      )}
    </>
  );
};

export default JobManagerRoutes;
