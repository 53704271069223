import { Col, Row, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Candidate } from "../../../../types/candidate";
import IconRatingSkill from "../../../../common/iconRating";
import icoSkills from "../../../../image/ico_skills.png";
const { Text } = Typography;

// interface
interface Props {
  candidate: Candidate;
  skillList: Array<any>;
}
const CandidateSkillSection: React.FC<Props> = ({ candidate, skillList }) => {
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <Row style={{ width: "100%", marginBottom: "10%" }}>
      <Col span={23} offset={1}>
        <Row style={{ width: "100%", marginBottom: "3%" }}>
          <Col
            xs={{ span: 3 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            xl={{ span: 3 }}
            xxl={{ span: 2 }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={icoSkills}
              style={{ height: 23, width: 20, margin: 0 }}
              alt={icoSkills}
            />
          </Col>

          <Col
            xs={{ span: 21 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            xl={{ span: 21 }}
            xxl={{ span: 22 }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Text style={{ color: "#129BD4", fontSize: 20 }}>
              {dictionary["job-candidate-detail-title-skills"]}
            </Text>
          </Col>
        </Row>

        <Row justify="start" style={{ width: "100%" }}>
          {skillList.map((skill, index) => (
            <Row
              key={skill.level + index}
              style={{ width: "80%", marginTop: index > 0 ? 10 : 0 }}
            >
              <Col
                xs={{ span: 11 }}
                sm={{ span: 13 }}
                md={{ span: 13 }}
                lg={{ span: 10 }}
                xl={{ span: 10 }}
                xxl={{ span: 8 }}
              >
                <Text>{skill.name}</Text>
              </Col>
              <Col
                sm={{ span: 12, offset: 1 }}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 14, offset: 1 }}
                xl={{ span: 13, offset: 1 }}
                xxl={{ span: 11, offset: 1 }}
              >
                <IconRatingSkill rating={skill.level} />
              </Col>
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CandidateSkillSection;
