//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Row, Tooltip, Typography } from "antd";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "../../types/position";
import AlertMessageBadge from "./AlertMessageBadge";
import { changeLoadingChat, clearFilterJob, clearMessageList, clearPositionSelect, clearUserChatSelected, getCandidateListByJob, getFilterJob, getPositionSelect } from "../../store/chat";
import { startGetCandidateListByJob } from "../../hooks/hooks";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
// Interface
interface Props {
  position: Job;
}

// Component Design
const { Text } = Typography;
// Component
const PositionCard: React.FC<Props> = ({ position }) => {

  const dispatch: Dispatch<any> = useDispatch();
  // States
  const filterJob = useSelector((state: any) => state.chat.filterJob);
  const positionSelected = useSelector((state: any) => state.chat.positionSelected);
  const user = useSelector((state: any) => state.auth.user);

  const handleOnSelectJob = async (position: Job) => {
    if (filterJob === 'Inbox') {
      dispatch(clearFilterJob());
      dispatch(clearMessageList());
      dispatch(clearUserChatSelected());
    }

    filterJob !== "MessageByJob" && dispatch(getFilterJob("MessageByJob"));

    if (position.idPosition !== positionSelected.idPosition) {
      dispatch(changeLoadingChat(true));
      dispatch(clearUserChatSelected());
      dispatch(
        getPositionSelect({
          idHiring: position.idHiring,
          idPosition: position.idPosition,
          status: position.status,
          title: position.title,
        })
      );

      const responseCandidateList = await startGetCandidateListByJob(position.idPosition, user?.idHiring);
      const list = await getStatusByCandidate(responseCandidateList, position.idPosition);
      if (responseCandidateList !== null) {
        dispatch(getCandidateListByJob(list))
      }
      dispatch(changeLoadingChat(false));
    } else {
      if (filterJob === "MessageByJob") {
        dispatch(clearFilterJob());
        dispatch(clearMessageList());
      }
      dispatch(clearPositionSelect());
      dispatch(clearUserChatSelected());
    }
  }

  const getStatusByCandidate = async (list: Array<any>, idPosition: string) => {
    try {
      const listNew: Array<any> = [];
      for (const candidate of list) {
        // Reference to the specific document in the "recommendationCandidates" subcollection
        const candidateDocRef = doc(db, `hirings/${user?.idHiring}/positions/${idPosition}/recommendationCandidates`, candidate.id);

        const response = await getDoc(candidateDocRef);
        if (response.exists()) {
          listNew.push({ ...candidate, status: response.data()?.status });
        }
      }
      return listNew;
    } catch (error) {
      console.error("Error fetching status by candidate:", error);
      return [];
    }
  };

  return (
    <Row
      style={{
        width: "100%",
        padding: 5,
        paddingLeft: 18,
        cursor: "pointer",
        backgroundColor:
          position.idPosition === positionSelected.idPosition
            ? "#24CECE"
            : "transparent",
        fontWeight: position.idPosition === positionSelected.idPosition ? 'bold' : 'initial',
        borderRadius: 5,
      }}
      onClick={() => handleOnSelectJob(position)}
    >
      <Col xs={21} sm={21} md={20} lg={21} xxl={22} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Tooltip title={position.title}>
          <Text style={{ fontSize: 16, fontFamily: 'Muli', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{position.title}</Text>
        </Tooltip>
      </Col>
      <Col xs={3} sm={3} md={4} lg={3} xxl={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <AlertMessageBadge position={position} />
      </Col>
    </Row>
  );
};
// Export
export default PositionCard;
