//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Upload, notification } from "antd";
import React, { useState } from "react";

// Interface
interface Props {
  avatar: string;
  setAvatar: (avatar: string) => void;
  title: string;
}

// Component
const UploadAvatar: React.FC<Props> = ({ avatar, setAvatar, title }) => {
  // State
  const [error, setError] = useState<boolean>(false);
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    !isJpgOrPng && setError(true);
    !isJpgOrPng && notificationModal('error', 'JPG/PNG File!', 'You can only upload JPG/PNG file!')

    const isLt2M = file.size / 4000 / 4000 < 2;
    !isLt2M && setError(true);
    !isLt2M && notificationModal('error', '4MB!', 'Image must smaller than 4MB!')

    return isJpgOrPng && isLt2M;
  };

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChangeAvatarPersonal = (info: any) => {
    if (!error) {
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        loadImage(imageUrl);
      });
    }
  };

  const loadImage = (imageUrl: string) => {
    setAvatar(imageUrl);
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const notificationModal = (type: NotificationType, message: string, description: string) => {
    notification[type]({
      message: message,
      description: description,
    });
  };
  return (

    <Upload
      name="avatarPersonalInformation"
      beforeUpload={beforeUpload}
      onChange={handleChangeAvatarPersonal}
      showUploadList={false}

    >

      {
        avatar === "" ? <div style={{ paddingLeft: 10, paddingRight: 10, borderRadius: 10, border: '1px solid', borderColor: '#9c9c9c', cursor: "pointer", height: 180, width: 210, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h4 style={{ color: "#999", textAlign: "center" }}>{title}</h4>  </div> : <img src={avatar} style={{ borderRadius: 10, border: '1px solid', borderColor: '#9c9c9c', height: 120, width: "100%" }} alt='AvatarPersonal.png' />
      }

    </Upload>


  );
};

// Export
export default UploadAvatar;