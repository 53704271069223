// Dependencies
import { Spin, Typography } from "antd";
import React, { useState, useEffect } from "react";

// Component design
const { Text } = Typography;

// Interface
interface Props {
  title?: string;
}
// Component
const Spinner: React.FC<Props> = ({ title = "Loading for" }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []); // Empty dependency array to run effect only once on component mount

  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "50%",
          height: "10%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" style={{ height: 140 }} tip="Loading" />
        <Text style={{ color: "#00000060" }}>
          {title} {seconds} seconds
        </Text>
      </div>
    </div>
  );
};

// Exports
export default Spinner;
