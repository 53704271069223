// Dependencies
import { createSlice } from '@reduxjs/toolkit';

const storedUserData = localStorage.getItem('userData');


export const authSlice = createSlice({
    name:'auth',
    initialState:{
        user: storedUserData ? JSON.parse(storedUserData) : null,
        error: null,
        loading: false,
        token: null,
    },
    reducers:{
        login:( state, action ) => {
            state.user = action.payload;
            state.loading=false;
        },
        logout:( state ) => {
            state.user = null;
            state.error = null;
            state.loading = false;
            state.token = null;
        },
        checkingCredentials: ( state,action ) => {
            state.loading = action.payload;
        },
        getToken: ( state, action ) => {
            state.token = action.payload;
        },
        getError: (state, action ) =>{
            state.error =  action.payload;
            state.loading = false;
        },
        clearError:(state)=>{
            state.error=null;
        },
        updateUser:(state,action) => {
            state.user=action.payload
        }
    }
});

export const { login, logout, checkingCredentials,getError,getToken,getVerificationMFA,cleanVerificationMFA,clearError,updateUser } = authSlice.actions;