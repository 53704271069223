//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import React from "react";

import Home from "../components/home/Home";
import { Layout } from "antd";

interface Props {
  changePassword: Boolean;
}

// Component
const HomeScreen: React.FC<Props> = ({ changePassword }) => {
  return (
    <Layout style={{ height: "100%", width: "100%", backgroundColor: '#00000004' }}>
      <Home changePassword={changePassword} />
    </Layout>
  );
};

// Exports
export default HomeScreen;
