import { getRecommendationCandidate } from "../endpoints/hiringEndPoint";

export const startGetLatestStatusCandidateAllJobs = async (positionList: any, idCandidate: string, idHiring: string) => {
    let status = "";
    let counter = 0;
    
    try {
        for (const position of positionList) {
            const data = await getRecommendationCandidate(idHiring,position.idPosition,idCandidate);
        
            if (data?.status === "waiting for reply") {
                if (counter <= 0) {
                status = "Waiting for reply";
                counter = 0;
                }
            } else if (data?.status === "interested") {
                if (counter < 1) {
                counter = 1;
                status = "interested";
                }
            } else if (data?.status === "interview") {
                if (counter < 2) {
                counter = 2;
                status = "interview";
                }
            } else if (data?.status === "meeting") {
                if (counter < 3) {
                counter = 3;
                status = "meeting";
                }
            } else if (data?.status === "offer") {
                if (counter < 4) {
                counter = 4;
                status = "offer";
                }
            } else if (data?.status === "hired") {
                if (counter < 5) {
                counter = 5;
                status = "hired";
                }
            }
            }
        return status
    } catch (error) {
        return null
    }
};
