import React, { useState } from 'react'
import iconBtnDecline from "../../image/sourcing/btn-decline.png";
import iconBtnDeclineFr from "../../image/btn-decline-fr.png";
import { Job } from '../../types/position';
import { PreCandidateInterface } from '../../models/preCandidate.interface';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import User from '../../types/user';
import { updateRecommendationPreCandidateDoc } from '../../firebase/controllers/hiring.services.firebase';
import NotificationInteractionSourcing from '../modals/notificationModal/NotificationInteractionSourcing';

interface Props {
    position: Job;
    preCandidate: PreCandidateInterface;
    user: User;
    loading: boolean;
    setLoading: (value: boolean) => void;
}
const ButtonDecline: React.FC<Props> = ({ position, preCandidate, user, loading, setLoading }) => {

    // States
    const { language } = useSelector((state: any) => state.ui);
    const [visibleModalNotification, setVisibleModalNotification] = useState<boolean>(false);

    // Handlers
    const handleOnDislikeCandidate = async (position: Job) => {
        try {
            setLoading(true);

            const { success } = await updateRecommendationPreCandidateDoc(user.idHiring, position.idPosition, preCandidate.idPreCandidate, {
                hiringLike: false,
                candidateStatus: 'declined',
            });

            if (success) {
                setLoading(false)
                setVisibleModalNotification(true);
            }
        } catch (error) {
            console.log("ERROR: handleOnDislikeCandidate", error);
        }
    };
    return (
        <>
            <Button
                disabled={loading}
                onClick={() => handleOnDislikeCandidate(position)}
                style={{
                    borderColor: "transparent",
                    height: 50,
                    width: 150,
                    opacity: loading ? 0.4 : 1,
                }}
                icon={
                    <img
                        src={
                            language === "English" ? iconBtnDecline : iconBtnDeclineFr
                        }
                        style={{ height: 80, width: 180, marginTop: -10 }}
                        alt={iconBtnDeclineFr}
                    />
                }
            />
            <NotificationInteractionSourcing
                visible={visibleModalNotification}
                closeModal={() => {
                    setVisibleModalNotification(false);

                }}
                type={'dislike'}
            />
        </>
    )
}

export default ButtonDecline
