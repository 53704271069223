import { Button, Col, List, Row, Typography } from "antd";
import React, { useState } from "react";
import icoWork from "../../../../image/ico-work.png";
import iconDocument from "../../../../image/ico-message-attach.png";
import { useSelector } from "react-redux";
import { Candidate } from "../../../../types/candidate";
import PreviewPDFModal from "../../../../common/modals/PreviewPDFModal";
const { Text } = Typography;

// interface
interface Props {
  candidate: Candidate;
  experienceList: Array<any>;
}
const CandidateExperienceSection: React.FC<Props> = ({
  candidate,
  experienceList,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);
  const [openPdf, setOpenPdf] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        paddingBottom: "6%",
      }}
    >
      <PreviewPDFModal
        candidate={candidate}
        openPDF={openPdf}
        setOpenPDF={setOpenPdf}
      />
      <Col span={23} offset={1}>
        <div>
          <Row style={{ width: "100%", marginBottom: "2%" }}>
            <Col
              xs={{ span: 3 }}
              sm={{ span: 2 }}
              md={{ span: 2 }}
              xl={{ span: 3 }}
              xxl={{ span: 2 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={icoWork}
                style={{ height: 20, width: 20, margin: 0 }}
                alt={icoWork}
              />
            </Col>

            <Col
              xs={{ span: 19 }}
              sm={{ span: 20 }}
              md={{ span: 20 }}
              xl={{ span: 19 }}
              xxl={{ span: 20 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Text style={{ color: "#129BD4", fontSize: 20 }}>
                {dictionary["job-candidate-detail-title-experience"]}
              </Text>
            </Col>

            <Col
              xs={{ span: 1, offset: 1 }}
              sm={{ span: 1 }}
              md={{ span: 1 }}
              xl={{ span: 1 }}
              xxl={{ span: 1 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {candidate.urlDocument ? (

                <Button
                  style={{ border: "transparent" }}
                  icon={
                    <img
                      src={iconDocument}
                      style={{ height: 30, width: "auto" }}
                      alt="document"
                    />
                  }
                  onClick={() => setOpenPdf(true)}
                />
              ) : candidate.urlDocumentProfile && (
                <Button
                  style={{ border: "transparent" }}
                  icon={
                    <img
                      src={iconDocument}
                      style={{ height: 30, width: "auto" }}
                      alt="document"
                    />
                  }
                  onClick={() => setOpenPdf(true)}
                />
              )}
            </Col>
          </Row>

          <Row style={{ width: "100%" }}>
            {experienceList.length > 0 && (
              <List
                dataSource={experienceList}
                style={{ width: "100%", height: "100%" }}
                renderItem={(experience, index) => (
                  <div style={{ marginTop: index > 0 ? 8 : 0 }}>
                    <Row
                      style={{
                        width: "100%",
                        marginBottom: "1%",
                        display: "flex",
                      }}
                    >
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 18 }}
                        md={{ span: 18 }}
                        lg={{ span: 19 }}
                        xl={{ span: 15 }}
                        xxl={19}
                        style={{ alignItems: "center" }}
                      >
                        <Text style={{ fontSize: 16 }}>
                          {experience.title} | {experience.company}
                        </Text>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 6 }}
                        md={{ span: 6 }}
                        lg={{ span: 5 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 5 }}
                      >
                        <Text style={{ fontWeight: "bold" }}>
                          {experience.dateStart} -{" "}
                          {experience.actualPosition
                            ? "PRESENT"
                            : experience.dateEnd}
                        </Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 20 }}
                        span={20}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text
                          style={{
                            justifyContent: "flex-start",
                            fontSize: 12,
                          }}
                        >
                          {experience.description}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                )}
              />
            )}
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default CandidateExperienceSection;
