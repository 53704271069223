import React, { useState } from 'react';
/* import VerificationStep from './VerificationStep';
import RegisterError from './RegisterError';
import RegisterForm from './registerForm/RegisterForm';
import RegisterLoading from './RegisterLoading';
import RegisterSucess from './RegisterSucess'; */
import { Typography } from 'antd';
import RegisterLoading from './RegisterLoading';
import { StateGeneralRedux } from '../../../../types/redux';
import { useSelector } from 'react-redux';
import RegisterSuccess from './RegisterSucess';
import RegisterError from './RegisterError';
import VerificationStep from './VerificationStep';
import RegisterForm from './registerForm/RegisterForm';
import { useNavigate } from 'react-router-dom';


// Component
const RegisterCard: React.FC = () => {

    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
    // States
    const [step, setStep] = useState<'Initial' | 'VerificationEmail' | 'completeRegistration' | 'Error' | 'Loading'>('Initial');
    const [userInformation, setUserInformation] = useState<any | null>(null);
    const navigate = useNavigate();
    return (
        <>
            {
                step === 'Loading' ? (
                    <RegisterLoading />
                ) : step === 'Initial' ? (
                    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Typography.Text style={{ color: '#5A5AD1' }}>{dictionary['register-card-already-have-account']}</Typography.Text>

                            <Typography.Text onClick={() => navigate('/login')} style={{ color: '#5A5AD1', marginLeft: '5px', marginRight: '120px', textDecoration: 'underline', cursor: 'pointer' }}>
                                {dictionary['register-card-sign-in-here']}
                            </Typography.Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography.Title style={{ color: '#5A5AD1', fontSize: '50px', fontFamily: 'Muli' }}>{dictionary['register-card-create-account-title']}</Typography.Title>
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <RegisterForm setStep={setStep} setUserInformation={setUserInformation} />
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>
                            <Typography.Text style={{ color: '#5A5AD1' }}>{dictionary['register-card-for-more-info']} </Typography.Text>

                            <Typography.Text style={{ color: '#5A5AD1', marginLeft: '5px', marginRight: '120px' }}>
                                <a href="https://gocareerswipe.com/" style={{ color: '#5A5AD1', textDecoration: 'underline' }}>{dictionary['register-card-gocareerswipe-link']}</a>
                            </Typography.Text>
                        </div>

                    </div>
                ) : step === 'completeRegistration' ? (
                    <RegisterSuccess />
                ) : step === 'Error' ? (
                    <RegisterError setStep={setStep} />
                ) : step === 'VerificationEmail' && (
                    <VerificationStep user={userInformation} setStep={setStep} />
                )
            }
        </>
    )
}

export default RegisterCard;
