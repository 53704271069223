//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
import { Col, Row, Typography } from 'antd';
import React from 'react'
import IconRatingSkill from '../../../../iconRating';
import { useSelector } from 'react-redux';
interface Props {
    requirements: Array<any>;
    skills: Array<any>;
}
const { Text } = Typography;

const SecondSectionCarousel: React.FC<Props> = ({ requirements, skills }) => {
    const { dictionary } = useSelector((state: any) => state.ui);


    return (
        <div className="contenedor" style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            <Row style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
                <Text style={{ fontSize: 22, fontFamily: 'Muli' }}>{dictionary['creation-modal-preview-field-skills']}</Text>
                <Row style={{ width: '100%' }}>
                    {
                        skills.map((each, index) => (
                            <Row key={each.nameSkill} style={{ width: '100%', paddingBottom: '3%', justifyContent: 'space-between', display: 'flex' }}>
                                <Col key={index + 'skillsDiv'} style={{ backgroundColor: '#24cece', width: '58%', marginRight: "2%", textAlign: "center", paddingLeft: 10, paddingRight: 8, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text key={index + 'skillsText'} style={{ fontSize: 18, fontFamily: 'Muli', display: 'inline-block' }}>{each.nameSkill}</Text>
                                </Col>
                                <Col style={{ width: "40%", display: 'flex', justifyContent: 'flex-end', alignItems: "center" }} key={index + 'skillsDivSecond'}>

                                    <IconRatingSkill rating={each.rating} />
                                </Col>
                            </Row>
                        ))
                    }

                </Row>
            </Row>

            <Row style={{ marginTop: 20, marginLeft: 30, marginRight: 30 }}>
                <Text style={{ fontSize: 22, fontFamily: 'Muli' }}>{dictionary['creation-modal-preview-field-requirements']}</Text>

                <Row style={{ width: '100%' }}>
                    {
                        requirements.map((each) => (
                            <div key={each.value} style={{ width: '98%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, backgroundColor: '#E7FBFF', padding: '3%', marginTop: 10 }}>
                                <Text style={{ fontSize: 18, display: 'inline-block', fontFamily: 'Muli' }}>{each.value}</Text>
                            </div>
                        ))
                    }

                </Row>
            </Row>
        </div>
    )
}

export default SecondSectionCarousel