// Dependencies

import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { startGetLatestStatusCandidateAllJobs } from "../../firebase/chat/hooks";
import {
  changeLoadingChat,
  getCounterMessages,
  getMessageInboxList,
  getPositionList,
  getUserChatSelected,
} from "./chatSlice";
import { db } from "../../firebase/config";
import { getJobsCandidateById } from "../../firebase/endpoints/messages";
//a
export const startGetMessageUnreadCounter = (user) => {
  return async (dispatch) => {
    // Reference to the "messages" subcollection
    const messagesRef = collection(db, `hirings/${user?.idHiring}/messages`);

    onSnapshot(messagesRef, (messagesCollection) => {
      let counter = 0;
      const chatQueries = [];

      messagesCollection.forEach((messagesData) => {
        // Filtra aquí por el ID que quieres ignorar
        if (messagesData.id !== "huiCo2D8l6ZY7NuyNruK6NH6CCG3") {
          const chatRef = collection(
            db,
            `hirings/${user?.idHiring}/messages/${messagesData.id}/chat`
          );
          const q = query(
            chatRef,
            where("check", "==", false),
            where("chatUser", "==", "to")
          );

          
          chatQueries.push(getDocs(q));
        }
      });

      Promise.all(chatQueries).then((chatDocs) => {
        chatDocs.forEach((docChat) => {
          counter += docChat.docs.length;
        });
        dispatch(getCounterMessages(counter));
      });
    });
  };
};

export const startGetPositionListChat = (user) => {
  return async (dispatch) => {
    // Reference to the "positions" subcollection under a specific "hirings" document
    const positionsRef = collection(db, `hirings/${user?.idHiring}/positions`);
    const q = query(positionsRef, orderBy("title", "asc"));

    // Setting up the real-time listener
    onSnapshot(q, (positionsCollectionSnap) => {
      const docs = [];
      positionsCollectionSnap.forEach((positionData) => {
        const position = positionData.data();
        docs.push(position);
      });

      // Filter positions with status "open"
      const result = docs.filter((d) => d.status === "open");

      // Dispatch the Redux action
      dispatch(getPositionList(result));
    });
  };
};

export const startGetMessageInboxList = (user, token) => {
  return async (dispatch) => {
    try {
      dispatch(changeLoadingChat(true));
      // Reference to the "messages" subcollection
      const messagesRef = collection(db, `hirings/${user?.idHiring}/messages`);
      const messagesCollectionSnap = await getDocs(messagesRef);

      const docs = [];

      await Promise.all(messagesCollectionSnap.docs.map(async( messageCandidateData)=>{
        const message = messageCandidateData.data();
        const responseJobPerCandidate =  await getJobsCandidateById(user?.idHiring, message.id)

        if (responseJobPerCandidate !== null) {
          const responseLatestStatus = await startGetLatestStatusCandidateAllJobs(responseJobPerCandidate,message.id,user?.idHiring);

          if (responseLatestStatus !== null && message.id !== "huiCo2D8l6ZY7NuyNruK6NH6CCG3") {
            
              docs.push({
                status: responseLatestStatus,
                dateLastMessage: {
                  seconds: message.dateLastMessage.seconds,
                  nanoseconds: message.dateLastMessage.nanoseconds,
                },
                id: message.id,
                lastName: message.lastName,
                name: message.name,
                notReaded: message.notReaded,
                messagesPending: message.messagesPending,
              });
            
          }
        }
      }))



      // Sort messages by dateLastMessage in descending order
      docs.sort((a, b) => {
        const dateA =
          a.dateLastMessage.seconds * 1000 +
          a.dateLastMessage.nanoseconds / 1000000;
        const dateB =
          b.dateLastMessage.seconds * 1000 +
          b.dateLastMessage.nanoseconds / 1000000;
        return dateB - dateA;
      });
      console.log(docs)
      dispatch(getMessageInboxList(docs));
      dispatch(changeLoadingChat(false));
    } catch (error) {
      dispatch(changeLoadingChat(false));
      console.error("Error fetching messages:", error);
    }
  };
};

export const startShowFirstCandidateToInbox = (user, positionMap) => {
  return async (dispatch) => {
    // Reference to the "messages" subcollection under a specific "hirings" document
    const messagesRef = collection(db, `hirings/${user?.idHiring}/messages`);
    const q = query(messagesRef, limit(1));

    try {
      // Fetching the first document from the "messages" subcollection
      const messagesCollectionSnap = await getDocs(q);
      messagesCollectionSnap.forEach((messageCandidateData) => {
        const message = messageCandidateData.data();
        // Dispatch the Redux action
        dispatch(getUserChatSelected(message));
      });
    } catch (error) {
      console.error("Error fetching first message candidate:", error);
      // Handle any errors that occurred during the process
    }
  };
};
