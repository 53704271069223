//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import GraphicPieChart from "./GraphicPieChart";
import { QuestionCircleOutlined } from "@ant-design/icons";
import HelpInformationModal from "./HelpInformationModal";
import { useSelector } from "react-redux";
import {
  getNewDimensionsByScreenSize,
  getTranslation,
} from "../../../hooks/hooks";
import TitleCol from "./TitleCol";

//Interface
interface Props {
  dataDashboard: Array<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
    daysOld: number;
    declined: number;
    hired: number;
    interested: number;
    interview: number;
    meeting: number;
    waitingForReply: number;
    feedback: number;
  }>;
}

//Component
const { Text } = Typography;
const GraphicCard: React.FC<Props> = ({ dataDashboard }) => {
  //States
  const { dictionary } = useSelector((state: any) => state.ui);
  const [positionSelected, setPositionSelected] = useState<string>("");
  const [infoPositionSelected, setInfoPositionSelected] =
    useState<Array<{ title: string; count: number }>>();
  const [visibleHelpInformationModal, setvisibleHelpInformationModal] =
    useState<boolean>(false);
  const [size, setSize] = useState<number>(window.innerWidth);
  const handleOnSelectJob = (each: any) => {
    try {
      if (positionSelected.length === 0) {
        setPositionSelected(each.idPosition);
        setInfoPositionSelected([
          {
            title: "like",
            count: each.like,
          },
          {
            title: "dislike",
            count: each.dislike,
          },
          {
            title: "feedback",
            count: each.feedback,
          },
          {
            title: "waiting reply",
            count: each.waitingForReply,
          },
        ]);
      } else if (positionSelected === each.idPosition) {
        setPositionSelected("");
        setInfoPositionSelected(undefined);
      } else if (positionSelected !== each.idPosition) {
        setPositionSelected("");
        setInfoPositionSelected(undefined);
        setTimeout(() => {
          setPositionSelected(each?.idPosition);
          setInfoPositionSelected([
            {
              title: "like",
              count: each.like,
            },
            {
              title: "dislike",
              count: each.dislike,
            },
            {
              title: "feedback",
              count: each.feedback,
            },
            {
              title: "waiting reply",
              count: each.waitingForReply,
            },
          ]);
        }, 50);
      }
    } catch (error) { }
  };

  // Effects
  useEffect(() => {
    if (dataDashboard.length > 0) {
      setPositionSelected(dataDashboard[0].idPosition);
      setInfoPositionSelected([
        {
          title: "like",
          count: dataDashboard[0].like,
        },
        {
          title: "dislike",
          count: dataDashboard[0].dislike,
        },
        {
          title: "feedback",
          count: dataDashboard[0].feedback,
        },
        {
          title: "waiting reply",
          count: dataDashboard[0].waitingForReply,
        },
      ]);
    }
  }, [dataDashboard]);

  const updateWidth = () => {
    setSize(window.innerWidth);
  };

  // Effects
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
  }, [size]);

  return (
    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 24 }} style={{
      height: "100%",
      padding: "1%",
      borderRadius: 16,
      border: "1px solid",
      borderColor: "#00000020",
      backgroundColor: 'white'
    }}
    >
      <Row style={{
        width: "100%", display: "flex", alignItems: "center",
        marginTop: 13,
        marginBottom: 13,
      }}
      >
        <Col
          xs={{ offset: 1 }}
          sm={{ offset: 1 }}
          md={{ offset: 1 }}
          lg={{ offset: 1 }}
          xl={{ offset: 1 }}
          xxl={{ offset: 1 }}
        >
          <Text
            style={{ fontSize: 18, fontWeight: "bold", fontFamily: "Muli" }}
          >
            {dictionary["dashboard-title-poolCandidates"]}
          </Text>
        </Col>

        <Col>
          <Button
            onClick={() => setvisibleHelpInformationModal(true)}
            shape="circle"
            style={{ border: "none" }}
            icon={<QuestionCircleOutlined />}
          />
        </Col>

        <HelpInformationModal
          visible={visibleHelpInformationModal}
          handleOnCloseModal={() => setvisibleHelpInformationModal(false)}
        />
      </Row>

      <Row
        style={{
          height: "85%",
          width: "100%",
          marginBottom: getNewDimensionsByScreenSize(size),
        }}
      >
        {
          <Row style={{ height: "80%", width: "100%" }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              style={{ height: "100%", overflowY: "auto" }}
            >
              {dataDashboard.length > 0 &&
                dataDashboard.map((each, index) => (
                  <TitleCol
                    each={each}
                    key={each.idPosition}
                    handleOnSelectJob={(value: any) => handleOnSelectJob(value)}
                    positionSelected={positionSelected}
                  />
                ))}
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: 'white'
              }}
            >
              {positionSelected !== "" &&
                infoPositionSelected !== undefined && (
                  <GraphicPieChart data={infoPositionSelected} />
                )}
            </Col>
          </Row>
        }

        <Row style={{ width: "100%", marginTop: 10 }}>
          <Col
            xs={{ offset: 1, span: 23 }}
            sm={{ span: 16, offset: 1 }}
            md={{ span: 12, offset: 12 }}
            lg={{ span: 10, offset: 14 }}
            xl={{ span: 14, offset: 10 }}
            xxl={{ span: 10, offset: 14 }}
            style={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Row style={{ width: "100%" }}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: "#77AB59",
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />
                  <Text style={{ fontFamily: "Muli" }}>
                    {getTranslation(
                      "dashboard-graphic-legend-meet",
                      dictionary
                    )}
                  </Text>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: "#D12525",
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />

                  <Text style={{ fontFamily: "Muli" }}>
                    {getTranslation(
                      "dashboard-graphic-legend-no-meet",
                      dictionary
                    )}
                  </Text>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: "yellow",
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />
                  <Text style={{ fontFamily: "Muli" }}>
                    {getTranslation(
                      "dashboard-graphic-legend-feedback",
                      dictionary
                    )}
                  </Text>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: "#C182E6",
                      borderRadius: 12,
                      marginRight: 3,
                    }}
                  />
                  <Text style={{ fontFamily: "Muli" }}>
                    {getTranslation(
                      "dashboard-graphic-legend-waiting-for-reply",
                      dictionary
                    )}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Col>
  );
};

//Export
export default GraphicCard;
