import { Button, Col, Modal, Row, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react'
import { CloseOutlined } from '@ant-design/icons';
import iconBetterSalary from '../../../image/iconSalaryWithoutText.png'
import iconRemote from '../../../image/iconRemoteWithoutText.png';
import iconLocation from '../../../image/iconOtherLocationWithoutText.png';
import iconLevel from '../../../image/iconLevelWithoutText.png';
import { useSelector } from 'react-redux';

//Interface
interface Props{
    visible:boolean;
    handleOnCloseModal:()=>void;
}

const { Text } = Typography;
const HelpInformationModal:React.FC<Props> = ({visible,handleOnCloseModal}) => {
    
    // States
    const { dictionary } = useSelector((state:any)=>state.ui);

return (
    <Modal 
        open={visible} 
        closable={false}
        title={
            <Row style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Text style={{fontSize:22,fontFamily:'Muli'}}>{dictionary['dashboard-modal-feedback-parrafo1']}</Text>
                <Button
                    shape="circle"
                    style={{color:'white',backgroundColor:'#D12525',border:'none',display:'flex',justifyContent:'center',alignItems:'center'}}
                    icon={<CloseOutlined   />}
                    onClick={handleOnCloseModal }
                />
            </Row>
        }
        onCancel={handleOnCloseModal}
        width='65%' 
        centered
        destroyOnClose
        footer={<></>}
    >
        <Content  className="contenedor" style={{width:'100%',height:'70vh', overflow:"auto"}}>
            <Row style={{width:'100%',display:'flex',justifyContent:'center',textAlign:'center'}}>
                <Text style={{fontSize:16, fontFamily:'Muli'}}>{dictionary['dashboard-modal-feedback-parrafo2']}</Text>
            </Row>

            <Row style={{width:'100%',display:'flex',justifyContent:'center',marginTop:10,textAlign:'center',paddingLeft:'17%',paddingRight:'17%'}}>
                <Text style={{fontSize:16, fontFamily:'Muli'}}> 
                    {dictionary['dashboard-modal-feedback-parrafo3']}
                </Text>
            </Row>

            <Row style={{width:'100%',marginTop:20}}>
                <Text style={{fontSize:17, fontFamily:'Muli'}}>{dictionary['dashboard-modal-feedback-parrafo4']}</Text>
            </Row>

            <Row style={{width:'100%', height:"60%"}}>
               
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} >
                    <Row style={{width:'100%', height:"100%"}}>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ height:'100%', width:"100%", justifyContent:'center',display:'flex',alignItems:'center', }}>
                            <img src={iconBetterSalary } style={{height:'80%', width:"80%", objectFit:"contain",}} alt='betteer salary'/>
                        </Col>

                        <Col xs={15} sm={15} md={15} lg={15} xl={15} xxl={15} style={{height:'100%',justifyContent:'center',display:'flex',alignItems:'center'}}>
                            <Row style={{height:'100%',width:'100%', padding:"5%"}}>
                                <Row style={{width:'100%',textAlign:'center'}}>
                                    <Text style={{fontSize:18, fontFamily:'Muli', fontWeight:"bold"}}>{dictionary['dashboard-modal-feedback-parrafo5']}</Text>
                                </Row>
                                <Row style={{width:'100%'}}>
                                    <Text style={{fontSize:16, fontFamily:'Muli', textAlign:"start"}}> {dictionary['dashboard-modal-feedback-parrafo5-sub-info']}</Text>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} >
                    <Row style={{width:'100%', height:"100%"}}>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ height:'100%', width:"100%", justifyContent:'center',display:'flex',alignItems:'center', }}>
                            <img src={iconRemote} style={{height:'80%', width:"80%", objectFit:"contain",}} alt='Remote'/>
                        </Col>

                        <Col xs={15} sm={15} md={15} lg={15} xl={15} xxl={15} style={{height:'100%',justifyContent:'center',display:'flex',alignItems:'center'}}>
                            <Row style={{height:'100%',width:'100%', padding:"5%"}}>
                                <Row style={{width:'100%',textAlign:'center'}}>
                                    <Text style={{fontSize:18, fontFamily:'Muli', fontWeight:"bold"}}>{dictionary['dashboard-modal-feedback-parrafo7']}</Text>
                                </Row>
                                <Row style={{width:'100%'}}>
                                    <Text style={{fontSize:16, fontFamily:'Muli', textAlign:"start"}}> {dictionary['dashboard-modal-feedback-parrafo7-sub-info']}</Text>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                </Col>    
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} >
                    <Row style={{width:'100%', height:"100%"}}>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ height:'100%', width:"100%", justifyContent:'center',display:'flex',alignItems:'center', }}>
                            <img src={iconLocation} style={{height:'80%', width:"80%", objectFit:"contain",}} alt='icon location'/>
                        </Col>

                        <Col xs={15} sm={15} md={15} lg={15} xl={15} xxl={15} style={{height:'100%',justifyContent:'center',display:'flex',alignItems:'center'}}>
                            <Row style={{height:'100%',width:'100%', padding:"5%"}}>
                                <Row style={{width:'100%',textAlign:'center'}}>
                                    <Text style={{fontSize:18, fontFamily:'Muli', fontWeight:"bold"}}>{dictionary['dashboard-modal-feedback-parrafo6']}</Text>
                                </Row>
                                <Row style={{width:'100%'}}>
                                    <Text style={{fontSize:16, fontFamily:'Muli', textAlign:"start"}}> {dictionary['dashboard-modal-feedback-parrafo6-sub-info']}</Text>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                </Col>    
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} >
                    <Row style={{width:'100%', height:"100%"}}>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ height:'100%', width:"100%", justifyContent:'center',display:'flex',alignItems:'center', }}>
                            <img src={iconLevel} style={{height:'80%', width:"80%", objectFit:"contain",}} alt='icon level'/>
                        </Col>

                        <Col xs={15} sm={15} md={15} lg={15} xl={15} xxl={15} style={{height:'100%',justifyContent:'center',display:'flex',alignItems:'center'}}>
                            <Row style={{height:'100%',width:'100%', padding:"5%"}}>
                                <Row style={{width:'100%',textAlign:'center'}}>
                                    <Text style={{fontSize:18, fontFamily:'Muli', fontWeight:"bold"}}>{dictionary['dashboard-modal-feedback-parrafo8']}</Text>
                                </Row>
                                <Row style={{width:'100%'}}>
                                    <Text style={{fontSize:16, fontFamily:'Muli', textAlign:"start"}}> {dictionary['dashboard-modal-feedback-parrafo8-sub-info']}</Text>
                                </Row>

                            </Row>
                        </Col>
                    </Row>
                </Col>    
            </Row>
        </Content>
    </Modal>
  )
}

export default HelpInformationModal