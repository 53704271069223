//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Typography } from "antd";
import PositionButton from "./PositionButton";
import { getTranslation } from "../../../../hooks/hooks";
import { useSelector } from "react-redux";

// Interface
interface Props {
  positionList: Array<any>;
  loading: boolean;
  status: any;
}

// Component
const PositionList: React.FC<Props> = ({ positionList, loading, status }) => {
  const { Text } = Typography;
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginBottom: 3,
        marginRight: 3,
      }}
    >
      {loading ? (
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#24CECE",
            cursor: "pointer",
          }}
        >
          <Text
            style={{
              fontSize: 20,
              color: "black",
              fontFamily: "Muli",
            }}
          >
            {getTranslation("message-header-chat-text-loading", dictionary)}
          </Text>
        </div>
      ) : positionList.length > 0 ? (
        positionList.map((position, index) => (
          <div
            key={index + "positionListRender"}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <PositionButton key={index} position={position} status={status} />
          </div>
        ))
      ) : (
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: "#24CECE",
            cursor: "pointer",
          }}
        >
          <Text
            style={{
              fontSize: 20,
              color: "black",
              fontFamily: "Muli",
            }}
          >
            {getTranslation("message-header-chat-text-no-messages", dictionary)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default PositionList;
