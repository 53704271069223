import { Button } from "antd";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
// Interface
interface Props {
  handleOnFunction: () => void;
}

// Component
const ButtonEquis: React.FC<Props> = ({ handleOnFunction }) => {
  return (
    <Button
      shape="circle"
      size="small"
      style={{
        color: "white",
        backgroundColor: "red",
        border: "transparent",
      }}
      icon={<CloseOutlined />}
      onClick={handleOnFunction}
    />
  );
};

export default ButtonEquis;
