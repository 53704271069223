import { Col, Row, Typography } from "antd";
import React from "react";

//Interfaces
interface Props {
  text: string;
  count: number;
}

// Component
const { Text } = Typography;
const StatusTextCard: React.FC<Props> = ({ text, count }) => {
  return (
    <Row
      style={{
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderTopColor: "#00000030",
        borderBottomColor: "#00000030",
        marginBottom: 5,
        backgroundColor: "white",
        borderBottomLeftRadius: text === "Declined" ? 10 : 0,
        borderBottomRightRadius: text === "Declined" ? 10 : 0,
      }}
    >
      <Col
        xs={{ span: 17, offset: 1 }}
        sm={{ span: 17, offset: 1 }}
        md={{ span: 17, offset: 1 }}
        lg={{ span: 17, offset: 1 }}
        xl={{ span: 17, offset: 1 }}
        xxl={{ span: 17, offset: 1 }}
      >
        <Text style={{ fontSize: 16 }}>{text}</Text>
      </Col>

      <Col
        xs={{ span: 2, offset: 1 }}
        lg={{ span: 2, offset: 1 }}
        xl={{ span: 4, offset: 1 }}
        xxl={{ span: 4, offset: 1 }}
      >
        <Text style={{ fontSize: 16 }}>{count}</Text>
      </Col>
    </Row>
  );
};

export default StatusTextCard;
