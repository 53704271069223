import React, { useEffect, useState } from "react";
import { TruncatedText } from "../../../common/TruncatedText";
import { Col } from "antd";

//  Interface
interface Props {
  name: string;
}

const TitlePositionCol: React.FC<Props> = ({ name }) => {
  const [truncateTextMaxLengh, setTruncateTextMaxLengh] = useState<number>(20);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200 && window.innerWidth < 1300) {
        setTruncateTextMaxLengh(20);
      } else if (window.innerWidth > 1300 && window.innerWidth < 1600) {
        console.log(window.innerWidth);
        setTruncateTextMaxLengh(30);
      } else if (window.innerWidth > 1600 && window.innerWidth < 1800) {
        setTruncateTextMaxLengh(40);
      } else if (window.innerWidth < 1200) {
        setTruncateTextMaxLengh(50);
      }
      console.log(window.innerWidth)
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Col
      xs={{ span: 23, offset: 1 }}
      sm={{ span: 23, offset: 1 }}
      md={{ span: 11, offset: 1 }}
      lg={{ span: 11, offset: 1 }}
      xl={{ span: 11, offset: 1 }}
      xxl={{ span: 11, offset: 1 }}
    >
      <TruncatedText text={name} maxLength={truncateTextMaxLengh} />
    </Col>
  );
};

export default TitlePositionCol;
