import React from 'react';
import { Button, Input, Typography } from 'antd';

const { Text } = Typography;

interface AddFieldFormProps {
    newFieldValue: string;
    setNewFieldValue: (value: string) => void;
    addNewField: any;
    dataSource: Array<any>;
}
const { TextArea } = Input;
const AddFieldForm: React.FC<AddFieldFormProps> = ({ newFieldValue, setNewFieldValue, addNewField, dataSource }) => {
    return (
        <>
            <div style={{ flexGrow: 1 }}>
                <TextArea rows={1} maxLength={350} className='input' value={newFieldValue} onChange={(e) => { e.preventDefault(); setNewFieldValue(e.target.value) }} />
            </div>

            <div className='counter-button-container' style={{ alignItems: 'flex-start', boxSizing: 'border-box', gap: '6px' }}>
                <Text className='text-counter' style={{ marginTop: '5px' }}>{newFieldValue.length}/350</Text>
                <Button onClick={() => newFieldValue.length > 0 && addNewField(newFieldValue, dataSource)} className='button-table-add'>
                    Add
                </Button>
            </div>

        </>
    );
};

export default AddFieldForm;
