//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import React from "react";

/* import Peer from "../components/recommendation/peerRecomendation/Peer"; */
import { Content } from "antd/lib/layout/layout";

// Component
const PeerRecommendationScreen: React.FC = () => {
  return (
    <Content style={{ marginLeft: "2vh", marginRight: "2vh" }}>
     {/*  <Peer /> */}
    </Content>
  );
};

// Exports
export default PeerRecommendationScreen;
