import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { TruncatedText } from "../../../common/TruncatedText";

interface Props {
  each: {
    idPosition: string;
    name: string;
  };
  handleOnSelectJob: (value: { idPosition: string; name: string }) => void;
  positionSelected: any;
}
const TitleCol: React.FC<Props> = ({
  each,
  handleOnSelectJob,
  positionSelected,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const colStyle = {
    display: "flex",
    alignItems: "center",
    paddingLeft: 7,
    fontSize: isSmallScreen ? "0.9em" : "1em", // Cambio de tamaño de fuente en función del tamaño de pantalla
  };
  const rowStyle = {
    marginTop: "3%",
    marginBottom: "1%",
    border: each.idPosition === positionSelected ? "1px solid" : "",
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 10,
    cursor: "pointer",
    borderRadius: 10,
    borderColor: each.idPosition === positionSelected ? "#24CECE" : "#00000000",
    backgroundColor: each.idPosition === positionSelected ? "#24CECE" : "",
    fontWeight: each.idPosition === positionSelected ? "bold" : "initial",
    height: 45,
    // Estilos para manejar texto largo
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };
  const [truncateTextMaxLengh, setTruncateTextMaxLengh] = useState<number>(40);
  useEffect(() => {
    const handleResize = () => {
      /*  setIsSmallScreen(window.innerWidth < 768); */
      if (window.innerWidth > 1200 && window.innerWidth < 1300) {
        setTruncateTextMaxLengh(20);
      } else if (window.innerWidth > 1300 && window.innerWidth < 1600) {
        setTruncateTextMaxLengh(30);
      } else if (window.innerWidth > 1600 && window.innerWidth < 1800) {
        setTruncateTextMaxLengh(40);
      } else if (window.innerWidth < 1200) {
        setTruncateTextMaxLengh(50);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Row
      key={each.idPosition + each.name}
      style={rowStyle}
      onClick={() => handleOnSelectJob(each)}
    >
      <Col xs={{ span: 23, offset: 1 }} style={colStyle}>
        <TruncatedText text={each.name} maxLength={truncateTextMaxLengh} />
      </Col>
    </Row>
  );
};

export default TitleCol;
