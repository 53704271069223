// Dependencies
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { db } from '../../../firebase/config';
import { MessagesModified } from '../../../types/message';
import ChatDetail from './ChatDetail';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';



// Component
const ChatList: React.FC = () => {

  // States

  // List chats
  const [chats, setChats] = useState<Array<MessagesModified>>([]);
  const user = useSelector((state: any) => state.auth.user);
  const chatEndRef = useRef<HTMLDivElement>(null);

  // Handlers
  const startGetMessageUser = useCallback(
    (id: string, idUser: string) => {
      try {
        // Reference to the "chat" subcollection
        const chatRef = collection(db, `hirings/${id}/messages/${idUser}/chat`);

        // Create the query with orderBy
        const q = query(chatRef, orderBy("date"));

        // Setting up the real-time listener
        const unsubscribe = onSnapshot(q, (chatCollectSnap) => {
          const chats: any = [];

          chatCollectSnap?.docs.forEach((chatData, index) => {
            const chat = chatData.data();
            const showName = index === 0 || chatCollectSnap?.docs[index - 1]?.data()?.chatUser !== "to";

            chats.push({
              ...chat,
              showName
            });
          });

          setChats(chats); // Update your state or perform other actions with chat data
        });

        // Return the unsubscribe function to allow manual unsubscription
        return unsubscribe;
      } catch (error) {
        console.log("Error setting up chat listener:", error);
      }
    },
    [], // Dependencies for useCallback
  );

  const scrollBottom = () => {
    const node: HTMLDivElement | null = chatEndRef.current;

    if (node) {
      const scroll = node.scrollHeight - node.clientHeight;
      node.scrollTo(0, scroll);
    }
  };
  useEffect(() => {
    startGetMessageUser(user?.idHiring, 'huiCo2D8l6ZY7NuyNruK6NH6CCG3')
  }, [user?.idHiring, startGetMessageUser]);


  useEffect(() => {
    scrollBottom();
  }, [chats]);


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="contenedor" style={{ width: '100', height: '100%', overflowY: 'auto', padding: 10 }} ref={chatEndRef}>
        {
          chats.map((chat, index) => (
            chat.chatUser === 'to' ? (
              <ChatDetail key={chat.chatUser + index} message={chat} backgroundColor="#ECFBFD" color='blue' justifyContent='flex-start' textAlign='center' />
            ) : (
              <ChatDetail key={chat.chatUser + index} message={chat} backgroundColor='#F5F1FB' color='red' justifyContent='flex-end' textAlign='center' />
            )
          ))
        }
      </div>
    </div>
  )
}

export default ChatList