//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
/* import IconRatingSkill from '../../../../iconRating'; */
import JobPreviewCarousel from './JobPreviewCarousel';

//Interface
interface Props {
    titleInput:string;
    jobType:Array<string>;
    location:string;
    workplace:Array<string>;
    description:string;
    salary:string;
    salaryMax:string;
    responsibilities:Array<any>;
    requirements:Array<any>;
    skills:Array<any>;
    totalSalary:string;
    annualOrHourly:boolean;
    hourlyCheck:boolean;
    annualCheck:boolean;
    percentageCheck:boolean;
    amountCheck:boolean;
    otherDetailsCompensation:string;
    valueHourForWeek:number;
    checkMin:boolean;
    checkMiddle:boolean;
    checkMax:boolean;
}

// Component
const FinalStepCreationJob:React.FC<Props> = ({checkMin,checkMiddle,checkMax,valueHourForWeek,otherDetailsCompensation,description,jobType,location,requirements,responsibilities,salary,totalSalary,skills,titleInput,workplace,annualOrHourly,amountCheck,annualCheck,hourlyCheck,percentageCheck, salaryMax})=> {
    return(
        <Content style={{minHeight:'75vh' ,width:'100%', padding:"3%"}}>
            <Col md={{ span: 16,offset:4}}  lg={{ span:12,offset:6}} xl={{span: 11,offset:7}} xxl={{span:9,offset:7}} style={{ height:'100%'}}>
                <JobPreviewCarousel 
                    checkMin={checkMin}
                    checkMax={checkMax}
                    checkMiddle={checkMiddle}
                    titleInput={titleInput}
                    description={description}
                    jobType={jobType}
                    location={location}
                    requirements={requirements}
                    responsibilities={responsibilities}
                    salary={salary}
                    salaryMax={salaryMax}
                    skills={skills}
                    workplace={workplace}
                    totalSalary={ totalSalary }
                    annualOrHourly={annualOrHourly}
                    amountCheck={amountCheck}
                    annualCheck={annualCheck}
                    hourlyCheck={hourlyCheck}
                    percentageCheck={percentageCheck}
                    otherDetailsCompensation={otherDetailsCompensation}
                    valueHourForWeek={valueHourForWeek}
                />
            </Col>
        </Content>
    )
}

// Exports
export default FinalStepCreationJob;