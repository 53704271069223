//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Menu, Row } from "antd";

import { db } from "../firebase/config";

import { logout } from "../store/auth";

import iconUser from "../image/iconProfile.png";
import iconMartillo from "../image/iconMartillo.png";
import iconLogout from "../image/iconLogoutWhite.png";
import iconLangEn from "../image/icon-web-en.png";
import iconLangFr from "../image/icon-web-fr.png";
import iconIRGTLogoWhite from "../image/navbar/logo-CS-horizontal_white-TM.png";
import { logoutFirebase } from "../firebase/providers";
import { getDictionary, getLanguage } from "../store/ui";
import MensajeIngles from "../lang/diccionary-en.json";
import MensajeFrance from "../lang/diccionary-fr.json";
import {
  Timestamp,
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";

//Components
const NavBar: React.FC = () => {
  //States
  const navigate = useNavigate();
  const { language } = useSelector((state: any) => state.ui);
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();

  //Handlers
  const LogOut = useCallback(async () => {
    logoutFirebase();
    dispatch(logout());
    navigate("/login");
  }, [navigate, dispatch]);

  const handleOnChangeLanguage = async () => {
    if (language === "English") {
      localStorage.setItem("lang", "Français");
      const candidateDocRef = doc(db, "hirings", user?.idHiring);
      await updateDoc(candidateDocRef, {
        language: "Français",
      });
      dispatch(getLanguage("Français"));
      dispatch(getDictionary(MensajeFrance));
    } else {
      localStorage.setItem("lang", "English");
      const candidateDocRef = doc(db, "hirings", user?.idHiring);
      await updateDoc(candidateDocRef, {
        language: "English",
      });
      dispatch(getLanguage("English"));
      dispatch(getDictionary(MensajeIngles));
    }
  };

  const handleOnNavigateSupportScreen = async (
    idHiring: string,
    name: string,
    lastName: string,
    company: string
  ) => {
    try {
      const candidateDocRef = doc(
        db,
        "admin",
        "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
        "messages",
        idHiring
      );
      const candidateDoc = await getDoc(candidateDocRef);

      if (!candidateDoc.exists()) {
        await runTransaction(db, async (transaction) => {
          const fromMessagesRef = doc(
            db,
            "hirings",
            idHiring,
            "messages",
            "huiCo2D8l6ZY7NuyNruK6NH6CCG3"
          );
          const toMessagesRef = doc(
            db,
            "admin",
            "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
            "messages",
            idHiring
          );

          const fromMessageData = {
            id: "huiCo2D8l6ZY7NuyNruK6NH6CCG3",
            name: "Administrator",
            lastName: "Support",
            dateLastMessage: Timestamp.now(),
            messagesPending: false,
            notReaded: 0,
          };

          const toMessageData = {
            idUser: idHiring,
            name: name,
            lastName: lastName,
            company: company,
            dateLastMessage: Timestamp.now(),
            messagesPending: false,
            notReaded: 0,
            typeUser: "hiring",
          };

          // Establece los documentos en las subcolecciones correspondientes
          transaction.set(fromMessagesRef, fromMessageData);
          transaction.set(toMessagesRef, toMessageData);
        });
      }

      navigate("/support");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Menu
      style={{ backgroundColor: "#6052A3", height: "100%", width: "100%" }}
      mode="horizontal"
    >
      <Row
        style={{
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        <Col
          xs={17}
          sm={18}
          md={19}
          lg={20}
          xl={20}
          xxl={22}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingLeft: 10,
          }}
        >
          <img
            alt="icon Irgt"
            src={iconIRGTLogoWhite}
            style={{ height: "70%", width: "auto", cursor: "pointer" }}
            onClick={() => navigate("/dash")}
          />
        </Col>

        <Col
          xs={7}
          sm={6}
          md={5}
          lg={4}
          xl={4}
          xxl={2}
          style={{ height: "100%", width: "100%" }}
        >
          <Row style={{ width: "100%", height: "100%" }}>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleOnChangeLanguage}
                style={{
                  borderColor: "transparent",
                  backgroundColor: "transparent",
                }}
              >
                {language === "English" ? (
                  <img
                    src={iconLangFr}
                    style={{ height: 35, width: "auto", marginTop: -5 }}
                    alt={iconLangFr}
                  />
                ) : (
                  <img
                    src={iconLangEn}
                    style={{ height: 35, width: "auto", marginTop: -5 }}
                    alt={iconLangEn}
                  />
                )}
              </Button>
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() =>
                  handleOnNavigateSupportScreen(
                    user?.idHiring,
                    user?.name,
                    user?.lastName,
                    user?.company
                  )
                }
                style={{
                  borderColor: "transparent",
                  backgroundColor: "transparent",
                }}
                icon={
                  <img
                    src={iconMartillo}
                    style={{ height: 25, width: "auto" }}
                    alt={iconMartillo}
                  />
                }
              />
            </Col>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => navigate("/profile")}
                style={{
                  borderColor: "transparent",
                  backgroundColor: "transparent",
                }}
                icon={
                  <img
                    src={iconUser}
                    style={{ height: 25, width: "auto" }}
                    alt={iconUser}
                  />
                }
              />
            </Col>

            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              xxl={6}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={LogOut}
                style={{
                  borderColor: "transparent",
                  backgroundColor: "transparent",
                }}
                icon={
                  <img
                    src={iconLogout}
                    style={{ height: 25, width: "auto" }}
                    alt={iconLogout}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Menu>
  );
};

//Export
export default NavBar;
