import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config";
import { CollectionsName } from "../../models/collections";


export const updateRecommendationPreCandidateDoc = async (idHiring: string, idPosition: string, idPreCandidate: string, value: any) => {
    try {
        console.log(idPreCandidate)
        const recommendationCandidatesRef = doc(db, CollectionsName.HIRINGS, idHiring, "positions", idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES, idPreCandidate);
        await updateDoc(recommendationCandidatesRef, value);

        return {
            success: true,
            data: null,
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const updateRecommendationCandidateDoc = async (idHiring: string, idPosition: string, idCandidate: string, value: any) => {
    try {
        const recommendationCandidatesRef = doc(db, CollectionsName.HIRINGS, idHiring, "positions", idPosition, CollectionsName.RECOMMENDATIONCANDIDATES, idCandidate);
        await updateDoc(recommendationCandidatesRef, value);

        return {
            success: true,
            data: null,
        };
    } catch (error) {
        console.log(error)
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}