// Dependencies
import { createSlice } from "@reduxjs/toolkit";
import MensajeIngles from "../../lang/diccionary-en.json";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    position: {},
    language: "English",
    dictionary: MensajeIngles,
  },
  reducers: {
    getPositionSideBarSelected: (state, action) => {
      state.position = action.payload;
    },
    clearPositionSideBarSelected: (state) => {
      state.position = {};
    },
    getLanguage: (state, action) => {
      state.language = action.payload;
    },
    getDictionary: (state, action) => {
      state.dictionary = action.payload;
    },
  },
});

export const {
  getPositionSideBarSelected,
  clearPositionSideBarSelected,
  getLanguage,
  getDictionary,
} = uiSlice.actions;
