//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
import { Row } from "antd";
import { useSelector } from "react-redux";
import { Job } from "../../types/position";
import PositionCard from "./PositionCard";

// Component
const PositionListChat: React.FC = () => {
  // States
  const positionList: Array<Job> = useSelector((state: any) => state.chat.positionList);

  return (
    <Row style={{ height: "100%", width: '100%' }}>
      <Row style={{ display: "flex", flexDirection: "column", width: '100%' }}>
        {positionList.map((position: any, index: number) => (
          <PositionCard key={index} position={position} />
        ))}
      </Row>
    </Row>
  );
};

// Export
export default PositionListChat;
