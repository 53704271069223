//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import React from "react";

import CandidateList from "./CandidateList";
import { useSelector } from "react-redux";
import { Content } from "antd/lib/layout/layout";

// Component
const Recommendation: React.FC = () => {
  // States
  const position = useSelector((state: any) => state.ui.position);

  return (
    <Content style={{ width: "100%", height: "100%" }}>
        {position.idPosition !== "" && <CandidateList position={position} />}
    </Content>
  );
};

// Exports
export default Recommendation;
