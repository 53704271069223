// Dependencies
import { collection, query, where, getDocs } from "firebase/firestore";
import { getUserSelected } from "./jobSlice";
import { db } from "../../firebase/config";
import { CollectionsName } from "../../models/collections";

// Function to get the first candidate from the list
export const startGetFirstCandidate = (user, position) => {
  return async (dispatch) => {
    try {
      // References to the subcollections
      const recommendationCandidatesRef = collection(
        db,
        `hirings/${user?.idHiring}/positions/${position.idPosition}/recommendationCandidates`
      );
      const recommendationPreCandidatesRef = collection(
        db,
        CollectionsName.HIRINGS, user.idHiring, CollectionsName.POSITIONS, position.idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES
      );

      // Queries
      const recommendationCandidatesQuery = query(recommendationCandidatesRef, where("candidateLike", "==", true));
      const recommendationPreCandidatesQuery = query(recommendationPreCandidatesRef, where('hiringLike', '==', true));

      // Fetching the candidates
      const recommendationCandidateSnap = await getDocs(recommendationCandidatesQuery);
      const recommendationPreCandidateSnap = await getDocs(recommendationPreCandidatesQuery);

      let candidateList = [];

      // Process recommendation candidates
      recommendationCandidateSnap.forEach((doc) => {
        const candidate = doc.data();
        if (
          candidate &&
          candidate.like &&
          candidate.status !== "declined" &&
          candidate.status !== "waiting for reply"
        ) {
          candidateList.push({
            ...candidate,
            type: 'candidate',
          });
        }
      });

      // Process pre-candidates
      recommendationPreCandidateSnap.forEach((doc) => {
        const preCandidate = doc.data();
        if (
          preCandidate &&
          preCandidate.hiringLike &&
          preCandidate.candidateStatus !== "declined" &&
          preCandidate.candidateStatus !== "waiting for reply"
        ) {
          candidateList.push({
            skills: preCandidate.skills,
            like: preCandidate.hiringLike,
            status: preCandidate.candidateStatus,
            'insertion date': preCandidate.promoteDate,
            promote: 'hiring',
            type: 'precandidate',
            id: preCandidate.id,
          });
        }
      });

      // Sort the list by "insertion date" (most recent first)
      candidateList.sort((a, b) => b['insertion date'].seconds - a['insertion date'].seconds);

      if (candidateList.length > 0) {
        // Dispatch the Redux action
        dispatch(getUserSelected(candidateList[0]));
      }
    } catch (error) {
      console.error("Error fetching first candidate:", error);
      alert(`Error in function startGetFirstCandidate: ${error}`);
      // Handle any errors that occurred during the process
    }
  };
};
