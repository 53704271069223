import { doc, getDoc } from "firebase/firestore";
import { db } from "../config";

// Obtener candidato usando su id para buscarlo
export const getCandidate = async (idCandidate:string)=>{
    try {
        // Reference to the specific document in the "candidates" collection
        const candidateDocRef = doc(db, 'candidates', idCandidate);
        
        // Get the document
        const candidateDoc = await getDoc(candidateDocRef);

        if (candidateDoc.exists()) {
            return candidateDoc.data(); // Return the document data
        } else {
            console.log("No such document!");
            return null; // Or handle the case where no document is found as needed
        }
    } catch (error) {
        console.error("Error fetching candidate: ", error);
        return null; // Handle the error appropriately
    }
}