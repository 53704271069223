import { Button, Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import alertIcon from "../../../image/iconExclamation.png";

// Interface
interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  handleOnFunction: () => void;
}
const { Text } = Typography;
const DeclinedCandidateConfirmModal: React.FC<Props> = ({
  visible,
  setVisible,
  handleOnFunction,
}) => {
  // States

  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              size="large"
              style={{
                backgroundColor: "#404193",
                color: "white",
                display: "flex",
                justifyContent: "center",
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '20px',
                alignItems: "center",
              }}
            >
              {dictionary["creation-modal-alert-close-button-cancel"]}
            </Button>

            <Button
              size="large"
              onClick={() => {
                setVisible(false);
                handleOnFunction();
              }}
              style={{
                backgroundColor: "#404193",
                color: "white",
                display: "flex",
                justifyContent: "center",
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '20px',
                alignItems: "center",
              }}
            >
              {dictionary["creation-modal-alert-close-button-yes"]}
            </Button>
          </Row>
        </Row>
      }
    >
      <Content style={{ width: "100%", height: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Col
            xs={{ span: 18, offset: 3 }}
            xxl={{ span: 18, offset: 3 }}
            style={{ height: "100%" }}
          >
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="Icon exclamation"
                style={{ height: 30, width: 30 }}
                src={alertIcon}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["job-modal-decliner-parrafo-1"]}
              </Text>
            </Row>

            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["job-modal-decliner-parrafo-2"]}
              </Text>
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};

export default DeclinedCandidateConfirmModal;
