// Dependencies
import { Button, Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import alertIcon from "../../../image/iconExclamation.png";
import { useSelector } from "react-redux";
import { StateGeneralRedux } from "../../../types/redux";

// Interface
interface Props {
  visible: boolean;
  closeModal: () => void;
  message: string;
}
// Component
const { Text } = Typography;

const NotificationLogin: React.FC<Props> = ({
  visible,
  closeModal,
  message,
}) => {

  // States
  const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
  return (
    <Modal
      open={visible}
      centered
      onCancel={closeModal}
      closable={false}
      width="40%"
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row
            style={{ width: "80%", display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => {
                closeModal();
              }}
              style={{
                width: 100,
                backgroundColor: "#24cece",
                borderRadius: 12,
                color: "white",
                boxShadow: "inset 7px 7px 15px #00000040",
              }}
            >
              {dictionary["buttonAccept-text-title1"]}
            </Button>
          </Row>
        </Row>
      }
    >
      <Content style={{ width: "100%", height: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Col xxl={{ span: 18, offset: 3 }} style={{ height: "100%" }}>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="Icon exclamation"
                style={{ height: 30, width: 30 }}
                src={alertIcon}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>{message}</Text>
            </Row>
          </Col>
        </Row>
      </Content>
    </Modal>
  );
};

// Export
export default NotificationLogin;
