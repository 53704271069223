//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import {
  CloseOutlined,
  FileDoneOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { Col, Row, Typography} from "antd";

// Interface
interface Props {
  fileType: "image" | "document";
  nameFile: string;
  setFile: any;
}

// Component
const PreviewMessageFile: React.FC<Props> = ({
  fileType,
  nameFile,
  setFile,
}) => {

  const { Text } = Typography
  return (
    <Row
      style={{
        minWidth:'40%',
        width: "fit-content",
        height: "80%",
        backgroundColor: "#6153D3",
        borderRadius: 16,
        padding:2
      }}
    >
      {fileType === "image" ? (
        <Col span={2} style={{alignContent:'center',alignItems:'center',justifyContent:'center',display:'flex'}}>
          <FileImageOutlined  style={{ color: "white" }} />
        </Col>
      ) : (
        <Col
          style={{
            height: "100%",
            cursor: "pointer",
          }}
        >
          <FileDoneOutlined  style={{ color: "white" }} />
        </Col>
      )}

      <Col span={20} style={{ alignContent:'center',alignItems:'center',justifyContent:'center',display:'flex'}}>
        <Text style={{ fontSize: 14, color: "white" }}>{nameFile.substring(0,20)}{nameFile.length > 20 && '...'}</Text>
      </Col>

      <Col span={2} style={{ height: "100%",cursor: "pointer",alignContent:'center',alignItems:'center',justifyContent:'center',display:'flex' }} onClick={() => setFile(null)}>
        <CloseOutlined  style={{ color: "white" }} />
      </Col>
    </Row>
  );
};

// Export
export default PreviewMessageFile;
