
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebase/config'; 
import User from '../../types/user';

// CRUD COLLECTION HIRING

// Read Hiring
export const getHiring = async (idHiring:string)=>{
    try {
        // Reference to the specific document in the "candidates" collection
        const hiringDocRef = doc(db, 'hirings', idHiring);
        
        // Get the document
        const hiringDoc = await getDoc(hiringDocRef);

        if (hiringDoc.exists()) {
            const hiring = hiringDoc.data() as User;
            return hiring; // Return the document data
        } else {
            console.log("No such document!");
            return null; // Or handle the case where no document is found as needed
        }
    } catch (error) {
        console.error("Error fetching candidate: ", error);
        return null; // Handle the error appropriately
    }
}

// Red Hiring List
export const getHiringList = async () => {
    try {
        // Referencia a la colección 'candidates'
        const hiringListRef = collection(db, "hirings");
        
        // Ejecuta la consulta y obtiene los documentos
        const querySnapshot = await getDocs(hiringListRef);
        
        // Procesa y utiliza los datos como necesites
        let hirings:Array<User> = [];
        querySnapshot.forEach((doc) => {
            // Agrega los datos del documento a un array, incluyendo el ID del documento
            const hiring = doc.data() as User;
            hirings.push(hiring);
        });

        return hirings;  // Retorna los candidatos obtenidos
    } catch (error) {
        console.error("Error getting documents: ", error);
        // Manejo adecuado de errores
        return [];  // O maneja el error como creas conveniente
    }
};

// UPDATE
export const updateHiringLanguage = async (userIdHiring: string | undefined, lang: string) => {
    try {
        // Ensure the user ID for hiring is available
        if (!userIdHiring) {
            console.log("User ID for hiring is not provided");
            return { idHiring:userIdHiring,msg:'failed'};
        }

        // Reference to the specific document in the "hirings" collection
        const hiringDocRef = doc(db, 'hirings', userIdHiring);
        
        // Update the document with the new language
        await updateDoc(hiringDocRef, {
            language: lang // Assuming the field name is 'language'
        });

        return { idHiring:userIdHiring,msg:'success'};
    } catch (error) {
        console.error("Error updating hiring document: ", error);
        return { idHiring:userIdHiring,msg:'failed'};
        // Handle the error appropriately
    }
}

//-------------------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------------------//
//-----------------------------POSITION------------------------------------------//
export const getOpenPositions = async (idHiring: string) => {
    try {
        // Reference to the "positions" subcollection under a specific "hirings" document
        const positionsRef = collection(db, `hirings/${idHiring}/positions`);
        
        // Create the query for positions with status 'open'
        const q = query(positionsRef, where("status", "==", "open"));
        
        // Execute the query and get the documents
        const positionListSnapshot = await getDocs(q);

        // Process the documents as needed, for example, converting them to a simple array of data
        const positionList = positionListSnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        
        return positionList; // Or handle these documents as needed in your app

    } catch (error) {
        console.error("Error fetching open positions: ", error);
        return []; // Handle the error appropriately
    }
}

export const getRecommendationCandidate = async (hiringId: string, positionId: string, candidateId: string) => {
    try {
        // Reference to the "recommendationCandidates" subcollection under a specific "positions" document
        const recommendationCandidatesRef = doc(db, `hirings/${hiringId}/positions/${positionId}/recommendationCandidates`, candidateId);
        
        // Get the document
        const responseRecommendation = await getDoc(recommendationCandidatesRef);

        if (responseRecommendation.exists()) {
            return responseRecommendation.data(); // Return the document data
        } else {
            console.log("No such document!");
            return null; // Or handle the case where no document is found as needed
        }
    } catch (error) {
        console.error("Error fetching recommendation candidate: ", error);
        return null; // Handle the error appropriately
    }
}

export const getCompanyQRByCompanyId = async (companyId: string) => {
    try {
        // Reference to the "companyQR" collection
        const companyQRCollRef = collection(db, "companyQR");
        
        // Create the query for documents where 'idCompany' matches the provided company ID
        const q = query(companyQRCollRef, where("idCompany", "==", companyId));
        
        // Execute the query and get the documents
        const querySnapshot = await getDocs(q);

        // Process the documents as needed, for example, converting them to a simple array of data
        const companyQRData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        
        return companyQRData; // Or handle these documents as needed in your app

    } catch (error) {
        console.error("Error fetching company QR data: ", error);
        return []; // Handle the error appropriately
    }
}

export const createRecommendationCandidate = async (idHiring:string,idPosition:string,itemCandidateJobList:any)=>{
    try {
        const recommendationCandidateDocRef = doc(db, `hirings/${idHiring}/positions/${idPosition}/recommendationCandidates`, itemCandidateJobList.id);
    
    // Set the document with the new data
    setDoc(recommendationCandidateDocRef, {
        id: itemCandidateJobList.id,
        like: "",
        status: "",
    })
        return true;
    } catch (error) {
        return false;
    }
}