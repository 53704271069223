//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import { Col } from "antd";
import { useSelector } from "react-redux";
import StatusTextCard from "./StatusTextCard";

//Interface
interface Props {
  dataPosition?: {
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
    daysOld: number;
    declined: number;
    hired: number;
    interested: number;
    interview: number;
    meeting: number;
    waitingForReply: number;
    offer: number;
  };
}

//Component
const StatusCandidateCard: React.FC<Props> = ({ dataPosition }) => {
  // States
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <Col
      xs={24}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      xxl={12}
      style={{
        border: "1px solid",
        borderColor: "#24CECE",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        marginTop: "2%",
        paddingTop: "1.5%",
        paddingBottom: "0.5%",
        height: 245,
        backgroundColor: "#24CECE",
        paddingLeft: "1%",
        paddingRight: "1%",
      }}
    >
      <StatusTextCard
        text={dictionary["job-status-title-waiting-for-reply"]}
        count={
          dataPosition?.waitingForReply ? dataPosition?.waitingForReply : 0
        }
      />

      <StatusTextCard
        text={dictionary["job-status-title-interested"]}
        count={dataPosition?.interested ? dataPosition?.interested : 0}
      />

      <StatusTextCard
        text={dictionary["job-status-title-meeting"]}
        count={dataPosition?.meeting ? dataPosition?.meeting : 0}
      />

      <StatusTextCard
        text={dictionary["job-status-title-interview"]}
        count={dataPosition?.interview ? dataPosition?.interview : 0}
      />

      <StatusTextCard
        text={dictionary["job-status-title-offer"]}
        count={dataPosition?.offer ? dataPosition?.offer : 0}
      />

      <StatusTextCard
        text={dictionary["job-status-title-hired"]}
        count={dataPosition?.hired ? dataPosition?.hired : 0}
      />

      <StatusTextCard
        text={dictionary["job-status-title-declined"]}
        count={dataPosition?.declined ? dataPosition?.declined : 0}
      />
    </Col>
  );
};

//Export
export default StatusCandidateCard;
