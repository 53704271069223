//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Button, Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import alertIcon from "../../../image/iconExclamation.png";

//Interface
interface Props {
  visible: boolean;
  closeModal: () => void;
  updateJobPositionStatus: () => void;
}

//Component
const { Text } = Typography;

const NotificationUpdatePositionModal: React.FC<Props> = ({
  visible,
  closeModal,
  updateJobPositionStatus,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      open={visible}
      closable={false}
      centered
      onCancel={closeModal}
      width={600}
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={closeModal}
              style={{
                marginTop: 5,
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '20px',
                width: 150,
                background:
                  "linear-gradient(90deg, rgba(205,108,243,1) 0%, rgba(161,107,245,1) 100%)",
                color: "white",
              }}
            >
              {dictionary["editing-modal-confirm-change-button-cancel"]}
            </Button>

            <Button
              onClick={updateJobPositionStatus}
              style={{
                marginTop: 5,
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '20px',
                width: 150,
                background:
                  "linear-gradient(90deg, #24a6a8 0%, rgba(34,194,197,1) 100%)",
                color: "white",
              }}
            >
              {dictionary["buttonAccept-text-title1"]}
            </Button>
          </Row>
        </Row>
      }
    >
      <Content style={{ width: "100%", height: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Col
            xs={{ span: 21, offset: 1 }}
            xxl={{ span: 21, offset: 1 }}
            style={{ height: "100%" }}
          >
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="Icon exclamation"
                style={{ height: 30, width: 30 }}
                src={alertIcon}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["editing-modal-alert-close-position-parrafo1"]}
              </Text>
            </Row>

            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["editing-modal-alert-close-position-parrafo2"]}
              </Text>
            </Row>
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["editing-modal-alert-close-position-parrafo3"]}
              </Text>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            width: "80%",
            borderBottom: "solid 2px #c93234",
            marginLeft: "10%",
            marginTop: 30,
          }}
        ></div>
      </Content>
    </Modal>
  );
};

export default NotificationUpdatePositionModal;
