//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Button, Col, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import alertIcon from "../../../image/iconExclamation.png";
//Interface
interface Props {
  visible: boolean;
  closeModal: () => void;
  closeCreateJobModal: () => void;
}

//Component
const { Text } = Typography;

const NotificationCloseModal: React.FC<Props> = ({
  visible,
  closeModal,
  closeCreateJobModal,
}) => {
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      width={600}
      footer={
        <Row
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={closeModal}
              style={{
                marginTop: 5,
                borderBottomRightRadius: 15,
                borderTopLeftRadius: 15,
                width: 150,
                background:
                  "linear-gradient(90deg, rgba(205,108,243,1) 0%, rgba(161,107,245,1) 100%)",
                color: "white",
              }}
            >
              {dictionary["creation-modal-alert-close-button-cancel"]}
            </Button>

            <Button
              onClick={async () => {
                await closeModal();
                closeCreateJobModal();
              }}
              style={{
                marginTop: 5,
                borderBottomRightRadius: 15,
                borderTopLeftRadius: 15,
                width: 150,
                background:
                  "linear-gradient(90deg, #24a6a8 0%, rgba(34,194,197,1) 100%)",
                color: "white",
              }}
            >
              {dictionary["creation-modal-alert-close-button-yes"]}
            </Button>
          </Row>
        </Row>
      }
    >
      <Content style={{ width: "100%", height: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Col
            xs={{ span: 18, offset: 3 }}
            xxl={{ span: 18, offset: 3 }}
            style={{ height: "100%" }}
          >
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <img
                alt="Icon exclamation"
                style={{ height: 40, width: 40 }}
                src={alertIcon}
              />
            </Row>
            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 20,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: "bold", color: "#666" }}>
                {dictionary["creation-modal-alert-close-parrafo-1"]}
              </Text>
            </Row>

            <Row
              style={{
                width: "100%",
                paddingLeft: 10,
                marginTop: 10,
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {dictionary["creation-modal-alert-close-parrafo2"]}
              </Text>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            width: "80%",
            borderBottom: "solid 2px #c93234",
            marginLeft: "10%",
            marginTop: 30,
          }}
        ></div>
      </Content>
    </Modal>
  );
};

export default NotificationCloseModal;
