//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GraphicCard from "./Graphic/GraphicCard";
import FeedbackCard from "./Feedback/FeedbackCard";
import ActivityCard from "./Activity/ActivityCard";
import StatusPositionCard from "./StatusPosition/StatusPositionCard";
import { useNavigate } from "react-router-dom";
import {
  getDataDashboardValidated,
  startFetchDataDashboard,
} from "../../hooks/hooks";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/config";
import Spinner from "../../common/Spinner";

// Interface
interface Props {
  changePassword: Boolean;
}

// Component
const Home: React.FC<Props> = ({ changePassword }) => {
  const navigate = useNavigate();

  //States
  const [loadingDataDashboard, setLoadingDataDashboard] =
    useState<boolean>(true);

  const { user, token } = useSelector((state: any) => state.auth);
  const [dataDashboard, setDataDashboard] = useState<
    Array<{
      idPosition: string;
      name: string;
      like: number;
      dislike: number;
      remote: number;
      location: number;
      feedback: number;
      betterSalaryRange: number;
      level: number;
      daysOld: number;
      declined: number;
      hired: number;
      interested: number;
      interview: number;
      meeting: number;
      waitingForReply: number;
      offer: number;
    }>
  >([]);

  //Handlers
  const getDataDashboard = useCallback(
    async (jobList: Array<any>, token: string) => {
      setLoadingDataDashboard(true);
      try {
        if (token !== null) {
          const responseGetDataDashboard = await startFetchDataDashboard(token);
          if (responseGetDataDashboard === null) return null;

          const dataDashboardList = getDataDashboardValidated(
            responseGetDataDashboard,
            jobList
          );

          setDataDashboard(dataDashboardList);
          setLoadingDataDashboard(false);
        }
      } catch (error) {
        console.log(error);
        setLoadingDataDashboard(false);
        alert(`Error in function getDataDashboard: ${error}`);
      }
    },
    []
  );

  //Effects
  useEffect(() => {
    if (!user?.idHiring) {
      return;
    }

    // Reference to the "positions" subcollection
    const positionsRef = collection(db, `hirings/${user.idHiring}/positions`);
    const q = query(positionsRef, orderBy("title", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs: any = [];
      querySnapshot.forEach((docSnapshot) => {
        docs.push({ ...docSnapshot.data(), id: docSnapshot.id });
      });

      const result = docs.filter((d: any) => d.status === "open");
      getDataDashboard(result, token);
    });

    return () => unsubscribe();
  }, [user?.idHiring, getDataDashboard, token]);

  useEffect(() => {
    if (changePassword) {
      navigate("/changePassword");
    }
  }, [user, changePassword, navigate]);

  return (
    <Content style={{ height: "100%", width: "100%" }}>
      {loadingDataDashboard ? (
        <Spinner />
      ) : (
        <Row style={{ height: "100%", width: "100%" }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 12 }} style={{ height: '49%', display: 'flex', gap: '5px', flexDirection: 'column', paddingRight: '2px' }}>

            <GraphicCard dataDashboard={dataDashboard} />
            <FeedbackCard dataDashboard={dataDashboard} />
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
            style={{ height: '49%', display: 'flex', gap: '5px', flexDirection: 'column', paddingRight: '2px' }}
          >
            <StatusPositionCard
              dataDashboard={dataDashboard}
              loadingStatusCandidate={loadingDataDashboard}
            />
            <ActivityCard
              dataDashboard={dataDashboard}
              loadingTableCountDaysPosition={loadingDataDashboard}
            />
          </Col>
        </Row>
      )}
    </Content>
  );
};

//Export default
export default Home;
