import { Row, Col, Typography, Tooltip, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import InformativeModal from './informativePopup/InformativeModal';

const { Text } = Typography;

const HeaderRow: React.FC<{
    iconLogo: string;
    steps: "Initial" | "First Step" | "Second Step" | "Third Step" | "Final Step";
    data: any;
    dictionary: { [key: string]: string };

}> = ({ iconLogo, steps, data, dictionary }) => {

    // States
    const [isVisibleInformativeModal, setIsVisibleInformativeModal] = useState<boolean>(false);

    const [titleInformativeModal, setTitleInformativeModal] = useState<string>('');
    const [descriptionInformativeModal, setDescriptionInformativeModal] = useState<string>('');


    useEffect(() => {

        if (steps === 'Initial') {
            setTitleInformativeModal(dictionary['new-job-modal-informative-section-initial-text-1']);
            setDescriptionInformativeModal(dictionary['new-job-modal-informative-section-initial-description-1'])
        } else if (steps === 'First Step') {
            setTitleInformativeModal('');
            setDescriptionInformativeModal(dictionary['new-job-modal-informative-section-one-form-description-1'])
        } else {
            setTitleInformativeModal('');
            setDescriptionInformativeModal('')
        }
        return () => {

        }
    }, [steps, dictionary])

    return (
        <Row
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Row>
                    <div
                        style={{
                            height: 30,
                            width: 30,
                            backgroundColor: '#414193',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: 5,
                        }}
                    >
                        <img
                            src={iconLogo}
                            style={{ height: 30, width: 30 }}
                            alt='icon-dot'
                        />
                    </div>
                    <Text
                        style={{
                            color: '#414193',
                            fontSize: 21,
                            fontWeight: 'bold',
                            fontFamily: 'Muli',
                        }}
                    >
                        Career Swipe
                    </Text>
                </Row>
            </Col>

            <Col
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Tooltip title='Help'>
                    {
                        steps === 'Initial' ?
                            <>
                                <Button
                                    type='link'
                                    icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => setIsVisibleInformativeModal(true)}
                                    style={{ color: '#414193', marginRight: 10 }}
                                />
                                <InformativeModal title={titleInformativeModal}
                                    description={descriptionInformativeModal} setVisible={setIsVisibleInformativeModal} visible={isVisibleInformativeModal} />
                            </> : steps === 'First Step' &&
                            <>
                                <Button
                                    type='link'
                                    icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />}
                                    onClick={() => setIsVisibleInformativeModal(true)}
                                    style={{ color: '#414193', marginRight: 10 }}
                                />
                                <InformativeModal title={titleInformativeModal}
                                    description={descriptionInformativeModal} setVisible={setIsVisibleInformativeModal} visible={isVisibleInformativeModal} />
                            </>
                    }
                </Tooltip>
                <Text
                    style={{
                        color: '#414193',
                        fontSize: 21,
                        fontWeight: 'bold',
                        fontFamily: 'Muli',
                    }}
                >
                    {steps === 'Initial'
                        ? ''
                        : steps === 'First Step'
                            ? data !== undefined
                                ? dictionary['editing-modal-title']
                                : dictionary['creation-modal-page-one-header-title']
                            : steps === 'Second Step'
                                ? data !== undefined
                                    ? dictionary['editing-modal-title']
                                    : dictionary['creation-modal-page-one-header-title']
                                : steps === 'Third Step'
                                    ? data !== undefined
                                        ? dictionary['editing-modal-title']
                                        : dictionary['creation-modal-page-one-header-title']
                                    : dictionary['creation-modal-title-last-step']}
                </Text>
            </Col>
        </Row>
    );
};

export default HeaderRow;
