//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import {  Upload, message } from "antd"
import React  from 'react'
import iconImage from './../image/icon-message-image.png';
import ImageButton from "./ImageButton";

// Interfaces
interface Props{
    setImage:any
}

const UploadButtonImage:React.FC<Props> = ({setImage})=>{
    // Handlers
    const beforeUpload =(file:any)=>{
        
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        
        !isJpgOrPng && message.error('You can only upload JPG/PNG file!');
        
        const isLt2M = file.size / 1024 / 1024 < 2;

        !isLt2M && message.error('Image must smaller than 2MB!');
        
        return isJpgOrPng && isLt2M;
    }

    const getBase64 = (img:any, callback:any) =>{
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }
   
    const handleChange = (info:any)=>{
        getBase64(info.file.originFileObj,(imageUrl:any) =>{setImage({name:info.file.name,url:imageUrl})})
    }
    
    return(
        <Upload 
            name="image"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            showUploadList={false}
        >
            <ImageButton height={36} width={38} iconImage={iconImage}/>
        </Upload>
    )
}

export default UploadButtonImage;