// Dependencies
import { createSlice } from "@reduxjs/toolkit";

export const jobSlice = createSlice({
  name: "job",
  initialState: {
    userSelected: {
      name: "",
      lastName: "",
      status: "",
    },
  },
  reducers: {
    getUserSelected: (state, action) => {
      state.userSelected = action.payload;
    },
    clearUserSelected: (state) => {
      state.userSelected = {
        name: "",
        lastName: "",
        status: "",
      };
    },
  },
});

export const { getUserSelected, clearUserSelected } = jobSlice.actions;
