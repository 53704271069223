//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React from "react";
import { Col, Row, Typography } from "antd";
import TitlePositionCol from "./TitlePositionCol";

//Interface
interface Props {
  dataDashboard: Array<{
    idPosition: string;
    name: string;
    like: number;
    dislike: number;
    remote: number;
    location: number;
    betterSalaryRange: number;
    level: number;
  }>;
}

//Component
const FeedbackTable: React.FC<Props> = ({ dataDashboard }) => {
  //States
  const { Text } = Typography;

  return (
    <>
      {dataDashboard.map((position, index) => (
        <Row
          key={`dashFeedTable${position.level}+ ${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: index > 0 ? 3 : 0,
            paddingTop: 8,
            paddingBottom: index === dataDashboard.length - 1 ? 8 : 0, // Add paddingBottom for the last item only
            borderBottomLeftRadius: index === dataDashboard.length - 1 ? 10 : 0, // Add bottom-left border radius for the last item only
            borderBottomRightRadius:
              index === dataDashboard.length - 1 ? 10 : 0, // Add bottom-right border radius for the last item only
            backgroundColor:
              index === 1 || index === 3 || index === 5 ? "#57D8D8" : "#B0ECEC",
            height: 45,
          }}
        >
          <TitlePositionCol name={position.name} />

          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
              >
                <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
                  {" "}
                  {position.betterSalaryRange}{" "}
                </Text>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
              >
                <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
                  {" "}
                  {position.location}
                </Text>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
              >
                <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
                  {" "}
                  {position.remote}
                </Text>
              </Col>
              <Col
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
              >
                <Text style={{ fontSize: 17, fontFamily: "Muli" }}>
                  {position.level}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </>
  );
};

//Export
export default FeedbackTable;
