import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import { Button, Table, Typography, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './index.css'

import FormHeaderInput from './FormHeaderInput';
const { Text } = Typography
interface DataType {
  index: number;
  value: string;
}




interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowTable = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined

                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface Props {
  dataSource: Array<any>;
  setDataSource: (value: any) => void;
  title: string
}
const TableDraw: React.FC<Props> = ({ dataSource, setDataSource, title }) => {

  // States
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [idEditValue, setIdEditValue] = useState<number>(0);
  const [currentEditValue, setCurrentEditValue] = useState<string>("");

  // Config Table
  const columns: ColumnsType<DataType> = [
    {
      key: 'sort',
    },
    {
      title: 'value',
      dataIndex: 'value',
      className: 'column-text',
      render: (_, render) => (
        <Text style={{
          color: isEditing ?
            render.index === idEditValue ?
              '#00000050' : 'black' : 'black', justifyItems: 'flex-start'
        }}>{render.value}</Text>
      )
    }, {
      dataIndex: 'action',
      className: 'column-buttons',
      render: (_, render) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            disabled={render.index === idEditValue ? isEditing : false}
            onClick={() => {
              setIdEditValue(render.index);
              setIsEditing(true);
              setCurrentEditValue(render.value);
            }}
            style={{
              backgroundColor:
                isEditing ?
                  render.index === idEditValue ?
                    '#00000050' :
                    'gray' :
                  'gray',
              borderRadius: 40,

            }} icon={<EditOutlined style={{ color: 'white' }} />} />
          <Button
            disabled={render.index === idEditValue ? isEditing : false}
            onClick={() => deleteField(dataSource, render.index)}
            style={
              {
                backgroundColor: isEditing ?
                  render.index === idEditValue ?
                    '#00000050' : 'red' : 'red', borderRadius: 40
              }} icon={<DeleteOutlined style={{ color: 'white' }} />} />
        </div>
      )
    }
  ];
  // States

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous: any) => {
        const activeIndex = previous.findIndex((i: any) => i.index === active.id);
        const overIndex = previous.findIndex((i: any) => i.index === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    const newList = [];
    dataSource?.forEach((data, index) => {
      newList.push({
        index: index,
        value: data.value
      })
    })
  }, [dataSource]);

  const deleteField = (list: Array<any>, id: number) => {
    const deleteValue = list.filter((list) => list.index !== id);
    setDataSource(deleteValue)
  }

  const handleUpdateField = (value: string, list: Array<any>, index: number) => {
    const deleteValue = list.filter((list) => list.index !== index);

    const idField = index;
    const newField = {
      index: idField,
      value: value
    };
    const newList = [...deleteValue, newField];

    const listSorted = newList.sort((a, b) => a.index - b.index);
    setIsEditing(false)
    setIdEditValue(0)
    setCurrentEditValue("");
    setDataSource(listSorted)
  }


  return (
    <Row className='container-table-draw'>
      <FormHeaderInput currentEditValue={currentEditValue} handleUpdateField={handleUpdateField} idCurrentEditValue={idEditValue} isEditing={isEditing} list={dataSource} setIdCurrentEditValue={setIdEditValue} setList={setDataSource} title={title} />

      <div style={{ border: '1px solid #00000040', padding: 5, borderRadius: 16, width: '100%' }}>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.index)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              pagination={false}
              components={{
                body: {
                  row: RowTable,
                },
              }}
              showHeader={false}
              rowKey="index"
              columns={columns}
              scroll={{ x: 'max-content' }}
              dataSource={dataSource}

            />
          </SortableContext>
        </DndContext>
      </div>
    </Row>
  )
}

export default TableDraw