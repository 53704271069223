// Dependencies
import { createSlice } from '@reduxjs/toolkit';


export const chatSlice = createSlice({
    name:'chat',
    initialState:{
        counterMessages: 0,
        filterJob: "",
        positionList: [],
        positionSelected: {
            idHiring: "",
            idPosition: "",
            title: "",
            status: "",
          },
        messageList: [],
        userChatSelected: {
            id: "",
            name: "",
            lastName: "",
            },
        loading:false,
    },
    reducers:{
        getFilterJob:( state, action ) => { //types.selectJobFilter
            state.filterJob=action.payload;
        },
        clearFilterJob:( state)=>{ //types.clearJobFilter
            state.filterJob="";
        },
        getPositionSelect: (state,action) => { //types.getPositionMessageSelected
            state.positionSelected=action.payload;
        },
        clearPositionSelect: (state) => { //types.clearPositionMessageSelected
            state.positionSelected={
                idHiring: "",
                idPosition: "",
                title: "",
                status: "",
              };
        },
        getUserChatSelected: ( state, action) =>{ // types.loadChatSelected
            state.userChatSelected = action.payload;
        },
        clearUserChatSelected: ( state ) =>{ //types.clearChatSelected
            state.userChatSelected = {
                id: "",
                name: "",
                lastName: "",
                };
        },
        getCandidateListByJob: ( state, action)=>{ //types.getCandidateMessageList
            state.messageList= action.payload;
        },
        clearMessageList: ( state)=>{ //CLEAR_MESSAGE_LIST
            state.messageList= [];
        },
        getCounterMessages: (state,action)=>{ //COUNTER_SUCCESS
            state.counterMessages= action.payload;
        },
        clearCounterMessages: (state )=>{
            state.counterMessages= 0;
        },
        getPositionList: ( state, action ) => { //POSITION_LIST_SUCCESS
            state.positionList = action.payload;
        },
        clearPositionList: (state)=>{ //CLEAR_POSITION_LIST
            state.positionList=[];
        },
        getMessageInboxList: ( state, action )=>{ //MESSAGES_LIST_SUCCESS
            state.messageList = action.payload;
        },
        changeLoadingChat:(state,action)=>{
            state.loading = action.payload
        }
        
    }
});

export const { clearCounterMessages,clearFilterJob,clearMessageInboxList,clearPositionList,clearPositionSelect,clearUserChatSelected,getCandidateListByJob,getCounterMessages,getFilterJob,getMessageInboxList,getPositionList,getPositionSelect,getUserChatSelected,clearMessageList,changeLoadingChat } = chatSlice.actions;