//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React from "react";

import MessageDetail from "./MessageDetail";
import { useSelector } from "react-redux";
import { Typography } from "antd";
import { getTranslation } from "../../../hooks/hooks";
import Spinner from "../../../common/Spinner";

const { Text } = Typography;
// Components
const MessagesList: React.FC = () => {
  // States
  const {
    messageList,
    filterJob,
    loading,
  }: { messageList: Array<any>; filterJob: any; loading: boolean } =
    useSelector((state: any) => state.chat);
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <div
      className="contenedor"
      style={{ width: "100%", height: "100%", overflow: "auto" }}
    >
      {loading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Spinner
            title={getTranslation(
              "message-header-chat-text-loading",
              dictionary
            )}
          />
        </div>
      ) : messageList.length > 0 ? (
        messageList.map((candidate: any, index: number) => (
          <MessageDetail key={index + "message"} candidate={candidate} />
        ))
      ) : (
        messageList.length === 0 &&
        filterJob !== "" && (
          <div
            style={{
              height: "60%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Text
              style={{ fontSize: 17, fontFamily: "Muli", textAlign: "center" }}
            ></Text>
          </div>
        )
      )}
    </div>
  );
};

// Export
export default MessagesList;
