//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Button, Input, Form, Spin, Col, Row, Typography } from "antd";
import { GlobalOutlined } from '@ant-design/icons';
import iconIRGT from "../../image/inrgt-logo@3x.png";
import { useDispatch, useSelector } from "react-redux";
import NotificationLogin from "../../common/modals/notificationModal/NotificationLogin";
import MensajeIngles from "../../lang/diccionary-en.json";
import MensajeFrance from "../../lang/diccionary-fr.json";
import { API_RECAPTCHA_SITE_KEY } from "../../api/secrets";
import ReCAPTCHA from "react-google-recaptcha";
import { getTranslation, verificationTokenGoogle } from "../../hooks/hooks";
import { checkingCredentials, clearError, getError, startSignInWithEmailAndPassword } from "../../store/auth";
import { getDictionary, getLanguage } from "../../store/ui";
import { StateGeneralRedux } from "../../types/redux";
import './index.css'
import { useNavigate } from "react-router-dom";
// Component
const { Text } = Typography;

// Component
const LoginForm: React.FC = () => {

  // Recaptcha Google
  const reCaptchaRef = useRef<any>();
  const navigate = useNavigate();
  // Redux Store
  const dispatch: Dispatch<any> = useDispatch();
  const { language, dictionary } = useSelector((state: StateGeneralRedux) => state.ui);
  const { loading, error } = useSelector((state: StateGeneralRedux) => state.auth);

  //States
  const [emailInput, setEmailInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [captchaDone, setCaptchaDone] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  // Handlers

  // Cambiar el idioma del usuario
  const handleOnChangeLanguage = () => {
    if (language === "English") {
      dispatch(getLanguage("Français"));
      localStorage.setItem("lang", "Français");
      dispatch(getDictionary(MensajeFrance));
    } else {
      dispatch(getLanguage("English"));
      localStorage.setItem("lang", "English");
      dispatch(getDictionary(MensajeIngles));
    }
  };

  const onChange = (value: string | null) => {
    setCaptchaDone(true);
    setToken(value);
  };

  // Iniciar sesion
  const handleOnStartLogInApp = async () => {
    try {
      dispatch(checkingCredentials(true));
      // Veritificacion de token
      const verificationTokenGoogleResponse = await verificationTokenGoogle(token);
      if (!verificationTokenGoogleResponse) {
        return dispatch(getError("Recaptcha verification required"));
      }

      dispatch(
        startSignInWithEmailAndPassword({
          email: emailInput,
          password: passwordInput,
          dictionary: dictionary,
        })
      );
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (error !== null) {
      reCaptchaRef.current.reset();
      setVisibleModal(true);
    }
  }, [error]);

  return (
    <>
      <Col className="container-login-card">
        <NotificationLogin visible={visibleModal} closeModal={() => { setVisibleModal(false); dispatch(clearError()); }} message={error ? error : ""} />
        <Row className="row-icon-irgt">
          <img
            src={iconIRGT}
            className="icon-irgt-logo"
            alt={iconIRGT}
          />
        </Row>

        <Row className="row-title-login-form">
          <Typography.Text className="text-title-login-form">{getTranslation("login-text-title", dictionary)}</Typography.Text>
        </Row>

        <Row style={{ width: "100%", padding: '0px 30px', justifyContent: 'center', marginBottom: '30px' }}>
          <Spin spinning={loading} size="large">
            <Form size="large">
              <div style={{ width: "100%", height: "100%", marginTop: "10%" }}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message:
                        getTranslation("loginMessageAlert-text-title2", dictionary),
                    },
                    {
                      required: true,
                      message:
                        getTranslation("loginMessageAlert-text-title1", dictionary),
                    },
                  ]}
                >
                  <Input
                    placeholder={
                      getTranslation("login-input-placeholder-email", dictionary)
                    }
                    onChange={(e) => setEmailInput(e.target.value)}
                    size="middle"
                    style={{
                      fontFamily: "Muli",
                      borderRadius: 10,
                      width: "100%",
                      backgroundColor: 'white'
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message:
                        getTranslation("loginMessageAlert-text-title3", dictionary),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={
                      getTranslation("login-input-placeholder-password", dictionary)
                    }
                    onChange={(e) => setPasswordInput(e.target.value)}
                    size="middle"
                    style={{ fontFamily: "Muli", borderRadius: 10 }}
                  />
                </Form.Item>

                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={API_RECAPTCHA_SITE_KEY}
                  onChange={onChange}
                  style={{ marginBottom: 20 }}
                />
              </div>
            </Form>
          </Spin>
        </Row>


        <Button
          onClick={handleOnStartLogInApp}
          type="primary"
          disabled={!captchaDone}
          htmlType="submit"
          style={{
            width: "70%",
            backgroundColor: !captchaDone ? '#a9a9a9' : '#404193', // Gris claro cuando está deshabilitado
            color: '#ffffff', // Texto blanco cuando está deshabilitado
            fontSize: 22,
            height: '70px',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            fontFamily: "Muli",
            position: 'absolute',
            bottom: '-30px',
            left: '60px'
          }}
        >
          {getTranslation("login-text-title", dictionary)}

        </Button>



      </Col>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: '30px'
        }}
      >
        <Row style={{ width: "100%", justifyContent: "center", height: "10%" }}>
          <Col>
            <Text
              style={{ fontSize: 18, cursor: "pointer" }}
            >
              {language === "English" ? "Don't have a Career Swipe account?" : "Vous n’avez pas de compte Career Swipe ? "}
            </Text>
            <Text style={{ fontSize: 18, cursor: "pointer", color: '#5A5AD1', textDecoration: 'underline' }} onClick={() => navigate('/register')}> {language === "English" ? "Sign up now" : "S’inscrire maintenant"}</Text>
          </Col>
        </Row>
      </div>

      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          marginTop: '10px'
        }}
      >
        <Row style={{ width: "100%", justifyContent: "center", height: "10%" }}>
          <Col style={{ gap: '5px', display: 'flex', flexDirection: 'row' }}>
            <GlobalOutlined />
            <Text
              style={{ fontSize: 18, cursor: "pointer" }}
              onClick={handleOnChangeLanguage}
            >
              {language === "English" ? "Français" : "English"}
            </Text>
          </Col>
        </Row>
      </div >
    </>
  );
};

// Exports
export default LoginForm;
