//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
// Importaciones de Ant Design
import { Col, Row } from "antd"; // Importa los componentes Col y Row para el diseño de la cuadrícula.
import { Content } from "antd/lib/layout/layout"; // Importa Content para el contenedor principal del layout.

// Importación de React
import React from "react"; // Importa React para utilizar JSX y crear el componente.

// Importaciones Locales
import { Job } from "../../../types/position"; // Importa un tipo personalizado para manejar los datos de la posición.
import JobDescriptionCard from "./JobDescriptionCard"; // Importa el componente JobDescriptionCard para mostrar los detalles de la posición.

// Interface
// Define las props que acepta el componente.
interface Props {
  jobPositionData: Job; // Prop 'jobPositionData', del tipo PositionResume, para pasar los datos de la posición.
}

// Componente
// Define el componente JobDescriptionTab como una función que acepta Props y devuelve JSX.
const JobDescriptionTab: React.FC<Props> = ({ jobPositionData }) => {
  return (
    /*  Contenedor principal con estilo específico para altura y ancho. */
    <Content style={{ height: '100%', width: '100%' }}>
      {/* Diseño de fila (Row) que ocupa toda la altura y ancho disponibles. */}
      <Row style={{ height: '100%', width: "100%" }}>
        {/* Columna (Col) que ocupa todo el espacio disponible en dispositivos de cualquier tamaño. */}
        <Col xs={{ span: 24 }} span={24} style={{ height: '100%' }}>
          {/* Componente JobDescriptionCard que muestra los detalles de la posición usando los datos proporcionados. */}
          <JobDescriptionCard jobPositionData={jobPositionData} />
        </Col>
      </Row>
    </Content>
  );
};

// Exportación
// Hace disponible el componente JobDescriptionTab para su uso en otros archivos.
export default JobDescriptionTab;
