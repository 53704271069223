// Dependencies
import { Col, Row, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import ChatList from './ChatList';
import InputSendMessage from './InputSendMessage';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../../hooks/hooks';

const { Text } = Typography;
// Component
const Chat:React.FC = () => {
  const {dictionary} = useSelector((state:any)=>state.ui);
  return (
    <Content style={{height:'100%',width:'100%'}}>
       <Row style={{height:'8%'}}>
        <Text style={{fontSize:40,fontFamily:'Muli',fontWeight:'bold'}}>{getTranslation('support-chat-header-title',dictionary)}</Text>

       </Row>
       
       <Row style={{ width:'100%',border:'1px solid #00000010',borderRadius:10,height: '10%',marginBottom:'1%',boxShadow: "2px 2px 10px #00000010",padding:10}}>
            <Col  style={{display:'flex',alignItems:'center'}}>
                <Text style={{fontFamily:'Muli'}}>{getTranslation('support-chat-body-title',dictionary)}:</Text>
            </Col>
            <Col  style={{display:'flex',alignItems:'center',marginLeft:10}}>
                <Text style={{fontFamily:'Muli'}}>Administrator</Text>
            </Col>
        </Row>

        <Row style={{ width:'100%',border:'1px solid #00000020',borderRadius:10,height: '66%',marginBottom:'1%',boxShadow: "2px 2px 10px #00000010"}}>
            <ChatList />
        </Row>

        <Row style={{ width:'100%',height: '15%'}}>
           <InputSendMessage />
        </Row>
    </Content>
  )
}

export default Chat