//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MensajeIngles from '../../../../../lang/diccionary-en.json';
import MensajeFrance from '../../../../../lang/diccionary-fr.json';

import TableDraw from '../../../../tableDraw/TableDraw';
import TableDrawSkill from '../../../../tableDrawSkill/TableDrawSkill';

//Interface
interface Props {
  requirements: Array<any>;
  setRequirements: (value: Array<any>) => void;
  isEditing: boolean;
  skills: Array<any>;
  setSkills: (value: Array<any>) => void;
}

// Component
const ThirdStepCreationJob: React.FC<Props> = ({ requirements, setRequirements, isEditing, skills, setSkills }) => {

  //States Requirements
  const { language } = useSelector((state: any) => state.ui);
  const [messageTranslate, setMessageTranslate] = useState<any>(MensajeIngles);
  // States Skills
  /*     const validateExistFieldInDatabase = async (value:any, list:any) => {
        try {
            const skillList:any = [];
    
            const skillTitlesQuery = query(collectionGroup(db, "skillTitles"));
            const response = await getDocs(skillTitlesQuery);
    
            response.docs.forEach((each) => {
                if (each.data().nameSkill.length > 0) {
                    skillList.push(each.data().nameSkill);
                }
            });
    
            const exist = skillList.find((a:any) => a === value);
    
            if (!exist) {
                const id = uid(30); // Ensure uid function is defined or imported
                const newSkillRef = doc(db, "onBoarding", "skills", "skillTitles", id);
                
                await setDoc(newSkillRef, {
                    function: ["miscellaneous"],
                    sector: ["miscellaneous"],
                    level: ["miscellaneous"],
                    language: language, // Ensure 'language' variable is defined in your context
                    id: id,
                    verified: false,
                    nameSkill: value
                });
            }
        } catch (error) {
            console.log("Error in validateExistFieldInDatabase:", error);
        }
    } */
  useEffect(() => {
    if (language === 'English') {
      setMessageTranslate(MensajeIngles);
    } else {
      //@ts-ignore
      setMessageTranslate(MensajeFrance)
    }
  }, [language]);

  return (
    <Content style={{ minHeight: "75vh", width: "100%", overflow: "auto", padding: "3%", backgroundColor: "#f4f7fc" }}>
      <Row style={{ width: "100%", padding: "1%" }}>
        <TableDraw
          dataSource={requirements}
          setDataSource={setRequirements}
          title={messageTranslate["creation-modal-page-three-field-requirements"]}
        />
      </Row>

      <Row style={{ width: "100%", display: "flex", padding: "1%" }}>
        <TableDrawSkill
          title={messageTranslate["creation-modal-page-three-field-skill"]}
          dataSource={skills}
          setDataSource={setSkills}
        />
      </Row>
    </Content>
  );
}

// Exports
export default ThirdStepCreationJob;