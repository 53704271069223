import { db } from "../config";
import { collection, query, where, getDocs } from 'firebase/firestore';

export const getJobsCandidateById = async (hiringId: string, candidateId: string) => {
    try {
        const recommendationList: any = [];
        const positionListOpen: any = [];

        // Consultar posiciones abiertas
        const positionsRef = collection(db, 'hirings', hiringId, 'positions');
        const openPositionsQuery = query(positionsRef, where('status', '==', 'open'));
        const positionSnapshots = await getDocs(openPositionsQuery);
        positionSnapshots.docs.forEach((doc) => {
            positionListOpen.push(doc.data().idPosition);
        });

        // Consultar recomendaciones
        const recommendationsRef = collection(db, 'candidates', candidateId, 'recommendation');
        const recommendationsQuery = query(
            recommendationsRef,
            where('type', '==', 'job'),
            where('like', '==', true),
            where('idHiring', '==', hiringId),
            where('status', '==', 'open')
        );
        const recommendationSnapshots = await getDocs(recommendationsQuery);
        recommendationSnapshots.docs.forEach((doc) => {
            const result = positionListOpen.find((idPosition: string) => idPosition === doc.data().idPosition);
            if (result !== undefined) {
                recommendationList.push(doc.data());
            }
        });

        return recommendationList;
    } catch (error) {
        console.error('Error fetching job candidates:', error);
        return [];
    }
};
