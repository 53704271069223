
import {  signOut,signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './config';

export const loginWithEmailPassword = async(email, password) => {
  try {
    // Realiza el inicio de sesión con email y contraseña.
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (error) {
    // Puedes retornar el error o manejarlo como prefieras.
    // Por ejemplo, puedes retornar un mensaje de error específico o un código de error.
    return error;
  }
};

export const logoutFirebase = async() => {
  // Elimina los datos del usuario del almacenamiento local.
  localStorage.removeItem('userData');
  
  // Cierra la sesión en Firebase Auth.
  try {
    await signOut(auth);
    // Aquí puedes manejar acciones adicionales post-cierre de sesión si lo necesitas.
  } catch (error) {
    // Manejar errores aquí, como mostrar un mensaje de error.
    console.error("Error signing out: ", error);
  }
}