import { Button, Result } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';
import { StateGeneralRedux } from '../../../../types/redux';

// Interface 
interface Props {
    setStep: (value: 'Initial' | 'VerificationEmail' | 'completeRegistration' | 'Error' | 'Loading') => void
}

const RegisterError: React.FC<Props> = ({ setStep }) => {
    const { dictionary } = useSelector((state: StateGeneralRedux) => state.ui);

    return (
        <div className='loading-register-form'>
            <Result
                status='error'
                title={dictionary['register-error-title']}

                extra={
                    <Button type="primary" key="console" onClick={() => setStep('Initial')}>
                        {dictionary['register-error-go-back-button']}
                    </Button>
                }
            />
        </div>
    )
}

export default RegisterError
