//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Col, Input, Row, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { useSelector } from 'react-redux';
import TableDraw from '../../../tableDraw/TableDraw';
// Component Ant
const { Text } = Typography;
const { TextArea } = Input;
//Interface
interface Props {
  description: string;
  setDescription: (description: string) => void;
  isEditing: boolean;
  responsibilities: Array<{ index: number, value: string }>;
  setResponsibilities: (value: Array<{ index: number, value: string }>) => void;
}

// Component
const SecondStepCreationJob: React.FC<Props> = ({ description, setDescription, isEditing, responsibilities, setResponsibilities }) => {

  // States
  const { dictionary } = useSelector((state: any) => state.ui);

  return (
    <Content
      className="contenedor"
      style={{
        minHeight: "75vh",
        width: "100%",
        overflowY: "auto",
        padding: "3%",
        backgroundColor: "#f4f7fc",
      }}
    >
      <Row style={{ width: "100%", display: "flex", padding: "1%" }}>
        <Col xs={24} sm={24} md={24} lg={5} xl={4} xxl={3} >
          <Text style={{ fontSize: 20, fontFamily: "Muli" }}>
            {dictionary["creation-modal-page-two-field-job-summary"]}{" "}
            <Text style={{ color: "#b90000" }}>*</Text>
          </Text>
        </Col>

        <Col xs={20} sm={24} md={24} lg={19} xl={20} xxl={21} style={{ boxSizing: 'border-box', paddingLeft: '18px' }} >
          <div style={{ width: '100%', padding: '7px 4px', backgroundColor: 'white', borderRadius: '20px', border: '1px solid #00000026', boxShadow: "4px 1px 6px #00000020", boxSizing: 'border-box', paddingBottom: '25px', paddingRight: '5px' }}>
            <TextArea
              count={{
                show: true,
                max: 500,
              }}
              value={description}
              style={{ width: "100%", border: 'transparent', fontFamily: "Muli" }}
              size="middle"
              showCount
              allowClear
              rows={3}
              placeholder={
                dictionary["creation-modal-page-two-placeholder-job-summary"]
              }
              onChange={(e) => setDescription(e.target.value)}
            />

          </div>
        </Col>
      </Row>

      <Row style={{ width: "100%", display: "flex", padding: "1%", marginTop: 40, }}>
        <TableDraw dataSource={responsibilities} setDataSource={setResponsibilities} title={dictionary["creation-modal-page-two-field-responsibilities"]} />
      </Row>
    </Content>
  );
}

// Exports
export default SecondStepCreationJob;