//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import {
  AutoComplete,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Typography,
  Radio,
  InputNumber,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useCallback, useEffect, useState } from "react";
import JobTypeCheckBox from "./JobTypeCheckBox";
import WorkplaceCheckBox from "./WorplaceCheckBox";
import LocationAutoComplete from "./LocationAutoComplete";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../../../hooks/hooks";
import { collectionGroup, getDocs, query } from "firebase/firestore";
import { db } from "../../../../../firebase/config";
//Interface
interface Props {
  titleInput: string;
  setTitle: (title: string) => void;
  type: Array<string>;
  setType: (typeList: Array<string>) => void;
  location: any;
  setLocation: (location: any) => void;
  workplace: Array<string>;
  setWorkplace: (workplace: Array<string>) => void;
  setSalary: (salary: string) => void;
  setPlaces: (place: any) => void;
  salary: string;
  totalSalary: string;
  salaryMax: string;
  setSalaryMax: (salaryMax: string) => void;
  setTotalSalary: (value: string) => void;
  checkHourly: boolean;
  setCheckHourly: (value: boolean) => void;
  checkAnnual: boolean;
  setCheckAnnual: (value: boolean) => void;
  checkAmount: boolean;
  setCheckAmount: (value: boolean) => void;
  checkMin: boolean;
  setCheckMin: (value: boolean) => void;
  checkMax: boolean;
  setCheckMax: (value: boolean) => void;
  checkMiddle: boolean;
  setCheckMiddle: (value: boolean) => void;
  checkBonnus: boolean;
  setCheckBonnus: (value: boolean) => void;
  checkPercentage: boolean;
  setCheckPercentage: (value: boolean) => void;
  update: boolean;
  updatePositionStatus: (status: "open" | "close") => void;
  otherDetailsCompensation: string;
  setOtherDetailsCompensation: (value: string) => void;
  valueHourForWeek: number;
  setValueHourForWeek: (value: any) => void;
}
const { Text } = Typography;
const { TextArea } = Input;

// Component
const FirstStepCreationJob: React.FC<Props> = ({
  titleInput,
  setTitle,
  setType,
  type,
  location,
  setPlaces,
  setLocation,
  workplace,
  setWorkplace,
  setSalary,
  salary,
  checkHourly,
  setCheckHourly,
  checkMin,
  setCheckMin,
  checkMax,
  setCheckMax,
  checkMiddle,
  setCheckMiddle,
  checkBonnus,
  setCheckBonnus,
  setSalaryMax,
  salaryMax,
  checkAmount,
  setCheckAmount,
  checkAnnual,
  setCheckAnnual,
  checkPercentage,
  setCheckPercentage,
  totalSalary,
  setTotalSalary,
  update,
  updatePositionStatus,
  otherDetailsCompensation,
  setOtherDetailsCompensation,
  setValueHourForWeek,
  valueHourForWeek,
}) => {
  // States Compensation
  const { dictionary, language } = useSelector((state: any) => state.ui);
  const [result, setResult] = useState(0);

  const [titleList, setTitleList] = useState<
    Array<{ value: string; label: string; predeterminated: string }>
  >([]);
  //Handlers
  const saveJobType = useCallback((typeList: Array<string>) => {
    setType(typeList);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveWorkplace = useCallback((arrayString: Array<string>) => {
    setWorkplace(arrayString);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHourly = () => {
    setCheckHourly(!checkHourly);
    checkAnnual === true && setCheckAnnual(false);
  };

  const onChangeAmount = () => {
    setCheckAmount(!checkAmount);
    checkPercentage === true && setCheckPercentage(false);
  };

  const onChangePercentage = () => {
    setCheckPercentage(!checkPercentage);
    checkAmount === true && setCheckAmount(false);
  };

  const onChangeAnnual = () => {
    setCheckAnnual(!checkAnnual);
    if (checkHourly === true) {
      setCheckHourly(false);
    }
  };
  const onChangeMin = () => {
    if (checkMin !== true) {
      setCheckMin(true);
      setCheckMiddle(false);
      setCheckMax(false);
    }
  };
  const onChangeMiddle = () => {
    if (checkMiddle !== true) {
      setCheckMin(false);
      setCheckMiddle(true);
      setCheckMax(false);
    }
  };
  const onChangeMax = () => {
    if (checkMax !== true) {
      setCheckMin(false);
      setCheckMiddle(false);
      setCheckMax(true);
    }
  };
  const onChangeBonnus = () => {
    setCheckBonnus(!checkBonnus);
  };

  useEffect(() => {
    if (
      salary.trim() === "" ||
      salaryMax.trim() === "" ||
      parseFloat(salaryMax.replace(/,/g, "")) <=
      parseFloat(salary.replace(/,/g, ""))
    ) {
      setResult(0);
      return;
    }

    const numMin = parseFloat(salary.replace(/,/g, ""));
    const numMax = parseFloat(salaryMax.replace(/,/g, ""));
    const numMid = (numMin + numMax) / 2;

    let numero1: number =
      checkMin === true
        ? numMin
        : checkMax === true
          ? numMax
          : checkMiddle === true
            ? numMid
            : 0;
    let numero2: number = parseFloat(totalSalary.replace(/,/g, ""));

    var numeroResult: number = 0;

    if (
      checkHourly === true &&
      checkAmount === false &&
      checkPercentage === false
    ) {
      numeroResult = numero1 * valueHourForWeek * 50 + 0;
      setResult(numeroResult);
    } else {
      if (checkHourly === true) {
        if (checkAmount === true) {
          numeroResult =
            numero1 * valueHourForWeek * 50 +
            (checkBonnus === true ? numero2 : 0);
          setResult(numeroResult);
        } else {
          if (checkPercentage === true) {
            numeroResult = numero1 * valueHourForWeek * 50;
            numeroResult =
              numeroResult +
              (checkBonnus === true ? (numero2 * numeroResult) / 100 : 0);
            setResult(numeroResult);
          }
        }
      }
    }

    if (
      checkAnnual === true &&
      checkAmount === false &&
      checkPercentage === false
    ) {
      numeroResult = numero1;
      setResult(numeroResult);
    } else {
      if (checkAnnual === true) {
        if (checkAmount === true) {
          numeroResult = numero1 + (checkBonnus === true ? numero2 : 0);
          setResult(numeroResult);
        } else {
          if (checkPercentage === true) {
            numeroResult = numero1;
            numeroResult =
              numero1 +
              (checkBonnus === true ? (numero2 * numeroResult) / 100 : 0);

            setResult(numeroResult);
          }
        }
      }
    }
  }, [
    checkHourly,
    checkAnnual,
    checkPercentage,
    checkAmount,
    salary,
    totalSalary,
    valueHourForWeek,
    salaryMax,
    checkMax,
    checkMiddle,
    checkMin,
    checkBonnus,
  ]);

  // Ruta :https://maps.googleapis.com/maps/api/place/autocomplete/json?input=santiago&key=AIzaSyCzQJcOKaO8T4l1hnqObjcVfD_h--5hggo

  // Effects
  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const jobTitleQuery = query(collectionGroup(db, "jobTitle"));
        const response = await getDocs(jobTitleQuery);
        const skillList: any = [];

        response.docs.forEach((each) => {
          const data = each.data();
          if (data.title && data.title.length > 0 && data.verified) {
            skillList.push(data.title);
          }
        });

        const uniqueSkills = skillList.filter(
          (item: any, index: any) => skillList.indexOf(item) === index
        );
        const orderList = uniqueSkills.sort();

        const skillListSelector = orderList.map((skillName: any) => {
          return {
            value: dictionary[skillName] ?? skillName,
            label: dictionary[skillName] ?? skillName,
            predeterminated: skillName,
          };
        });

        const uniqueValues = Array.from(
          new Set(skillListSelector.map((item: any) => item.value))
        ).map((value) => {
          return skillListSelector.find((item: any) => item.value === value);
        });

        setTitleList(
          language === "English"
            ? uniqueValues.sort((a, b) => a.value.localeCompare(b.value))
            : uniqueValues
        );
      } catch (error) {
        console.error("Error fetching job titles:", error);
      }
    };

    fetchJobTitles();
  }, [dictionary, language]);

  return (
    <Content
      className="contenedor"
      style={{
        minHeight: "60vh",
        width: "100%",
        overflowY: "auto",
        padding: "3%",
        /* backgroundColor: "#f4f7fc", */
      }}
    >
      {/* Content Section */}

      {/* Title */}
      {update && (
        <Row style={{ width: "100%" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            xs={{ span: 24 }}
            sm={{ span: 23 }}
            md={{ span: 23 }}
            lg={{ span: 23 }}
            xl={{ span: 23 }}
            xxl={{ span: 23 }}
          >
            {update && (
              <Select
                defaultValue="open"
                onSelect={(value: any) => {
                  updatePositionStatus(value);
                }}
                style={{
                  width: 200,
                  marginLeft: "2%",
                  fontFamily: "Muli",
                  fontSize: 16,
                }}
                options={[
                  {
                    value: "open",
                    label: dictionary["editing-modal-status-open"],
                  },
                  {
                    value: "close",
                    label: dictionary["editing-modal-status-closed"],
                  },
                ]}
              />
            )}
            {update && (
              <Text
                style={{ marginLeft: "2%", fontSize: 17, fontFamily: "Muli" }}
              >
                {" "}
                {dictionary["editing-modal-status"]}
              </Text>
            )}
          </Col>
        </Row>
      )}
      <Row style={{ width: "100%", marginTop: update ? 15 : 0 }}>
        <Col
          style={{ display: "flex", alignItems: "center" }}
          xs={24}
          sm={24}
          md={5}
          lg={3}
          xl={{ span: 3 }}
          xxl={3}
        >
          <Text className={"textTitleModalCreation"}>
            {dictionary["creation-modal-page-one-field-job-title"]}{" "}
            <Text style={{ color: "#b90000" }}>*</Text>
          </Text>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={18}
          sm={20}
          md={20}
          lg={12}
          xl={{ span: 12 }}
          xxl={12}
        >
          <Row
            style={{
              width: "100%",
              borderRadius: '20px',
              border: "1px solid",
              borderColor: "#00000036",
            }}
          >
            <AutoComplete
              style={{
                width: "100%",
                borderRadius: 'transparent',
                fontFamily: "Muli",
                fontSize: 16,
                backgroundColor: "transparent",
              }}
              placeholder={
                dictionary["creation-modal-page-one-placeholder-job-title"]
              }
              size="middle"
              allowClear
              bordered={false}
              options={titleList}
              value={titleInput}
              filterOption={true}
              onSelect={(value: string) => setTitle(value)}
              onChange={(value) =>
                setTitle(value.length <= 75 ? value : titleInput)
              }
            />
          </Row>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={6}
          sm={4}
          md={4}
          lg={3}
          xl={{ span: 3 }}
          xxl={2}
        >
          <Text
            style={{ fontSize: 17, color: "#00000060", fontFamily: "Muli" }}
          >
            {titleInput.length}/75
          </Text>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: 15,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col
          style={{ marginTop: 4 }}
          xs={24}
          sm={24}
          md={5}
          lg={3}
          xl={{ span: 3 }}
          xxl={3}
        >
          <Row style={{ width: "100%" }}>
            <Text className={"textTitleModalCreation"}>
              {dictionary["creation-modal-page-one-field-location"]}
              <Text style={{ color: "#b90000", fontFamily: "Muli" }}>*</Text>
            </Text>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={{ span: 12 }} xxl={12}>
          <LocationAutoComplete
            location={location}
            setLocation={setLocation}
            setPlaces={(value: any) => setPlaces(value)}
          />
        </Col>
      </Row>

      <Row style={{ width: "100%", marginTop: 15 }}>
        <Col
          style={{ marginTop: 4 }}
          xs={24}
          sm={24}
          md={6}
          lg={4}
          xl={{ span: 4 }}
          xxl={3}
        >
          <Text className={"textTitleModalCreation"}>
            {dictionary["creation-modal-page-one-field-workplace"]}{" "}
            <Text style={{ color: "#b90000" }}>*</Text>
          </Text>
        </Col>
        <Col xs={24} sm={24} md={17} lg={12} xl={{ span: 12 }} xxl={12}>
          <Row style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24}>
              <WorkplaceCheckBox
                saveWorkplace={saveWorkplace}
                dataWorkplace={workplace}
              />
            </Col>
          </Row>
        </Col>
      </Row>


      <Row style={{ width: "100%", marginTop: 50 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={{ span: 11 }}
              lg={8}
              xl={{ span: 7 }}
              xxl={7}
            >
              <Row
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={{ span: 12 }} xxl={10}>
                  <Text className={"textTitleModalCreation"}>
                    {dictionary["new-job-modal-text-title1"]}
                    <Text style={{ color: "#b90000" }}>*</Text>
                  </Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={11} xl={{ span: 11 }} xxl={11}>
                  <Input
                    value={formatNumber(salary)}
                    maxLength={8}
                    size="large"
                    placeholder={
                      dictionary["creation-modal-page-one-placeholder-salary"]
                    }
                    allowClear
                    style={{
                      width: "100%",
                      borderRadius: 20,
                      boxShadow: "4px 1px 6px #00000020",
                      borderColor: "#00000020",

                      paddingLeft: 6,
                      fontSize: 16,
                      fontFamily: "muli",
                    }}
                    onChange={(e) => setSalary(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 12, offset: 1 }}
              lg={8}
              xl={{ span: 8, offset: 1 }}
              xxl={7}
            >
              <Row
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={{ span: 12 }} xxl={10}>
                  <Text className={"textTitleModalCreation"}>
                    {dictionary["new-job-modal-text-title2"]}
                    <Text style={{ color: "#b90000" }}>*</Text>
                  </Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={11} xl={{ span: 11 }} xxl={11}>
                  <Input
                    value={formatNumber(salaryMax)}
                    maxLength={8}
                    size="large"
                    placeholder={
                      dictionary["creation-modal-page-one-placeholder-salary"]
                    }
                    allowClear
                    style={{
                      width: "100%",
                      borderRadius: 20,
                      boxShadow: "4px 1px 6px #00000020",
                      borderColor: "#00000020",

                      paddingLeft: 6,
                      fontSize: 16,
                      fontFamily: "muli",
                    }}
                    onChange={(e) => setSalaryMax(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              style={{ display: "flex", justifyContent: "center" }}
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={{ span: 7 }}
              xxl={7}
            >
              <Row
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={{ span: 12 }}
                  xxl={12}
                >
                  <Radio
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkAnnual}
                    onChange={onChangeAnnual}
                  >
                    {dictionary["creation-modal-page-one-checkbox-annual"]}
                  </Radio>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={{ span: 12 }}
                  xxl={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkHourly}
                    onChange={onChangeHourly}
                  >
                    {dictionary["creation-modal-page-one-checkbox-hourly"]}
                  </Radio>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col xs={24} sm={24} md={5} lg={3} xl={{ span: 3 }} xxl={3}>
              <Row style={{ width: "100%" }}>
                <Text className={"textTitleModalCreation"}>
                  {dictionary["creation-modal-page-one-field-bonus"]}
                </Text>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={19} lg={12} xl={{ span: 12 }} xxl={12}>
              <Input
                value={formatNumber(totalSalary)}
                maxLength={8}
                size="large"
                placeholder={
                  dictionary["creation-modal-page-one-placeholder-bonus"]
                }
                allowClear
                style={{
                  width: "100%",
                  borderRadius: 20,
                  boxShadow: "4px 1px 6px #00000020",
                  borderColor: "#00000020",

                  paddingLeft: 6,
                  fontSize: 16,
                  fontFamily: "muli",
                }}
                onChange={(e) => setTotalSalary(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} xl={{ span: 9 }} xxl={9}>
              <Row style={{ width: "100%", paddingRight: 5, paddingLeft: 5 }}>
                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={{ span: 12 }}
                  xxl={12}
                >
                  <Radio
                    checked={checkPercentage}
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    onChange={onChangePercentage}
                  >
                    {dictionary["creation-modal-page-one-checkbox-percentage"]}
                  </Radio>
                </Col>

                <Col
                  style={{ display: "flex", justifyContent: "center" }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={{ span: 12 }}
                  xxl={12}
                >
                  <Radio
                    checked={checkAmount}
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    onChange={onChangeAmount}
                  >
                    {dictionary["creation-modal-page-one-checkbox-ammount"]}
                  </Radio>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              marginTop: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              style={{ marginTop: 4 }}
              xs={24}
              sm={24}
              md={5}
              lg={3}
              xl={{ span: 3 }}
              xxl={3}
            >
              <Row style={{ width: "100%" }}>
                <Text className={"textTitleModalCreation"}>
                  {dictionary["new-job-modal-text-title3"]}
                  <Text style={{ color: "#b90000" }}>*</Text>
                </Text>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={19} lg={21} xl={{ span: 21 }} xxl={21}>
              <Row style={{ width: "90%", marginTop: 10 }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={{ span: 6 }}
                  xxl={6}
                >
                  <Radio
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkMin}
                    onChange={onChangeMin}
                  >
                    {dictionary["new-job-modal-check-title1"]}
                  </Radio>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={{ span: 6 }}
                  xxl={6}
                >
                  <Radio
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkMiddle}
                    onChange={onChangeMiddle}
                  >
                    {dictionary["new-job-modal-check-title4"]}
                  </Radio>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={{ span: 6 }}
                  xxl={6}
                >
                  <Radio
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkMax}
                    onChange={onChangeMax}
                  >
                    {dictionary["new-job-modal-check-title2"]}
                  </Radio>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={{ span: 6 }}
                  xxl={6}
                >
                  <Checkbox
                    style={{ fontSize: 16, fontFamily: "Muli" }}
                    className="textContentModalCreation"
                    checked={checkBonnus}
                    onChange={onChangeBonnus}
                  >
                    {dictionary["new-job-modal-check-title3"]}
                  </Checkbox>
                </Col>
              </Row>
            </Col>
          </Row>
          {
            <Row style={{ width: "100%" }}>
              <Col
                style={{ marginTop: 20 }}
                xs={24}
                sm={24}
                md={24}
                lg={10}
                xl={{ span: 8 }}
                xxl={16}
              >
                <Row style={{ width: "100%" }}>
                  <Text className={"textTitleModalCreation"}>
                    {
                      dictionary[
                      "creation-modal-page-one-placeholder-other-details-hour-for-week"
                      ]
                    }
                  </Text>
                </Row>
                <Row style={{ width: "95%", marginTop: 10 }}>
                  <InputNumber
                    value={valueHourForWeek}
                    size="large"
                    min={1}
                    max={45}
                    style={{
                      borderRadius: 20,
                      boxShadow: "4px 1px 6px #00000020",
                      borderColor: "#00000020",
                      paddingLeft: 6,
                      fontSize: 16,
                      fontFamily: "muli",
                    }}
                    onChange={(e) => setValueHourForWeek(e)}
                  />
                </Row>
              </Col>
            </Row>
          }
          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              style={{ marginTop: 4 }}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={{ span: 24 }}
              xxl={24}
            >
              <Text
                style={{ fontSize: 30 }}
                className={"textTitleModalCreation"}
              >
                {" "}
                {
                  dictionary["creation-modal-preview-field-total-compensation"]
                }:{" "}
                <Text style={{ fontSize: 30, fontWeight: "bold" }}>
                  {" "}
                  $ {formatNumber(result)}
                </Text>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ width: "100%" }}>
        <Col
          style={{ marginTop: 40 }}
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={{ span: 16 }}
          xxl={16}
        >
          <Row style={{ width: "100%" }}>
            <Text className={"textTitleModalCreation"}>
              {
                dictionary[
                "creation-modal-page-one-field-other-details-compensation"
                ]
              }
            </Text>
          </Row>
          <Row style={{ width: "95%", marginTop: 10 }}>
            <div style={{ width: '100%', padding: '10px 10px', backgroundColor: 'white', borderRadius: '20px', border: '1px solid #00000026', boxShadow: "4px 1px 6px #00000020", }}>
              <TextArea
                rows={4}
                value={otherDetailsCompensation}
                size="middle"
                placeholder={
                  dictionary[
                  "creation-modal-page-one-placeholder-other-details-compensation"
                  ]
                }
                allowClear
                style={{
                  width: "100%",
                  borderRadius: 20,

                  borderColor: "transparent",
                  paddingLeft: 6,
                  fontSize: 16,
                  fontFamily: "muli",
                }}
                onChange={(e) => setOtherDetailsCompensation(e.target.value)}
              />

            </div>

          </Row>
        </Col>
      </Row>

      {/* Type  */}

      <Row style={{ width: "100%", marginTop: 20 }}>
        <Row style={{ width: "100%" }}>
          <Text className={"textTitleModalCreation"}>
            {dictionary["creation-modal-page-one-field-job-type"]}{" "}
            <Text style={{ color: "#b90000" }}>*</Text>
          </Text>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24}>
            <JobTypeCheckBox dataJobType={type} saveJobType={saveJobType} />
          </Col>
        </Row>
      </Row>
    </Content>
  );
};

// Exports
export default FirstStepCreationJob;
