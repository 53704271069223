//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { useEffect, useState } from "react";

import InputChat from "./FooterChat/InputChat";
import ChatMessageList from "./BodyChat/ChatMessageList";
import HeaderChat from "./HeaderChat/HeaderChat";
import { Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase/config";

// Interfaces
interface Props {
  idHiring: string;
  idCandidate: string;
  nameCandidate: string;
  isChatScreen: boolean;
}

// Component
const Chat: React.FC<Props> = ({
  idHiring,
  idCandidate,
  nameCandidate,
  isChatScreen,
}) => {
  // States
  const [chat, setChat] = useState<any>([]);
  const [showHeaderLarge, setShowHeaderLarge] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (!idHiring || !idCandidate) {
      return;
    }

    // Reference to the "chat" subcollection
    const chatRef = collection(
      db,
      `hirings/${idHiring}/messages/${idCandidate}/chat`
    );
    const q = query(chatRef, orderBy("date"));

    const unsubscribe = onSnapshot(q, (chatCollectionSnap) => {
      const messages: any = [];
      chatCollectionSnap.forEach((messageSnap) => {
        const message = messageSnap.data(); // Assuming messageSnap.data() returns a Message type
        messages.push(message);
      });
      setChat(messages); // Assuming setChat is a state setter function
    });

    return () => unsubscribe();
  }, [idHiring, idCandidate, nameCandidate, isChatScreen]);

  return (
    <Content style={{ minHeight: "100%", maxHeight: "110%", width: "100%" }}>
      {isChatScreen ? (
        <>
          <Row
            className="contenedor"
            style={{ width: "100%", overflow: "auto" }}
          >
            <HeaderChat
              isChatScreen={isChatScreen}
              showHeaderLarge={showHeaderLarge}
              setShowHeaderLarge={setShowHeaderLarge}
            />
          </Row>
          <Row style={{ height: "65%", marginTop: 10 }}>
            <ChatMessageList
              showHeaderLarge={showHeaderLarge}
              chat={chat}
              isChatScreen={isChatScreen}
            />
          </Row>
        </>
      ) : (
        <>
          <Row
            className="contenedor"
            style={{
              height: showHeaderLarge ? 'auto' : 59,
              width: "100%",
              overflow: "auto",
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <HeaderChat
              isChatScreen={isChatScreen}
              showHeaderLarge={showHeaderLarge}
              setShowHeaderLarge={setShowHeaderLarge}
            />
          </Row>

          <Row
            style={{ height: showHeaderLarge ? "67%" : "72%", marginTop: 10 }}
          >
            <ChatMessageList
              showHeaderLarge={showHeaderLarge}
              chat={chat}
              isChatScreen={isChatScreen}
            />
          </Row>
        </>
      )}

      <Row style={{ width: "100%", paddingTop: 10 }}>
        <InputChat isChatScreen={isChatScreen} />
      </Row>
    </Content>
  );
};

// Export
export default Chat;
