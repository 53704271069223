//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";

import { Content } from "antd/lib/layout/layout";
import { CloseOutlined } from "@ant-design/icons";
import JobPreviewCarousel from "./steps/finalStep/JobPreviewCarousel";
import { Job } from "../../../types/position";
import { useSelector } from "react-redux";
import ButtonEquis from "../../ButtonEquis";
// Interface
interface Props {
  visibleModal: boolean;
  closeModal: () => void;
  position: Job;
  handleOnCloneJob: () => void;

  valueHourForWeek: number;
}

// Component
const JobPreviewModal: React.FC<Props> = ({
  visibleModal,
  closeModal,
  position,
  handleOnCloneJob,
  valueHourForWeek,
}) => {
  const [titleInput, setTitleInput] = useState<string>("");
  const [jobType, setJobType] = useState<Array<string>>([]);
  const [location, setLocation] = useState<any>({});
  const [workplace, setWorkplace] = useState<Array<string>>([]);
  const [description, setDescription] = useState<string>("");
  const [salary, setSalary] = useState<string>("");
  const [salaryMax, setSalaryMax] = useState<any>("");
  const [totalSalary, setTotalSalary] = useState<string>("");
  const [responsibilities, setResponsibilities] = useState<
    Array<{ index: number; value: string }>
  >([]);
  const [checkHourly, setCheckHourly] = useState<boolean>(true);
  const [checkAnnual, setCheckAnnual] = useState<boolean>(false);
  const [checkAmount, setCheckAmount] = useState<boolean>(false);
  const [checkPercentage, setCheckPercentage] = useState<boolean>(false);
  const { dictionary } = useSelector((state: any) => state.ui);
  const [checkMin, setCheckMin] = useState<boolean>(true);
  const [checkMax, setCheckMax] = useState<boolean>(false);
  const [checkMiddle, setCheckMiddle] = useState<boolean>(false);
  const [otherDetailsCompensation, setOtherDetailsCompensation] =
    useState<any>("");

  // States Thrid Step
  const [requirements, setRequirements] = useState<Array<any>>([]);
  const [skills, setSkills] = useState<Array<any>>([]);

  // Handlers
  const handleOnCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    setCheckMin(position.checkMin === undefined ? false : position.checkMin);
    setCheckMiddle(
      position.checkMiddle === undefined ? true : position.checkMiddle
    );
    setCheckMax(position.checkMax === undefined ? false : position.checkMax);
    setSalary(position.salary);
    setSalaryMax(position.salaryMax ? position.salaryMax : position.salary);
    setTitleInput(position.title ? position.title : "");
    setLocation(position.location ? position.location : "");
    setTotalSalary(position.totalSalary ? position.totalSalary : "");
    setJobType(position.jobType ? position.jobType : []);
    setWorkplace(position.workplace ? position.workplace : []);
    setDescription(position.description ? position.description : "");
    setResponsibilities(
      position.responsibilities ? position.responsibilities : []
    );
    setRequirements(
      position.technicalRequirements ? position.technicalRequirements : []
    );
    setSkills(position.skills ? position.skills : []);
    setCheckAmount(position?.amount);
    setCheckAnnual(position?.annual);
    setCheckHourly(position.hourly);
    setCheckPercentage(position.percentage);
    setOtherDetailsCompensation(position?.otherDetailsCompensation);
  }, [position]);

  return (
    <Modal
      open={visibleModal}
      closable={false}
      closeIcon={
        <CloseOutlined
          style={{
            backgroundColor: "red",
            borderRadius: 32,
            fontSize: 14,
            padding: 5,
            fontWeight: "bold",
          }}
        />
      }
      width="70vh"
      destroyOnClose={true}
      onCancel={handleOnCloseModal}
      centered
      footer={
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: 30,
          }}
        >
          <Button
            style={{
              marginTop: 5,
              borderBottomRightRadius: 20,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              width: 150,
              background:
                "linear-gradient(90deg, rgba(196,196,78,1) 0%, rgba(157,196,81,1) 100%)",
              color: "white",
            }}
            onClick={handleOnCloneJob}
          >
            {dictionary["creation-modal-initial-page-button-go"]}
          </Button>
        </Row>
      }
    >
      <div style={{ height: "75vh", width: "100%" }}>
        <Row style={{ width: "100%", height: "8%" }}>
          <Col
            xs={{ span: 1, offset: 23 }}
            xxl={{ span: 1 }}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <ButtonEquis handleOnFunction={closeModal} />
          </Col>
        </Row>

        <Content style={{ height: "92%", width: "100%" }}>
          <Col
            xxl={{ span: 20, offset: 2 }}
            style={{ height: "100%", padding: "1%" }}
          >
            <JobPreviewCarousel
              checkMin={checkMin}
              checkMax={checkMax}
              checkMiddle={checkMiddle}
              salaryMax={salaryMax}
              annualOrHourly={position.annual}
              amountCheck={checkAmount}
              annualCheck={checkAnnual}
              hourlyCheck={checkHourly}
              percentageCheck={checkPercentage}
              titleInput={titleInput}
              description={description}
              jobType={jobType}
              location={location}
              requirements={requirements}
              responsibilities={responsibilities}
              salary={salary}
              skills={skills}
              workplace={workplace}
              totalSalary={totalSalary}
              otherDetailsCompensation={otherDetailsCompensation}
              valueHourForWeek={valueHourForWeek}
            />
          </Col>
        </Content>
      </div>
    </Modal>
  );
};

// Exports
export default JobPreviewModal;
