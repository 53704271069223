import { Modal, Row, Typography } from "antd";
import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth";
import { logoutFirebase } from "../../firebase/providers";
import { getTranslation } from "../../hooks/hooks";

// interface
interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  timeInactiveUser: number;
}

const { Text } = Typography;

const LogoutInactiveModal: React.FC<Props> = ({
  visible,
  setVisible,
  timeInactiveUser,
}) => {
  // States
  const dispatch: Dispatch<any> = useDispatch();
  const { dictionary } = useSelector((state: any) => state.ui);

  const saveTimeInSeconds = () => {
    const currentTime = new Date();
    const seconds =
      currentTime.getHours() * 3600 +
      currentTime.getMinutes() * 60 +
      currentTime.getSeconds();
    return seconds;
  };
  // Handlers
  const handleOnLogout = () => {
    localStorage.setItem("timeInSeconds", "0");
    setVisible(false);
    dispatch(logout());
    logoutFirebase();
  };

  const handleOnContinueWork = (timeInactiveUser: number) => {
    const timeUserInteractionButton = saveTimeInSeconds();

    let counterInactiveTime = timeUserInteractionButton - timeInactiveUser;
    if (counterInactiveTime > 180) {
      handleOnLogout();
    } else {
      setVisible(false);
    }
  };

  const secondsToTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      onOk={() => handleOnLogout()}
      onCancel={() => {
        handleOnContinueWork(timeInactiveUser);
      }}
      okText={getTranslation("inactive-session-logout-button-text", dictionary)}
      cancelText={getTranslation(
        "inactiveSession-button-text-continue-working",
        dictionary
      )}
    >
      <Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Text
          style={{ display: "flex", textAlign: "center", fontFamily: "Muli" }}
        >
          {getTranslation("inactive-session-logout-text", dictionary)}
        </Text>
        <Text style={{ fontFamily: "Muli" }}>
          {secondsToTime(timeInactiveUser)}
        </Text>
      </Row>
    </Modal>
  );
};

export default LogoutInactiveModal;
