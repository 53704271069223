//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Input, notification, Row, Typography, Radio, Divider } from "antd";
import type { RadioChangeEvent } from "antd";
import { QuestionCircleOutlined, DownloadOutlined, CloseOutlined } from "@ant-design/icons";
import "./StylesProfile.css";

import { startUpdateUserInformation } from "../../store/auth";

import { getCompanyQRByCompanyId } from "../../firebase/endpoints/hiringEndPoint";
import { getTranslation } from "../../hooks/hooks";

import UploadAvatar from "../../common/UploadAvatar";

type NotificationType = "success" | "info" | "warning" | "error";

// Component
const Profile: React.FC = () => {
  // States
  const user = useSelector((state: any) => state.auth.user);
  const [avatarPersonal, setAvatarPersonal] = useState<string>("");
  const [openModalQR, setOpenModalQR] = useState<boolean>(false);
  const [avatarQR, setAvatarQR] = useState<string>("");
  const [avatarCompany, setAvatarCompany] = useState<string>("");
  const [language, setLanguage] = useState<string>("English");
  const [nameForm, setNameForm] = useState<string>("");
  const [lastNameForm, setLastNameForm] = useState<string>("");
  const [emailForm, setEmailForm] = useState<string>("");
  const [phoneNumberForm, setPhoneNumberForm] = useState<string>("");
  const [companyInformationForm, setCompanyInformationForm] = useState<string>("");
  const [companyName, setCompanyNameForm] = useState<string>("");
  const [industryForm, setIndustryForm] = useState<string>("");
  const [websiteCompany, setWebsiteCompany] = useState<string>("");
  const [titleForm, setTitleForm] = useState<string>("");

  const { TextArea } = Input;
  const { Text } = Typography;
  const dispatch: Dispatch<any> = useDispatch();
  const [statusMdl, setStatusMdl] = useState<boolean>();
  const [titleMdl, setTitleMdl] = useState<string>();
  const [textMdl, setTextMdl] = useState<string>();

  // Handlers
  const handleOnSaveChanges = () => {
    dispatch(
      startUpdateUserInformation({
        avatarCompany: avatarCompany,
        avatarPersonal: avatarPersonal,
        company: companyName,
        companyInformation: companyInformationForm,
        idHiring: user.idHiring,
        industry: industryForm,
        lastName: lastNameForm,
        name: nameForm,
        phoneNumber: phoneNumberForm,
        companyWebsite: websiteCompany,
        title: titleForm,
        email: user.email,
        communicationLanguaje: language,
        idCompanyQR: user.idCompanyQR !== undefined ? user.idCompanyQR : "",
      })
    );

    notificationModal("success", "Save changes successfully", "Profile fields have been successfully updated");
  };



  const notificationModal = (type: NotificationType, message: string, description: string) => notification[type]({ message: message, description: description, });

  const downloadQR = () => {
    axios.get(avatarQR, { responseType: "blob" }) // Solicita la imagen como un blob
      .then((response) => {
        // Creación del objeto URL a partir del blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Creación del elemento 'a' para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
        a.download = `${user.company}QR.png`; // Nombre del archivo descargado
        document.body.appendChild(a); // Adjunta el elemento 'a' al cuerpo del documento
        a.click(); // Simula un clic para descargar el archivo

        // Limpieza: elimina el elemento 'a' y libera el objeto URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error al descargar la imagen", error);
      });
  };

  const { dictionary } = useSelector((state: any) => state.ui);

  useEffect(() => {
    if (user) {
      setAvatarCompany(user.avatarCompany ? user.avatarCompany : "");
      setAvatarPersonal(user.avatarPersonal ? user.avatarPersonal : "");
      setNameForm(user.name ? user.name : "");
      setLastNameForm(user.lastName ? user.lastName : "");
      setEmailForm(user.email ? user.email : "");
      setPhoneNumberForm(user.phoneNumber ? user.phoneNumber : "");
      setCompanyNameForm(user.company ? user.company : "");
      setIndustryForm(user.industry ? user.industry : "");
      setCompanyInformationForm(
        user.companyInformation ? user.companyInformation : ""
      );
      setWebsiteCompany(user.companyWebsite ? user.companyWebsite : "");
      setTitleForm(user.title ? user.title : "");
      setLanguage(user.communicationLanguaje ? user.communicationLanguaje : "");
    }
  }, [user]);

  const fetchCompanyQRWirhIdCompany = useCallback(async (idCompany: string) => {
    try {
      const companyQRResult: any = await getCompanyQRByCompanyId(idCompany);
      if (companyQRResult) {
        if (companyQRResult[0].url) {
          setAvatarQR(companyQRResult[0].url);
        } else {
          setAvatarQR("");
        }
      }
    } catch (error) {
      console.log(error);
      alert(`Error en fetchCompanyQRWirhIdCompany: ${error}`);
    }
  }, []);

  useEffect(() => {
    if (
      user.idCompanyQR !== undefined &&
      user.idCompanyQR !== "" &&
      user.idCompanyQR !== null
    ) {
      fetchCompanyQRWirhIdCompany(user.idCompanyQR);
    }
  }, [user, fetchCompanyQRWirhIdCompany]);

  const onChange = (e: RadioChangeEvent) => setLanguage(e.target.value);

  return (
    <div className="container-profile">
      {openModalQR === true ? (
        <div className="container-div-avatar">
          <div className="container-avatar">
            <img src={avatarQR} className="avatar-img" alt="document" />
          </div>

          <div className="container-div-buttons">
            <Button
              icon={<DownloadOutlined />}
              className="download-buttons"
              onClick={() => downloadQR()}
            >

              {dictionary["download-qr-text-title1"]}
            </Button>

            <Button
              className="download-buttons"
              style={{ backgroundColor: 'red' }}
              onClick={() => setOpenModalQR(false)}
            >
              {dictionary["creation-modal-initial-page-button-close"]}
            </Button>
          </div>
        </div>
      ) : null}

      <Row style={{ height: "85%", width: "100%", overflowY: "auto" }}>
        <Row style={{ width: "100%", height: "100%" }}>
          <Row style={{ width: "100%" }}>

            {/* Header Title Contact Information */}
            <Row style={{ width: "100%", marginTop: 30 }}>
              <Col xs={{ span: 23, offset: 1 }} sm={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} lg={{ span: 23, offset: 1 }} xl={{ span: 23, offset: 1 }} xxl={{ span: 23, offset: 1 }} style={{ display: "flex", alignItems: "center" }}>
                <Text style={{ fontSize: 26, color: "#129bd4", fontFamily: "Muli" }}>
                  {dictionary["profile-title-contact-information"]}
                </Text>

                <Button
                  onClick={() => [
                    setStatusMdl(true),
                    setTitleMdl(dictionary["profile-title-contact-information"]),
                    setTextMdl(dictionary["profile-modal-information-personal-title"]),
                  ]}

                  style={{
                    border: 'transparent',
                    backgroundColor: 'transparent'
                  }}
                  icon={<QuestionCircleOutlined />}
                />
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} xxl={{ span: 22, offset: 1 }}>
                <Row style={{ width: "100%" }}>
                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} style={{ marginTop: 20 }}>
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-name"]}
                      value={nameForm}
                      onChange={(e) => setNameForm(e.target.value)}
                    />

                    <Text className="text-label">
                      {dictionary["profile-text-title1"]}
                    </Text>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} style={{ marginTop: 20 }} >
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-last-name"]}
                      value={lastNameForm}
                      onChange={(e) => setLastNameForm(e.target.value)}
                    />

                    <Text className="text-label">
                      {dictionary["profile-text-title2"]}
                    </Text>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} style={{ marginTop: 20 }} >
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-phone-number"]}
                      value={phoneNumberForm}
                      onChange={(e) => setPhoneNumberForm(e.target.value)}
                    />
                    <Text className="text-label">
                      {dictionary["profile-text-title3"]}
                    </Text>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} style={{ marginTop: 20 }}>
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder="Email"
                      value={emailForm}
                      disabled
                    />

                    <Text className="text-label">
                      {dictionary["profile-text-title4"]}
                    </Text>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }} style={{ marginTop: 20 }}>
                    <Input
                      size="large"
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={getTranslation(
                        "profile-field-title",
                        dictionary
                      )}
                      value={titleForm}
                      onChange={(e) => setTitleForm(e.target.value)}
                    />
                    <Text className="text-label">
                      {getTranslation("profile-text-title9", dictionary)}
                    </Text>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text style={{ marginBottom: "5px" }}>
                      Communication Languaje
                    </Text>

                    <Radio.Group onChange={onChange} value={language}>
                      <Radio value="English">English</Radio>
                      <Radio value="Français">Français</Radio>
                    </Radio.Group>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }} style={{ marginTop: 20 }}>
                    {user.idCompanyQR !== undefined ||
                      user.idCompanyQR !== null ||
                      user.idCompanyQR !== "" ? (

                      <div style={{ gap: '2px', flexDirection: 'row', display: 'flex', marginTop: '1px' }}>
                        <Button
                          className="download-buttons"
                          onClick={() => setOpenModalQR(true)}
                        >
                          {dictionary["download-qr-text-title2"]}
                        </Button>

                        <Button
                          icon={<DownloadOutlined />}
                          className="download-buttons"
                          onClick={() => downloadQR()}
                        >
                          {dictionary["download-qr-text-title1"]}
                        </Button>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>

          <div style={{ width: '100%', padding: '0px 20px' }}>
            <Divider />
          </div>



          <Row style={{ width: "100%", marginTop: '10px' }}>
            {/* Header company */}
            <Row style={{ width: "100%" }}>
              <Col xs={{ span: 23, offset: 1 }} sm={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} lg={{ span: 23, offset: 1 }} xl={{ span: 23, offset: 1 }} xxl={{ span: 23, offset: 1 }} style={{ display: "flex", alignItems: "center" }}>
                <Text style={{ fontSize: 26, color: "#129bd4", fontFamily: "Muli" }}
                >
                  {dictionary["profile-title-company-information"]}
                </Text>

                <Button
                  onClick={() => [
                    setStatusMdl(true),
                    setTitleMdl(
                      dictionary["profile-title-company-information"]
                    ),
                    setTextMdl(
                      dictionary["profile-modal-information-company-title"]
                    ),
                  ]}
                  shape="circle"
                  style={{
                    border: "none",
                  }}
                  icon={<QuestionCircleOutlined />}
                />
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} md={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }} xl={{ span: 22, offset: 1 }} xxl={{ span: 22, offset: 1 }}>
                <Row style={{ width: '100%' }}>
                  <Col
                    xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 7, offset: 0 }} xxl={{ span: 7, offset: 0 }}
                    style={{ marginTop: 20 }}
                  >
                    <Input
                      size="large"
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-company-name"]}
                      value={companyName}
                      onChange={(e) => setCompanyNameForm(e.target.value)}
                    />
                    <Text
                      className="text-label"
                    >
                      {dictionary["profile-text-title5"]}
                    </Text>
                  </Col>

                  <Col
                    xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                    style={{ marginTop: 20 }}
                  >
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-industry"]}
                      value={industryForm}
                      onChange={(e) => setIndustryForm(e.target.value)}
                    />
                    <Text
                      className="text-label"
                    >
                      {dictionary["profile-text-title6"]}
                    </Text>
                  </Col>

                  <Col
                    xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                    style={{ marginTop: 20 }}
                  >
                    <Input
                      style={{ width: "100%", borderRadius: '20px' }}
                      placeholder={dictionary["profile-field-company-webite"]}
                      value={websiteCompany}
                      onChange={(e) => setWebsiteCompany(e.target.value)}
                    />
                    <Text
                      className="text-label"
                    >
                      {getTranslation("profile-text-title7", dictionary)}
                    </Text>
                  </Col>

                  <Col
                    xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 7, offset: 0 }} xl={{ span: 15, offset: 0 }} xxl={{ span: 15, offset: 0 }}
                    style={{ marginTop: 20 }}
                  >
                    <TextArea
                      style={{ width: "100%", borderRadius: '20px', height: "20vh" }}
                      placeholder={getTranslation(
                        "profile-field-description-company",
                        dictionary
                      )}
                      value={companyInformationForm}
                      onChange={(e) => setCompanyInformationForm(e.target.value)}
                    />
                    <Text
                      className="text-label"
                    >
                      {getTranslation("profile-text-title8", dictionary)}
                    </Text>

                  </Col>

                  <Col
                    xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 7, offset: 1 }}
                    style={{ marginTop: 20, display: 'flex', flexDirection: 'column', gap: '2px' }}
                  >
                    <UploadAvatar
                      avatar={avatarCompany}
                      setAvatar={setAvatarCompany}
                      title={dictionary["profile-text-placeholder-company-logo"]}
                    />

                    <Text
                      className="text-label"
                    >
                      Company Logo
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>

      <div style={{ width: '100%', padding: '0px 20px' }}>
        <Divider />

      </div>

      <Row
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10%",
        }}
      >
        <Button
          onClick={handleOnSaveChanges}
          style={{
            backgroundColor: "#404193",
            color: "white",
            display: "flex",
            justifyContent: "center",
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '20px',
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 16, color: "white", fontFamily: "Muli" }}>
            {dictionary["profile-field-button-save"]}
          </Text>
        </Button>
      </Row>
      {statusMdl ? (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#3838387a",
            top: 0,
          }}
        >
          <div
            style={{
              width: "60%",
              height: 300,
              backgroundColor: "white",
              boxShadow: "7px 7px 15px #0000002b",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "15%",
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid #9292922f",
              }}
            >
              <div
                style={{
                  width: "90%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: "3%",
                }}
              >
                <h2 style={{ margin: "auto 0" }}>{titleMdl}</h2>
              </div>
              <div
                style={{
                  marginRight: 5,
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  shape="circle"
                  style={{
                    color: "white",
                    backgroundColor: "#D12525",
                    border: "none",
                  }}
                  icon={<CloseOutlined />}
                  onClick={() => setStatusMdl(false)}
                />
              </div>
            </div>
            <div style={{ width: "100%", height: "80%", padding: "5%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  marginTop: "3%",
                  marginBottom: "3%",
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontFamily: "Muli",
                  }}
                >
                  {textMdl}
                </h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Profile;
