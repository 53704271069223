//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Row } from 'antd';
import { useSelector } from 'react-redux';

// Interface
interface Props {
    saveJobType: (arrayString:Array<string>) => void
    dataJobType:Array<string>
}

// Component
const JobTypeCheckBox:React.FC<Props> = ({saveJobType, dataJobType}) => {

    const [fullTime, setFullTime] = useState<boolean>(false)
    const [partTime, setPartTime] = useState<boolean>(false)
    const [contract, setContract] = useState<boolean>(false)
    const [permanent, setPermanent] = useState<boolean>(false)
    const [training, setTraining] = useState<boolean>(false)
    const [seasonal, setSeasonal] = useState<boolean>(false)
    const [freelance, setFreelance] = useState<boolean>(false)
    const [volunteer, setVolunteer] = useState<boolean>(false)

    const { dictionary } = useSelector((state:any)=>state.ui);

    useEffect(() => {
        const arrayString=[]
        if(fullTime === true){
            arrayString.push("Full time")
        }
        if(partTime === true){
            arrayString.push("Part time")
        }
        if(contract === true){
            arrayString.push("Contract")
        }
        if(permanent === true){
            arrayString.push("Permanent")
        }
        if(training === true){
            arrayString.push("Training")
        }
        if(seasonal === true){
            arrayString.push("Seasonal")
        }
        if(freelance === true){
            arrayString.push("Freelance")
        }
        if(volunteer === true){
            arrayString.push("Volunteer")
        }

        saveJobType(arrayString)
    },[fullTime, partTime, contract, permanent, training, seasonal, freelance, volunteer, saveJobType])


    useEffect(() => {
        if (dataJobType === undefined) {
            return;
        }
        dataJobType.forEach((element:string) => {
            if (element === "Full time") {
                setFullTime(true)
            }
            if (element === "Part time") {
                setPartTime(true)
            }
            if (element === "Contract") {
                setContract(true)
            }
            if (element === "Permanent") {
                setPermanent(true)
            }
            if (element === "Training") {
                setTraining(true)
            }
            if (element === "Seasonal") {
                setSeasonal(true)
            }
            if (element === "Freelance") {
                setFreelance(true)
            }
            if (element === "Volunteer") {
                setVolunteer(true)
            }
            
        });

    },[ dataJobType ]);

    return (
        <Row style={{width:'100%'}}>
            <Row style={{width:'100%'}}>
                <Col xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }} xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={fullTime} style={{fontSize: 16}}  onChange={() => setFullTime(!fullTime)}>{dictionary['creation-modal-page-one-checkbox-full-time']}</Checkbox>
                </Col>

                <Col  xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }}  xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={partTime} style={{fontSize: 16}} onChange={() => setPartTime(!partTime)}>{dictionary['creation-modal-page-one-checkbox-part-tim']}</Checkbox>    
                </Col>

                <Col  xs={{ span: 24}} sm={{ span: 12}}  md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }}xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={contract} style={{fontSize: 16}} onChange={() => setContract(!contract)}>{dictionary['creation-modal-page-one-checkbox-contract']}</Checkbox>
                </Col>

                <Col  xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }} xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={permanent}  style={{fontSize: 16}} onChange={() => {setPermanent(!permanent)}}>{dictionary['creation-modal-page-one-checkbox-permanent']}</Checkbox>
                </Col>
            
                <Col xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }}  xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={training} style={{fontSize:16}} onChange={() => setTraining(!training)}>{dictionary['creation-modal-page-one-checkbox-training']}</Checkbox>
                </Col>

                <Col xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }}  xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox style={{fontSize:16}} checked={seasonal} onChange={() => setSeasonal(!seasonal)}>{dictionary['creation-modal-page-one-checkbox-seasonal']}</Checkbox>
                </Col>

                <Col xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }}  xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={freelance} style={{fontSize:16}} onChange={() => setFreelance(!freelance)}>{dictionary['creation-modal-page-one-checkbox-freelance']}</Checkbox>
                </Col>

                <Col xs={{ span: 24}} sm={{ span: 12}} md={{ span: 8}} lg={{ span:4 }} xl={{ span: 3 }} xxl={{span:3}} style={{paddingTop:10}}>
                    <Checkbox checked={volunteer} style={{fontSize:16}} onChange={() => setVolunteer(!volunteer)}>{dictionary['creation-modal-page-one-checkbox-volunteer']}</Checkbox>
                </Col>
            </Row>
        </Row>
    )
}

//Export 
export default JobTypeCheckBox;
