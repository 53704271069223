import { Modal } from 'antd'
import React from 'react'
import { Candidate } from '../../types/candidate';

// Interface
interface Props {
    openPDF: boolean;
    setOpenPDF: (value: boolean) => void;
    candidate: Candidate;
}
const PreviewPDFModal: React.FC<Props> = ({ openPDF, setOpenPDF, candidate }) => {
    return (
        <Modal
            style={{ marginTop: "10vh" }}
            width={"80vw"}
            centered
            title="PDF"
            open={openPDF}
            okText={"Close"}
            onCancel={() => { setOpenPDF(false) }}
            footer={[
                <button style={{
                    backgroundColor: "#1890ff",
                    borderColor: "#ffffff",
                    color: "white",
                    fontSize: 16,
                    borderRadius: 5,
                    paddingRight: 20,
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: 20,
                }}
                    key="ok" onClick={() => { setOpenPDF(false) }}
                >OK
                </button>,
            ]}
        >
            <div style={{ width: "100%", height: "70vh" }}>
                <iframe src={candidate.urlDocumentProfile ? candidate.urlDocumentProfile : candidate.urlDocument} width="100%" height="100%" title="PDF Viewer"></iframe>
            </div>
        </Modal>
    )
}

export default PreviewPDFModal