// Dependencies
import { Col, Row, Typography } from 'antd'
import React from 'react'
import icoSkills from "../../image/ico_skills.png";
import { useSelector } from 'react-redux';
import IconRatingSkill from '../iconRating';

// Component Ant Design
const { Text } = Typography

// Inteface
interface Props {
  skill: Array<any>
}
const SkillSectionCandidateCard: React.FC<Props> = ({ skill }) => {

  // States
  const { dictionary } = useSelector((state: any) => state.ui);
  return (
    <>
      <Row style={{ height: '30%', width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
        <Row style={{ height: '100%', width: '50%' }}>
          <Col xs={12} sm={12} md={8} lg={6} xl={4} xxl={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={icoSkills}
              style={{ height: 40, width: 40 }}
              alt={icoSkills}
            />
          </Col>

          <Col xs={12} sm={{ span: 11, offset: 1 }} md={{ span: 16, offset: 0 }} lg={18} xl={20} xxl={20} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Text style={{ color: "#129BD4", fontSize: 24, fontFamily: 'Muli' }}>
              {dictionary['sourcing-card-title-skills']}
            </Text>
          </Col>
        </Row>
      </Row>

      <Row style={{ width: '100%', height: '70%' }}>
        {
          <Row style={{ paddingLeft: '4%', width: '100%', display: 'flex', justifyContent: 'center' }}>
            {
              skill.map((skill) => (
                <Col key={skill.name} style={{ padding: 1, height: 30, marginRight: 40, marginTop: 10 }}>
                  <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <IconRatingSkill rating={skill.level} />
                      </div>
                    </Col>

                    <Col style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                      <Text style={{ fontSize: 20, fontFamily: 'Muli' }}>{skill.name}</Text>
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
        }
      </Row>
    </>
  )
}

export default SkillSectionCandidateCard