// Dependencies
import { Spin } from 'antd'
import React from 'react'

// Component
const RegisterLoading: React.FC = () => {
    return (
        <div className='loading-register-form'>
            <Spin />
        </div>
    )
}

// Export
export default RegisterLoading
