// Dependencies
//@ts-check
import { Col, Row } from 'antd'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
// Interface
interface Props {
  location: string;
  setLocation: (location: string) => void;
  setPlaces: (place: any) => void;
}

const options = {
  fields: [],
  types: ["locality", "country", "sublocality"]
};

// Component
const LocationAutoComplete: React.FC<Props> = ({ location, setLocation, setPlaces }) => {




  const autoCompleteRef = useRef<any>();
  const inputRef = useRef<any>();
  const { dictionary } = useSelector((state: any) => state.ui);

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace();
      if (place?.formatted_address) {
        const country = place?.address_components.find((place: any) => place.types.includes('country', 'political'));
        const city = place?.address_components.find((place: any) => place.types.includes('locality', 'political'));
        const state = place?.address_components.find((place: any) =>
          place.types.includes('administrative_area_level_1', 'political')
        );
        const newLocation = () => {
          if (country !== undefined && city !== undefined && state !== undefined) {
            return city.long_name + ', ' + state.short_name + ', ' + country.short_name;
          } else if (country !== undefined && city !== undefined) {
            return city.long_name + ', ' + country.short_name;
          } else if (city !== undefined) {
            return city.long_name;
          } else {
            return place?.formatted_address;
          }
        };
        //@ts-ignore
        setLocation(prevState => newLocation());
        //@ts-ignore
        setPlaces({
          formattedAddress: place?.formatted_address,
          addressComponents: place?.address_components,
          name: place?.name,
          placeId: place?.place_id,
          types: place?.types,
          geometry: {
            lat: place?.geometry.location.lat(),
            lng: place?.geometry.location.lng(),
          },
        });
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  return (
    <Row style={{ width: '100%' }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 24 }} xxl={24}>
        <input
          ref={inputRef}
          value={location}
          placeholder={dictionary['creation-modal-page-one-placeholder-location']}

          style={{
            width: "100%",
            borderRadius: '20px',
            border: '1px solid  #00000036',
            boxShadow: "4px 1px 6px #00000020 !important",
            borderColor: '#00000020',
            height: '37px',
            paddingLeft: '12px',
            fontSize: 16,

          }}
          onChange={(e) => { setLocation(e.target.value) }}
        />

      </Col>
    </Row>
  )
}

export default LocationAutoComplete