//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { chatSlice } from "./chat";
import { jobSlice } from "./job/jobSlice";
import { uiSlice } from "./ui";

export const store = configureStore({
    reducer:{
        auth: authSlice.reducer,
        chat: chatSlice.reducer,
        jobList: jobSlice.reducer,
        ui: uiSlice.reducer,
    }
})