//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { List } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../../firebase/config";
import ResumeCandidateCard from "./CandidateCard";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { CollectionsName } from "../../../../models/collections";
import { RecommendationCandidate } from "../../../../types/candidate";

// Component
const CandidateList: React.FC = () => {
  // States
  const position = useSelector((state: any) => state.ui.position);
  const user = useSelector((state: any) => state.auth.user);
  const [candidateList, setCandidateList] = useState<Array<RecommendationCandidate>>([]);

  useEffect(() => {
    if (user?.idHiring && position?.idPosition) {
      const recommendationCandidatesRef = collection(db, `hirings/${user.idHiring}/positions/${position.idPosition}/recommendationCandidates`);
      const recommendationCandidatesQuery = query(recommendationCandidatesRef, where("like", "==", true));

      const recommPreCandidateRef = collection(db, CollectionsName.HIRINGS, user.idHiring, CollectionsName.POSITIONS, position.idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES);
      const recommendationPreCandidatesQuery = query(recommPreCandidateRef, where('hiringLike', '==', true));

      const unsubscribe1 = onSnapshot(recommendationCandidatesQuery, (recommendationCandidatesSnap) => {
        const candidates: any = [];
        recommendationCandidatesSnap.forEach((doc) => {
          const candidate = doc.data();
          if (candidate?.candidateLike && candidate.status !== "waiting for reply" && candidate.status !== "declined") {
            candidates.push({
              ...candidate,
              type: 'candidate',
            });
          }
        });
        setCandidateList(prevList => {
          const filteredPrevList = prevList.filter(candidate => candidate.type !== 'candidate');
          return [...filteredPrevList, ...candidates];
        });
      });

      const unsubscribe2 = onSnapshot(recommendationPreCandidatesQuery, (recommendationPreCandidatesSnap) => {
        const preCandidates = recommendationPreCandidatesSnap.docs
          .map(doc => doc.data())
          .filter(preCandidate => preCandidate.candidateStatus !== "waiting for reply" && preCandidate.candidateStatus !== "declined")
          .map(preCandidate => ({
            skills: preCandidate.skills,
            like: preCandidate.hiringLike,
            status: preCandidate.candidateStatus,
            'insertion date': preCandidate.promoteDate,
            promote: 'hiring',
            type: 'precandidate',
            id: preCandidate.id
          }));
        setCandidateList(prevList => {
          const filteredPrevList = prevList.filter(candidate => candidate.type !== 'precandidate');
          const combinedList = [...filteredPrevList, ...preCandidates];

          // Ordenar por "insertion date" (más reciente primero)
          combinedList.sort((a: any, b: any) => b['insertion date'].seconds - a['insertion date'].seconds);

          return combinedList;
        });
      });

      return () => {
        unsubscribe1();
        unsubscribe2();
        setCandidateList([]);
      };
    }
  }, [user, position]);

  return (
    <List className="contenedor"
      style={{
        width: "100%",
        height: "auto",
        padding: '2%',
        overflowY: "auto",
      }}
      itemLayout="horizontal"
      dataSource={candidateList}
      renderItem={(candidate) => (
        <ResumeCandidateCard candidate={candidate} />
      )}
    />
  );
}

// Export
export default CandidateList;
