//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/config";
const { Text } = Typography;

// Component
const formatTime = (time) => {
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  if (minutes <= 10) minutes = "0" + minutes;
  if (seconds <= 10) seconds = "0" + seconds;
  return minutes + ":" + seconds;
};

export default function CounterDown({ seconds, handleOnLogout }) {
  // States
  const { dictionary } = useSelector((state) => state.ui);
  const [countDown, setCountDown] = useState(seconds);
  const timerId = useRef();
  const navigate = useNavigate();

  const LogOut = useCallback(async () => {
    try {
      await signOut(auth);
      handleOnLogout();
      navigate("/login");
    } catch (error) {
      alert(`Error in function LogOut: ${error}`);
      // Handle any errors that occur during logout
    }
  }, [handleOnLogout, navigate]);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountDown((prev) => {
        localStorage.setItem("seconds", prev - 1);
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(timerId.current);
  }, []);

  // Effects
  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timerId.current);
      LogOut();
    }
  }, [countDown, LogOut]);

  return (
    <Text style={{ fontSize: 18, fontFamily: "Muli" }}>
      {dictionary["change-password-text-counter"]}:{formatTime(countDown)}
    </Text>
  );
}
