import axios from "axios";
import routes from "../api";

const baseURL = routes.mode === "dev" ? routes.dev_route : routes.qa_route


export const sendEmailFromHiringToCandidateForNewMessageRequest = async (email: string, language: string, name: string, lastName: string, token: string) => {
    try {
        const configSendEmail = {
            headers: {
                Authorization: token,
                'email': email.trim(),
                'typeEmail': 'newMessage',
                'language': language.trim(),

            },
        };

        await axios.get(`${baseURL}/email/sendEmail`, configSendEmail)

        return true;
    } catch (error) {
        return false;
    }
}