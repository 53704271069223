// Dependencies
import { Col, Row, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from "../../firebase/config";
import iconDot from '../../image/sidebar/icon-dot.png';
import { useLocation } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { CollectionsName } from '../../models/collections';

// Interface
interface Props {
  handleOnClickButton: () => void;
  positionSelected: number;
  positionIndex: number;
  position: any;
}

// Component
const { Text } = Typography;

const SourcingButton: React.FC<Props> = ({ handleOnClickButton, positionSelected, positionIndex, position }) => {

  // States
  const user = useSelector((state: any) => state.auth.user);
  const [counterRecommendation, setCounterRecommendation] = useState<number>(0);
  const [counterPreRecommendation, setCounterPreRecommendation] = useState<number>(0);
  const location = useLocation();

  // Effects
  useEffect(() => {
    if (!user?.idHiring || !position.idPosition) {
      return;
    }

    setCounterRecommendation(0); // Reiniciar el contador
    setCounterPreRecommendation(0); // Reiniciar el contador de pre-recomendaciones

    // Referencia y consulta para recommendationCandidates
    const recommCandidateRef = collection(db, 'hirings', user.idHiring, 'positions', position.idPosition, 'recommendationCandidates');
    const recommendationCandidatesQuery = query(recommCandidateRef, where('like', '==', ''));

    // Referencia y consulta para recommendationPreCandidates
    const recommPreCandidateRef = collection(db, CollectionsName.HIRINGS, user.idHiring, CollectionsName.POSITIONS, position.idPosition, CollectionsName.RECOMMENDATIONPRECANDIDATES);
    const recommendationPreCandidatesQuery = query(recommPreCandidateRef, where('hiringLike', '==', null));

    // Observador para recommendationCandidates
    const unsubscribeRecommendations = onSnapshot(recommendationCandidatesQuery, (snapshot) => {
      const recommendationCount = snapshot.docs.length;
      setCounterRecommendation(recommendationCount);
    });

    // Observador para recommendationPreCandidates
    const unsubscribePreRecommendations = onSnapshot(recommendationPreCandidatesQuery, (snapshot) => {
      const preRecommendationCount = snapshot.docs.length;
      setCounterPreRecommendation(preRecommendationCount);
    });

    return () => {
      unsubscribeRecommendations();
      unsubscribePreRecommendations();
    };
  }, [position.idPosition, user?.idHiring]);

  const totalRecommendations = counterRecommendation + counterPreRecommendation;

  return (
    <Row
      onClick={handleOnClickButton}
      style={{
        position: 'relative',
        cursor: "pointer",
        borderColor: '#24cece',
        backgroundColor: location.pathname === "/recommendation" && positionIndex === positionSelected ? "#24cece" : "white",
        alignItems: "center",
        padding: "1% 0% 1%",
        minHeight: 35,
        width: '100%'
      }}
    >
      <Row style={{ width: '100%' }}>
        <Col xs={{ span: 3, offset: 2 }} sm={{ span: 3, offset: 2 }} md={{ span: 3, offset: 2 }} lg={{ span: 3, offset: 2 }} xl={{ span: 3, offset: 2 }} xxl={{ span: 3, offset: 2 }} style={{ flexDirection: "row", width: "100%", display: "flex", justifyContent: "center" }} >
          <img src={iconDot} style={{ height: 10, width: 10, marginTop: 5 }} alt="icon-dot" />
        </Col>
        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
          <Row style={{ width: '100%' }}>
            <Col style={{ width: "100%", display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
              <Tooltip title={position.title}>
                <Text style={{ color: "black", fontSize: 12, fontFamily: 'Muli', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} key={positionIndex + 5}>{position.title}</Text>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {totalRecommendations > 0 && (
            <div style={{ height: 18, width: 18, backgroundColor: '#D12525', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10 }}>
              <Text style={{ fontFamily: 'Muli', fontSize: 11, color: 'white' }}>{totalRecommendations}</Text>
            </div>
          )}
        </Col>
      </Row>
    </Row>
  );
}

export default SourcingButton;
