//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Dependencies
import { Upload } from "antd"
import iconDocument from './../image/ico-message-attach.png';
import React  from 'react'
import ImageButton from "./ImageButton";


// Interfaces
interface Props{  
    setDocument:any;
}

const UploadButtonDocument:React.FC<Props> = ({setDocument})=>{
    // Handlers
    const getBase64 = (img:any, callback:any) =>{
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      }
   
    const handleChange = (info:any)=>{
        getBase64(info.file.originFileObj,(document:any) =>{    
            setDocument({
                name:info.file.name,
                url:document
            })
          
        })
    }
    
return(
    <Upload 
        name="image"
        onChange={ handleChange }
        showUploadList={false}
    >
        <ImageButton height={38} width={38} iconImage={iconDocument}/>
    </Upload>
    )
}

export default UploadButtonDocument;