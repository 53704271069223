import React from 'react';
import { Typography } from 'antd';

interface SourcingLoadingProps {
    dictionary: { [key: string]: string };
}

const SourcingLoading: React.FC<SourcingLoadingProps> = ({ dictionary }) => {
    return (
        <div style={{ height: '100%', width: '100%', padding: '10px', boxSizing: 'border-box' }}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '20px',
                    boxShadow: '0px 2px 8px #6153D3',
                    opacity: 0.3,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography.Text style={{ fontSize: '20px', color: 'black' }}>
                    {dictionary['sourcing-loading-text']}
                </Typography.Text>
            </div>
        </div>
    );
};

export default SourcingLoading;
