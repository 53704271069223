//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------- 
// Dependencies
import { Content } from "antd/lib/layout/layout";
import React from "react";
import Profile from "../components/profile/Profile";

// Component
const ProfileScreen: React.FC = () => {
  return (
    <Content style={{ height: '100%', width: '100%', padding: '1%', backgroundColor: '#00000004' }}>
      <Profile />
    </Content>
  );
};

// Export
export default ProfileScreen;
