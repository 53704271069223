//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
// Copyright :derechos_de_autor: 2021 Service-Conseil IRGT Inc. All rights reserved.
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//Dependencies
import React, { useEffect, useState } from "react";

import { Candidate, Skill } from "../../../../types/candidate";
import { Timestamp, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";
import CandidateExperienceSection from "./CandidateExperienceSection";
import CandidateEducationSection from "./CandidateEducationSection";
import CandidateSkillSection from "./CandidateSkillSection";
import { Row } from "antd";

//Interface
interface Props {
  candidate: Candidate;
}

//Component
const CandidateDetail: React.FC<Props> = ({ candidate }) => {
  // States
  const [experienceList, setExperienceList] = useState<
    Array<{
      actualPosition: boolean;
      company: string;
      dateEnd: number;
      dateStart: number;
      description: string;
      title: string;
    }>
  >([]);
  const [educationList, setEducationList] = useState<Array<any>>([]);
  const [skills, setSkills] = useState<Array<Skill>>([]);

  //Effects
  useEffect(() => {
    if (!candidate?.id) {
      return;
    }

    // Reference to the specific candidate document
    const candidateDocRef = doc(db, candidate.type === 'precandidate' ? 'preCandidates' : "candidates", candidate.id);

    getDoc(candidateDocRef)
      .then((candidateSnap) => {
        const candidateData: any = candidateSnap.data();

        if (candidateData) {
          const experienceList: any = [];
          const educationList: any = [];

          setSkills(candidateData.skills);

          candidateData.experience?.forEach((experience: any) => {
            const fullYearStart = experience.dateStart
              ? new Date(experience.dateStart.seconds * 1000)
              : new Date();
            const fullYearEnd = experience.dateEnd
              ? new Date(experience.dateEnd.seconds * 1000)
              : new Date();

            experienceList.push({
              ...experience,
              dateStart: fullYearStart.getFullYear(),
              dateEndComplete: experience.actualPosition
                ? Timestamp.now().seconds
                : experience.dateEnd?.seconds,
              dateEnd: fullYearEnd.getFullYear(),
            });
          });

          candidateData.education?.forEach((education: any) => {
            const fullYearGraduationEnd = education.graduationDate
              ? new Date(education.graduationDate.seconds * 1000)
              : new Date();

            educationList.push({
              ...education,
              dateGraduationDateOk: fullYearGraduationEnd.getFullYear(),
            });
          });

          setExperienceList(
            experienceList.sort(
              (a: any, b: any) => b.dateEndComplete - a.dateEndComplete
            )
          );
          setEducationList(educationList);
        }
      })
      .catch((error) => {
        console.error("Error fetching candidate data: ", error);
      });
  }, [candidate?.id, candidate.type]);

  return (
    <Row
      style={{
        width: "100%", // Ajusta esto según sea necesario
        boxShadow: "7px 7px 15px #00000020",
        border: "1px solid",
        borderRadius: 10,
        borderColor: "#00000016",
        padding: "2%",
        overflowY: "auto",
        height: "80vh",
        backgroundColor: 'white'
      }}
    >
      <CandidateExperienceSection
        candidate={candidate}
        experienceList={experienceList}
      />
      <CandidateEducationSection
        candidate={candidate}
        educationList={educationList}
      />
      <CandidateSkillSection candidate={candidate} skillList={skills} />
    </Row>
  );
};

//Export
export default CandidateDetail;
