// Dependencies
import { Col, Row, Tooltip, Typography } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from "../../firebase/config";
import iconDot from '../../image/sidebar/icon-dot.png';

// Interface
interface Props {
  handleOnClickButton: () => void;
  positionJobSelected: any;
  positionIndex: number;
  position: any;
}

const { Text } = Typography;

const JobsButton: React.FC<Props> = ({ handleOnClickButton, positionJobSelected, positionIndex, position }) => {
  // States
  const user = useSelector((state: any) => state.auth.user);
  const [counterRecommendation, setCounterRecommendation] = useState<number>(0);
  const [counterRecommendationPre, setCounterRecommendationPre] = useState<number>(0);
  const location = useLocation();

  // Callback function to fetch data in real-time
  const fetchRecommendations = useCallback(() => {
    if (!user?.idHiring || !position?.idPosition) return;

    // Reference to the specific collections
    const recommCandidateRef = collection(db, "hirings", user.idHiring, "positions", position.idPosition, "recommendationCandidates");
    const recommPreCandidateRef = collection(db, "hirings", user.idHiring, "positions", position.idPosition, "recommendationPreCandidates");
    const recommendationCandidatesQuery = query(recommCandidateRef, where("status", "==", "interested"));
    const recommendationPreCandidatesQuery = query(recommPreCandidateRef, where("candidateStatus", "==", "interested"));

    // Subscribe to real-time updates
    const unsubscribeRecommendationCandidates = onSnapshot(recommendationCandidatesQuery, (snapshot) => {
      setCounterRecommendation(snapshot.docs.length);
    });

    const unsubscribeRecommendationPreCandidates = onSnapshot(recommendationPreCandidatesQuery, (snapshot) => {
      setCounterRecommendationPre(snapshot.docs.length);
    });

    // Cleanup function to unsubscribe from listeners
    return () => {
      unsubscribeRecommendationCandidates();
      unsubscribeRecommendationPreCandidates();
    };
  }, [user?.idHiring, position?.idPosition]);

  // Effects
  useEffect(() => {
    const unsubscribe = fetchRecommendations();
    return () => unsubscribe && unsubscribe();
  }, [fetchRecommendations]);

  const total = counterRecommendation + counterRecommendationPre;

  return (
    <Row
      onClick={handleOnClickButton}
      style={{
        cursor: "pointer",
        backgroundColor: location.pathname === "/jobList" && position.idPosition === positionJobSelected.idPosition ? "#24cece" : "white",
        alignItems: "center",
        padding: "1% 0% 1%",
        minHeight: 35,
        width: '100%'
      }}
    >
      <Row style={{ width: '100%' }}>
        <Col
          xs={{ span: 3, offset: 2 }}
          sm={{ span: 3, offset: 2 }}
          md={{ span: 3, offset: 2 }}
          lg={{ span: 3, offset: 2 }}
          xl={{ span: 3, offset: 2 }}
          xxl={{ span: 3, offset: 2 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img src={iconDot} style={{ height: 10, width: 10, marginTop: 5 }} alt="icon-dot" />
        </Col>
        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
          <Row style={{ width: '100%' }}>
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={position.title}>
                <Text
                  style={{
                    color: "black",
                    fontSize: 12,
                    fontFamily: 'Muli',
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                  key={positionIndex + 5}
                >
                  {position.title}
                </Text>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {total > 0 && (
            <div
              style={{
                height: 18,
                width: 18,
                backgroundColor: '#D12525',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 10
              }}
            >
              <Text style={{ fontFamily: 'Muli', fontSize: 11, color: 'white' }}>{total}</Text>
            </div>
          )}
        </Col>
      </Row>
    </Row>
  );
}

export default JobsButton;
