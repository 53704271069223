import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config";
import { CollectionsName } from "../../models/collections";
import { PreCandidateInterface } from "../../models/preCandidate.interface";

export const getPreCandidateDocument = async (id: string) => {
    try {

        const preCandidateDocRef = doc(db, CollectionsName.PRECANDIDATES, id);

        const preCandidateDoc = await getDoc(preCandidateDocRef);

        if (preCandidateDoc.exists()) {
            const preCandidate = preCandidateDoc.data() as PreCandidateInterface;
            return {
                success: true,
                data: preCandidate,
            };
        } else {
            return {
                success: true,
                data: null,
            };

        }
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export const setRecommendation = async (idPreCandidate: string, idPosition: string, data: any) => {
    try {

        const recommendationDocRef = doc(db, CollectionsName.PRECANDIDATES, idPreCandidate, 'recommendation', idPosition);

        await setDoc(recommendationDocRef, data);

        return {
            success: true,
            data: null,
        };
    } catch (error) {
        return {
            success: false,
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}